import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { ImageList } from "../../../../config/ImageList";
import { BorderRadius, Shadow } from "../../../../config/Values";

export const ImageAndRoleWrapper = styled.div`
  width: 100%;
  min-height: 15rem;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1.5rem;
  .loadingBg {
    background-image: url(${ImageList.LoadingImageBg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
  }
  .profile_picture {
    width: 100% !important;
    height: 12rem;
    object-fit: cover;
    object-position: center;
    box-shadow: ${Shadow.dark};
    border-radius: ${BorderRadius.mediumRadius};
    cursor: pointer;
  }
  .edit_button {
    border-radius: ${BorderRadius.smallRadius};
    background-color: ${Colors.lightPurple};
    text-transform: capitalize;
    margin-right: 1rem;
    font-size: 12px;
    font-weight: 700;
    padding: 0.4rem 1.8rem;
    transition: all 0.5s ease;
  }
  .edit_button:hover {
    background-color: ${Colors.lightPurple};
    transform: translateY(-3px);
    transition: all 0.5s ease;
  }
  .remove_button {
    border-radius: ${BorderRadius.smallRadius};
    text-transform: capitalize;
    padding: 0.3rem 1.8rem;
    color: ${Colors.textBlack};
    font-size: 12px;
    font-weight: 700;
    transition: all 0.5s ease;
  }
  .remove_button:hover {
    color: ${Colors.bsDark};
    transform: translateY(-3px);
    transition: all 0.5s ease;
  }
  .preview {
    border-radius: ${BorderRadius.mediumRadius} !important;
  }
`;
