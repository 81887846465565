import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

export const ProfileInfoWrapper = styled.div`
  /* height: fit-content; */
  flex: 1;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1.5rem;
  .description {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 1rem;
    color: ${Colors.cardText} !important;
    font-weight: 300;
    margin-bottom: 2rem;
  }
  .singleInfo {
    display: flex;
    align-items: center;
  }
  .title {
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif;
    font-size: 0.9rem;
  }
  .infoValue {
    margin-left: 0.5rem;
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 1rem;
    color: ${Colors.cardText} !important;
    font-weight: 300;
  }
  .icon {
    margin: 0 0.8rem;
    cursor: pointer;
  }
`;
