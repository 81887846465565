import { Table, Tag } from "antd";
const { Column } = Table;

const CreateBrandExcelTable = ({ data }) => {
  return (
    <Table
      dataSource={data}
      scroll={{
        y: 540,
        x: 2000,
      }}
    >
      <Column
        width={150}
        title="Brand Name"
        dataIndex="firstName"
        key="firstName"
        fixed="left"
      />

      <Column title="Role" dataIndex="role" key="role" width={150} />
      <Column title="Email" dataIndex="email" key="email" width={200} />
      <Column title="Phone Number" dataIndex="phone" key="phone" width={200} />
      <Column title="Tagline" dataIndex="tagline" key="tagline" />
      <Column title="State" dataIndex="state" key="state" />
      <Column title="City" dataIndex="city" key="city" />
      <Column
        title="Categories"
        dataIndex="categories"
        key="categories"
        width={400}
        render={(categories) => (
          <>
            {categories.map((tag, i) => (
              <Tag color="blue" key={i}>
                {tag}
              </Tag>
            ))}
          </>
        )}
      />
    </Table>
  );
};
export default CreateBrandExcelTable;
