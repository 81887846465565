import React, { useState } from "react";
import { Switch, Tooltip } from "antd";
import { InputLabel, Typography } from "@mui/material";
import SmallHeading from "../../../headings/SmallHeading";
import { UserSettingsWrapper } from "../../UserProfile/UserSettings/Style";
import { Colors } from "../../../../config/Colors";
import { Icons } from "../../../../config/IconsList";
const UserSettingsByAdmin = () => {
  const [settingValues, setsettingValues] = useState({
    // if account is restricted than the influencer cannot connect to any campaign
    // and a brand cannot post any campaign
    restrictAccount: false,
    //if account is suspended the influencer or brand will not be visible in their respective list
    suspendAccount: false,
    deleteAccount: false,
    verifyGeneral: false,
    verifyBlue: false,
    verifyGold: false,
  });

  const onChange = (checked, name) => {
    setsettingValues({ ...settingValues, [name]: checked });
    console.log(`switch to ${checked}=====${name}`);
  };

  const SettingOptions = (value, name, text, infoText) => {
    return (
      <div className="singleSetting mt1">
        <Switch
          checked={value}
          onChange={(e) => onChange(e, name)}
          className="switch"
        />
        <Typography className="settingText">{text}</Typography>
        <Tooltip title={infoText}>
          <Icons.InfoIcon style={{ marginLeft: 12 }} />
        </Tooltip>
      </div>
    );
  };
  return (
    <UserSettingsWrapper>
      <SmallHeading
        title="Apply Following Settings To User"
        color={Colors.textBlack}
        weight="500"
      />
      <InputLabel className="settingsLabel">Account</InputLabel>
      {/* email on shortlist */}
      {SettingOptions(
        settingValues?.verifyGeneral,
        "verifyGeneral",
        "The user is verified",
        "The user will be considered verified if they have connected their account to their social profiles and in case of Brand, it is ensured that they are real"
      )}
      {/* email on rejection */}
      {SettingOptions(
        settingValues?.verifyBlue,
        "verifyBlue",
        "Apply blue tick to the profile",
        "Recognize the user under special case and provide the special tag"
      )}
      {/* email on message */}
      {SettingOptions(
        settingValues?.verifyGold,
        "verifyGold",
        "Apply gold tick to the profile",
        "Recognize the user under special case and provide the special tag"
      )}

      {SettingOptions(
        settingValues?.restrictAccount,
        "restrictAccount",
        "Restrict the account",
        "The restricted account will not be able to connect to a campaign and in case of brand, they will not be able to post campaign"
      )}
      {/* monthly campaign update */}
      {SettingOptions(
        settingValues?.suspendAccount,
        "suspendAccount",
        "Suspend the user",
        "Suspended account will not be visible on App in their respected segment"
      )}
      {/* subscribe to news letter */}
      {SettingOptions(
        settingValues?.deleteAccount,
        "deleteAccount",
        "Delete the account",
        "The user will be deleted, but it can be retrieved within 6 month of time. To retrieve the account just click the switch one more time"
      )}
    </UserSettingsWrapper>
  );
};

export default UserSettingsByAdmin;
