import { Box, Button, Container, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink } from "react-router-dom";
import useViewport from "../viewport/useViewport";
import { Colors } from "../config/Colors";
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { logoutUser } from "../redux/currentUserSlice";
import { useDispatch } from "react-redux";

const ErrorImage =
  "https://res.cloudinary.com/axborapp/image/upload/v1643637747/undraw_page_not_found_su7k_ifdcdu.svg";
const UnAuthorized = () => {
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const logout = async () => {
    await signOut(auth)
      .then(() => {
        console.log("User signed out.");
        dispatch(logoutUser());
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
          alignSelf: "center",
          marginTop: 15,
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              align="center"
              color="textPrimary"
              variant={isMobile ? "h3" : "h2"}
              fontWeight="600"
            >
              You are not authorized for this site.
              <br />
              contact Ingenkart team in all other cases.
            </Typography>

            <Box sx={{ textAlign: "center" }}>
              <img
                alt="Under development"
                src={ErrorImage}
                style={{
                  marginTop: 50,
                  display: "inline-block",
                  maxWidth: "100%",
                  width: 560,
                }}
              />
            </Box>
            <NavLink
              exact
              to="/"
              replace={true}
              style={{ textDecoration: "none" }}
            >
              <Button
                component="a"
                startIcon={<ArrowBackIcon fontSize="small" />}
                sx={{
                  mt: 3,
                  borderRadius: 3,
                  textDecoration: "none",
                  backgroundColor: Colors.lightPurple,
                }}
                variant="contained"
              >
                Go back to home
              </Button>
            </NavLink>
            <Button variant="text" onClick={logout}>
              Logout
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UnAuthorized;
