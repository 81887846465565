import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { currentUser } from "../functions/auth";

const initialState = {
  email: "",
  phone: "",
  name: "",
  token: "",
  categories: [],
  role: "",
  DOB: "",
  uid: "",
  _id: "",
  profilePicture: {
    reference: "",
    url: "",
  },
  isLoading: true,
};

// calling user data ayncronus way
export const getAsyncUser = createAsyncThunk(
  "user/getAsyncUser",
  async (authtoken, thunkAPI) => {
    console.log("HITTING USER REDUX");
    try {
      const resp = await currentUser(authtoken);
      const user = resp.data.user;
      let data = {
        email: user.email,
        token: authtoken,
        _id: user._id,
        phone: user.phone,
        DOB: "",
        role: user.role,
        categories: user?.categories,
        uid: user.uid,
        name: user.name,
        profilePicture: {
          reference: user?.profilePicture?.reference,
          url: user?.profilePicture?.url,
        },
        isLoading: false,
      };

      return data;
    } catch {
      console.log("ERROR IN CATCH");
      console.log("THUNKAPI=>", thunkAPI);
      return thunkAPI.rejectWithValue("something wait wrong");
    }
  }
);

const currentUserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    noUser: (state) => {
      state.isLoading = false;
    },
    logoutUser: (state) => {
      state._id = "";
      state.email = "";
      state.phone = "";
      state.DOB = "";
      state.token = "";
      state.profilePicture = {
        reference: "",
        url: "",
      };
      state.categories = [];
      state.name = "";
      state.role = "";
      state.uid = "";
      state.isLoading = false;
    },
    login: (state, { payload }) => {
      state._id = payload._id;
      state.email = payload.email;
      state.phone = payload.phone;
      state.DOB = payload.DOB;
      state.token = payload.token;
      state.profilePicture = payload.profilePicture;
      state.categories = payload.categories;
      state.name = payload.name;
      state.role = payload.role;
      state.uid = payload.uid;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAsyncUser.pending, (state) => {
        state._id = "";
        state.email = "";
        state.phone = "";
        state.DOB = "";
        state.name = "";
        state.role = "";
        state.uid = "";
        state.isLoading = true;
      })
      .addCase(getAsyncUser.fulfilled, (state, { payload }) => {
        state._id = payload._id;
        state.email = payload.email;
        state.phone = payload.phone;
        state.DOB = payload.DOB;
        state.token = payload.token;
        state.profilePicture = payload.profilePicture;
        state.categories = payload.categories;
        state.name = payload.name;
        state.role = payload.role;
        state.uid = payload.uid;
        state.isLoading = payload.isLoading;
      })
      .addCase(getAsyncUser.rejected, (state) => {
        state._id = "";
        state.email = "";
        state.phone = "";
        state.DOB = "";
        state.name = "";
        state.role = "";
        state.uid = "";
        state.isLoading = false;
      });
  },
});

export const { login, logoutUser, noUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;
