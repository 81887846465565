import styled from "styled-components";
import { Shadow } from "../../../config/Values";

export const InstaDataWrapper = styled.div`
  .card_container {
    width: 100%;
    min-height: 25rem;
    border-radius: 18px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    overflow: hidden;
  }
  .card_container:hover .insta_image_post {
    scale: 1.04;
    transition: all 0.2s ease-in;
  }

  .insta_image_post {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    border-radius: 18px 18px 0 0;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }
  .username {
    font-size: 16px;
    font-weight: 500;
  }
  .content_container {
    padding: 0.5rem;
  }
`;
