import React from "react";
import CampaignWiseInfo from "../../../components/table/campaign/HomeScreen/CampaignWiseInfo";
import DesktopLayout from "../../../theme/DesktopLayout";

const CampaignWiseRevenue = () => {
  return (
    <DesktopLayout>
      <CampaignWiseInfo all={true} />
    </DesktopLayout>
  );
};

export default CampaignWiseRevenue;
