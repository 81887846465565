import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius } from "../../../config/Values";

export const CreateUserWrapper = styled.div`
  min-height: 100vh;
  flex: 1;
  .create_user_heading {
    font-weight: 500;
    font-size: 1.6rem;
    color: ${Colors.white};
  }
  .create_user_subheading {
    font-weight: 300;
    font-size: 1rem;
    color: ${Colors.white};
    max-width: ${(prop) => (prop.isTablet ? "90%" : "50%")};
    line-height: 1.925rem;
  }
  .save_button {
    background-color: hsla(0, 0%, 100%, 0.1);
    /* opacity: 0.75; */
    box-shadow: none;
    color: ${Colors.white};
    transition: all 0.3s linear;
    text-transform: capitalize;
    border-radius: ${BorderRadius.smallRadius};
    border: 1px solid ${Colors.white};
    padding: 0.5rem 1rem;
  }
  .save_button:hover {
    background-color: transparent;
    box-shadow: none;
    color: ${Colors.white80};
    transform: translateY(-3px);
    transition: all 0.3s linear;
    border: 1px solid ${Colors.white};
  }
`;
