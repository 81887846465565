import { Typography, Badge } from "@mui/material";
import { Avatar, Badge as MyRibbon, Col, Image, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CampaignDescription from "../../components/description/CampaignDescription";
import LargeHeading from "../../components/headings/LargeHeading";
import DosDontsList from "../../components/list/DosAndDonts/DosDontsList";
import TagWithIcon from "../../components/tag/TagWithIcon";
import { Colors } from "../../config/Colors";
import { formatDate, nFormatter } from "../../config/Function";
import { Icons } from "../../config/IconsList";
import { ImageList } from "../../config/ImageList";
import { LocalStorageIdentifier } from "../../config/LocalStorageInfo";
import DesktopLayout from "../../theme/DesktopLayout";
import useViewport from "../../viewport/useViewport";
import { SingleCampaignWrapper } from "../campaigns/SingleCampaign/Style";
import { useSelector } from "react-redux";

const PreviewCampaign = () => {
  const { isMobile, isTablet } = useViewport();
  const createCampaign = useSelector((state) => state.createCampaign);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [campaignDetail, setCampaignDetail] = useState({});
  const [displayedImage, setDisplayedImage] = useState("");

  const [isBarter, setIsBarter] = useState(false);
  const [ribbonTitle, setRibbonTitle] = useState("Banner");

  // all info of brand which was selected
  let brandInfo = user;

  // useEffect
  useEffect(() => {
    // isExisting will check that wether the localstorage value is defined or not
    let isExisting =
      createCampaign?.campaignName !== "" &&
      createCampaign?.campaignBanner !== "" &&
      createCampaign?.gender !== "" &&
      createCampaign?.campaignPeriod?.endDate !== "" &&
      createCampaign?.deliverableType?.length > 0;
    if (createCampaign) {
      if (!isExisting) {
        navigate("/all/campaigns", { replace: true });
      } else {
        getCampaignData();
      }
    }
  }, [createCampaign]);

  // retrieve the data of campaign from the _id received
  const getCampaignData = () => {
    const myCampaign = { ...createCampaign };
    setCampaignDetail(myCampaign);
    setIsBarter(myCampaign?.isBarter);
  };

  // replace bigger image by clicked image
  const handleImageChange = (imageUrl, imageType) => {
    setDisplayedImage(imageUrl);
    setRibbonTitle(imageType);
  };
  // helping jsx for small images
  const SmallImages = (imageUrl, tooltipTitle, imageType, isVideo) => {
    return (
      <Tooltip title={tooltipTitle} style={{ overflow: "hidden" }}>
        {isVideo ? (
          <a
            href={imageUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="smallImage flexCenter videoDiv"
          >
            Video{" "}
            <Icons.OpenNewTabIcon style={{ fontSize: 14, marginLeft: 5 }} />
          </a>
        ) : (
          <img
            src={imageUrl}
            alt={imageType}
            className="smallImage"
            onClick={() => handleImageChange(imageUrl, imageType)}
          />
        )}
      </Tooltip>
    );
  };
  return (
    <DesktopLayout>
      <SingleCampaignWrapper isMobile={isMobile}>
        {/* {JSON.stringify(
          localStorage.getItem(LocalStorageIdentifier.isCreateCampaignComplete)
        )} */}
        <Row gutter={[0, 24]}>
          <Col xs={24} md={10} lg={8}>
            {/*=================================Images===================================== */}
            <MyRibbon.Ribbon
              placement="start"
              color={Colors.lightPurple}
              text={ribbonTitle}
            >
              <Image
                width="100%"
                src={
                  displayedImage === ""
                    ? campaignDetail?.campaignBanner
                    : displayedImage
                }
                alt={campaignDetail?.campaignName}
                className="campaignBanner"
              />
            </MyRibbon.Ribbon>
            <div className="otherImagesContainer mtp5">
              {/* product reference */}
              {campaignDetail?.productReference?.map((item, i) => (
                <div key={i}>
                  {SmallImages(
                    item.imageUrl,
                    `Product Reference :- ${item.productName} at ₹ ${item.price}`,
                    "Product Reference",
                    false
                  )}
                </div>
              ))}
              {/* reference images for post */}
              {campaignDetail?.referenceImage?.map((item, i) => (
                <div key={i}>
                  {SmallImages(
                    item,
                    `Reference Image`,
                    "Reference Image",
                    false
                  )}
                </div>
              ))}
              {/* video reference */}
              {campaignDetail?.referenceVideo?.map((item, i) => (
                <div key={i}>
                  {SmallImages(
                    item,
                    "Reference Video",
                    "Reference Video",
                    true
                  )}
                </div>
              ))}
              {/* banner */}
              {displayedImage !== "" &&
                SmallImages(
                  campaignDetail.campaignBanner,
                  `Banner`,
                  "Campaign Banner"
                )}
            </div>
            {/*=================================Tags===================================== */}
            <div className="tagWrapper mt1">
              {/* barter  */}
              <TagWithIcon
                title={`Barter: ${isBarter ? "Yes" : "No"} `}
                iconUrl={ImageList.HandshakeImageIcon}
                disabled={isBarter ? false : true}
              />
              {/* budget tag */}
              <TagWithIcon
                title={`Budget: ${nFormatter(
                  campaignDetail?.influencerBudget?.min
                )} to ${nFormatter(campaignDetail?.influencerBudget?.max)} `}
                iconUrl={ImageList.MoneyBagImageIcon}
              />{" "}
              {/* categories tag */}
              <TagWithIcon
                title={campaignDetail?.campaignCategories?.map((item, i) => (
                  <span key={i} style={{ marginRight: "0.2rem" }}>
                    {item}{" "}
                    {/* The role and aria-hidden attributes are added to the span element to ensure that it is properly accessible to screen readers. */}
                    <span
                      role="presentation"
                      aria-hidden="true"
                      style={{
                        display:
                          i ===
                            campaignDetail?.campaignCategories?.length - 1 &&
                          "none",
                      }}
                    >
                      {/* The dot element is created using an HTML span element with a middle dot character as its content. */}
                      &middot;
                    </span>
                  </span>
                ))}
                iconUrl={ImageList.TagsImageIcon}
              />
            </div>

            {/*=================================Deliverables Tags===================================== */}
            <Typography className="deliverableHeading">Deliverables</Typography>
            <div className="deliverableTagsContainer">
              {campaignDetail?.deliverableType?.map((item, i) => {
                let imageUrl =
                  item === "instaReel"
                    ? ImageList.InstagramReelImageUrl
                    : item === "instagramPost"
                    ? ImageList.InstagramImageUrl
                    : item === "instagramStory"
                    ? ImageList.InstagramImageUrl
                    : item === "facebookPost"
                    ? ImageList.FacebookImageUrl
                    : item === "youtubeShorts"
                    ? ImageList.YouTubeShortsImageUrl
                    : ImageList.YouTubeImageUrl;
                return <TagWithIcon key={i} title={item} iconUrl={imageUrl} />;
              })}
            </div>
          </Col>
          {/*==================================== **************** ======================================*/}
          {/*======================================= 2nd Column ======================================*/}
          {/*==================================== **************** ======================================*/}

          <Col xs={24} md={14} lg={16}>
            <div className="contentContainer">
              <div style={{ paddingRight: !isTablet && "5.5rem" }}>
                {" "}
                <LargeHeading title={campaignDetail?.campaignName} />
              </div>
              {/* brand logo, name, status and campaign time period */}
              <div className="brandContainer flexBetween mt1">
                {/* this section has brand logo and brand name */}
                <div className="brandInfo">
                  <Badge
                    overlap="circular"
                    badgeContent={
                      <img
                        src={ImageList.GoldenTickImageIcon}
                        alt="tick"
                        className="verificationTick"
                      />
                    }
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <Avatar shape="circle" src={user?.profilePicture?.url} />
                  </Badge>
                  <Typography className="brandName font">
                    {brandInfo?.name}
                  </Typography>
                </div>
                {/* campaign status and campaign time period */}
                <div className="statusPeriodContainer flexBetween">
                  <TagWithIcon
                    title="Running"
                    background={Colors.tagGreenBg}
                    color={Colors.darkGreen}
                    iconUrl={ImageList.RunningCampaignImageIcon}
                  />
                  <TagWithIcon
                    title={
                      formatDate(campaignDetail?.campaignPeriod?.startDate) +
                      " - " +
                      formatDate(campaignDetail?.campaignPeriod?.endDate)
                    }
                    iconUrl={ImageList.CalenderImageIcon}
                    iconDimension=".9rem"
                  />
                </div>
              </div>
              {/* ====================================DESCRIPTION =========================== */}
              <CampaignDescription
                campaignDetail={campaignDetail}
                apiCall={false}
              />
              {/* ========================INFO TAGS=========================================== */}

              <div className="mt1 mb2">
                {" "}
                <TagWithIcon
                  title={`Age: ${campaignDetail?.age?.min}-${campaignDetail?.age?.max} years`}
                  iconUrl={ImageList.HumanHeadImageIcon}
                />
                <TagWithIcon
                  title={`Sex: ${
                    campaignDetail?.gender === "Male & Female"
                      ? "All"
                      : campaignDetail?.gender
                  }`}
                  iconUrl={ImageList.UserImageIcon}
                />
                {campaignDetail?.campaignPlatform?.platformName ===
                "youtube" ? (
                  <TagWithIcon
                    title={`Subscribers: >${nFormatter(
                      campaignDetail?.subscribers?.min
                    )}`}
                    iconUrl={ImageList.YouTubeImageIcon}
                  />
                ) : (
                  <TagWithIcon
                    title={`Followers: ${nFormatter(
                      campaignDetail?.followersRange?.min
                    )}-${nFormatter(campaignDetail?.followersRange?.max)}`}
                    iconUrl={campaignDetail?.campaignPlatform?.platformLogo}
                  />
                )}
              </div>
              {/* Do's and Don'ts */}
              <DosDontsList campaignDetail={campaignDetail} />
            </div>
          </Col>
        </Row>
      </SingleCampaignWrapper>
    </DesktopLayout>
  );
};

export default PreviewCampaign;
