import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { Shadow } from "../../../config/Values";

export const ChatRoomWrapper = styled.div`
  flex: 1 !important;
  height: 90vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .chatArea {
    flex: 1;
    overflow-y: scroll;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .typeMessageBox {
    /* height: 3rem; */
    padding: 0.7rem 1rem;
    max-height: 4rem !important;

    z-index: 200;
    background-color: ${Colors.faintedWhiteBg} !important;
  }
  .inputMessage {
    flex: 1;
    border-radius: 0.7rem;
    box-shadow: ${Shadow.light};
    min-height: 3rem;
    max-height: 6rem !important;
    background-color: ${Colors.white};
    border: none;
    padding: 0 0.9rem;
    margin-right: 0.7rem;
    white-space: pre-wrap;
    resize: none;
  }

  .inputMessage:focus {
    border: none;
    outline: none;
  }
  .sendIcon {
    background-color: ${Colors.lightPurple};
  }
  .sendIcon:hover {
    background-color: ${Colors.faintedGreen};
  }
`;
