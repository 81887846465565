import axios from "axios";

export const createStoryAPI = async (values, authtoken) => {
  return await axios.post(`${process.env.REACT_APP_API_ADMIN}/story`, values, {
    headers: {
      authorization: authtoken,
    },
  });
};

export const getStoriesAPI = async (page, limit, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/story?page=${page}&limit=${limit}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const deleteStoryAPI = async (storyId, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_ADMIN}/story?storyId=${storyId}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};
