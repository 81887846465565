import { Chip } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
const TagWithBg = ({ title, status }) => {
  return <TagWrapper size="small" label={title} status={status} />;
};

export default TagWithBg;

const TagWrapper = styled(Chip)`
  background-color: ${({ status }) =>
    status === "submitted" || status === "verified"
      ? Colors.tagGreenBg
      : status === "pending" || status === "unverified"
      ? Colors.faintedPink
      : status === "reshoot"
      ? Colors.textGrey
      : "transparent"} !important;
  color: ${({ status }) =>
    status === "submitted" || status === "verified"
      ? Colors.darkGreen
      : status === "pending" || status === "unverified"
      ? Colors.alertRed
      : status === "reshoot"
      ? Colors.white
      : Colors.textBlack} !important;
  border-radius: 6px !important;
  text-transform: capitalize;
`;
