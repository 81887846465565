import axios from "axios";

export const getAllBrandsAPI = async (values, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/users?page=${values.page}&limit=${values?.limit}&filterType=${values?.filterType}&filterValue=${values?.filterValue}`,

    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getRevenueByBrandAPI = async (page, limit, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/revenue-by-brand?page=${page}&limit=${limit}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const transferCreditToInfluencerAPI = async (values, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ADMIN}/transfer-credit`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};
