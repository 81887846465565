import { Typography } from "@mui/material";
import { Segmented } from "antd";
import React from "react";
import { capitalizeFirstLetter } from "../../../../config/Function";
import BrandInfoForm from "../../../forms/CreateUser/BrandInfoForm";
import InfluencerInfoForm from "../../../forms/CreateUser/InfluencerInfoForm";
import CardHeading from "../../../headings/CardHeading";
import { RoleBasedWrapper } from "./Style";

const RoleBasedInfo = ({ values, handleChange, errors, touched, edit }) => {
  // change role for which the registration will be made
  const handleChangeRole = (roleValue) => {
    handleChange({
      target: {
        name: "role",
        value: roleValue,
      },
    });
  };
  return (
    <RoleBasedWrapper>
      {/* heading and role selection */}

      <div className="flexBetween mb2">
        <CardHeading
          title={capitalizeFirstLetter(values?.role) + " Information"}
          weight="700"
        />
        {!edit && (
          <Segmented
            className="mtp1"
            options={[
              {
                label: <Typography className="font">Influencer</Typography>,
                value: "influencer",
              },
              {
                label: <Typography className="font">Brand</Typography>,
                value: "brand",
              },
            ]}
            value={values?.role}
            name="role"
            onChange={handleChangeRole}
          />
        )}
      </div>

      {values?.role === "influencer" ? (
        <InfluencerInfoForm
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={errors}
        />
      ) : (
        <BrandInfoForm
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={errors}
        />
      )}
    </RoleBasedWrapper>
  );
};

export default RoleBasedInfo;
