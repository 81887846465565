import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType, Shadow } from "../../../../config/Values";

export const UserCampaignWrapper = styled.div`
  flex: 1;
  height: fit-content;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1rem;
  padding-top: 0 !important;
  .campaignBanner {
    border-radius: ${BorderRadius.mediumRadius};
    object-fit: cover;
    object-position: center;
    height: 9rem;
    width: 100%;
    box-shadow: 0 0.25rem 0.375rem -0.0625rem hsla(0, 0%, 8%, 0.12),
      0 0.125rem 0.25rem -0.0625rem hsla(0, 0%, 8%, 0.07) !important;
  }
  .campaignCount {
    color: ${Colors.textGrey};
    font-size: 0.8rem;
    font-family: ${FontType.openSans}, sans-serif !important;
    margin-top: 1rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }
  .description {
    color: ${Colors.cardText};
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 0.9rem;
  }
  .viewButton {
    text-transform: inherit;
    border: 1px solid ${Colors.lightPurple};
    color: ${Colors.lightPurple};
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 8px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;
