import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import {
  BorderRadius,
  FontType,
  Gradient,
  Shadow,
} from "../../../config/Values";

export const AllCampaignWrapper = styled.div`
  min-height: 8rem;
  max-height: fit-content;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1.5rem;

  .headerContainer {
    align-items: flex-start;
    flex-direction: ${(prop) => prop.isMobile && "column"} !important;
  }
  .subHeading {
    color: ${Colors.textGrey};
    font-size: 0.875rem;
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 400;
  }
  .addButton {
    background-image: ${Gradient.primary};
    color: ${Colors.white};
    font-size: 0.7rem;
    text-transform: capitalize;
    padding: 0.48rem 1.8rem;
    border-radius: ${BorderRadius.smallRadius};
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    box-shadow: ${Shadow.light};
    margin-top: ${(prop) => prop.isMobile && "1rem"};
  }
  .addButton:hover {
    transform: translateY(-0.14rem);
    transition: all 0.3s ease-in-out;
  }

  .addButton:focus {
    outline: none;
  }
  .filterContainer {
    min-height: 6rem;
    background-color: ${Colors.white};
    border-radius: ${BorderRadius.mediumRadius};
    display: flex;
    align-items: center;
  }
  .searchByContainer {
    margin-left: 1rem;
  }
  .searchContainer {
    height: fit-content;
    flex: 1;
    max-width: 40%;
  }
  .searchIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    width: 20px;
    height: 20px;
  }

  .searchInputBox {
    /* width: ${(prop) => (prop.expanded ? "100%" : "40px")}; */
    transition: width 0.4s linear;
  }
`;
