import { Typography } from "@mui/material";
import {
  Col,
  DatePicker,
  Empty,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { formatDate, nFormatter } from "../../../config/Function";
import { ImageList } from "../../../config/ImageList";
import { LocationData, LocationState } from "../../../config/Location";
import { CategoryList } from "../../../config/Values";
import LabelInfo from "../../inputLabel/LabelInfo";
import NormalLabel from "../../inputLabel/NormalLabel";
const { Option } = Select;

const InfluencerInfoForm = ({ values, handleChange, touched, errors }) => {
  // fieldName and its value is accepted as props
  //  and passed to values using handleChange method
  const [cityList, setCityList] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);
  const [stateDataToRender, setStateDataToRender] = useState([]);
  const [searchText, setSearchText] = useState("");
  const handleSpecificChange = (e, fieldName) => {
    handleChange({
      target: {
        name: fieldName,
        value: e,
      },
    });
    setSearchText("");
  };
  const handleBudgetChange = (e) => {
    const budgetMin =
      e === 1
        ? 0
        : e === 2
        ? 10000
        : e === 3
        ? 25000
        : e === 4
        ? 50000
        : 100000;
    const budgetMax =
      e === 1
        ? 10000
        : e === 2
        ? 25000
        : e === 3
        ? 50000
        : e === 4
        ? 100000
        : 110000;
    const budgetValue = {
      min: budgetMin,
      max: budgetMax,
    };
    handleChange({
      target: {
        name: "budget",
        value: budgetValue,
      },
    });
  };
  const handleSearchState = (newValue) => {
    setSearchText(newValue);
    const filteredState = LocationState?.filter((location) =>
      location.toLowerCase().includes(newValue.toLowerCase())
    );
    setStateDataToRender(filteredState);
  };
  useEffect(() => {
    // get the array corresponding to the matched state
    const filteredState = LocationData[values?.state];
    console.log("CITY LIST==>", cityList);
    // set the value in cityList to render when there is nothing typed in city search
    setCityList(filteredState);
  }, [values?.state]);

  const handleSearchCity = (newValue) => {
    setSearchText(newValue);
    // filter the city based on the the search input
    const filteredCity = cityList?.filter((location) =>
      location.toLowerCase().includes(newValue.toLowerCase())
    );
    setDataToRender(filteredCity);
  };

  const HelperText = (value) => {
    return (
      <Typography variant="caption" color={Colors.tableText} marginLeft={2}>
        The stored value is{" "}
        <span style={{ color: Colors.alertRed }}>{value}</span>
      </Typography>
    );
  };

  return (
    <FormWrapper>
      {/* {JSON.stringify(values, null, 4)} */}
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="Gender" identifier="gender" />
          <Select
            size="large"
            id="gender"
            value={values?.gender !== "" && values?.gender}
            status={touched.gender && errors.gender ? "error" : null}
            name="gender"
            style={{ width: "100%" }}
            onChange={(e) => handleSpecificChange(e, "gender")}
          >
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
            <Option value="others">Others</Option>
          </Select>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="Age" identifier="age" />
          <InputNumber
            size="large"
            style={{ width: "100%" }}
            status={touched?.age && errors?.age ? "error" : null}
            min={14}
            max={80}
            id="age"
            name="age"
            value={values?.age}
            onChange={(e) => handleSpecificChange(e, "age")}
          />
        </Col>
      </Row>
      {/* 2nd row */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="State" identifier="state" />
          <Select
            status={touched.state && errors.state ? "error" : null}
            showSearch
            value={values?.state}
            size="large"
            placeholder="Select State"
            style={{
              width: "100%",
            }}
            onChange={(e) => handleSpecificChange(e, "state")}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchState}
            notFoundContent={<Empty />}
            options={(searchText === ""
              ? LocationState?.slice(0, 29)
              : stateDataToRender
            )?.map((d) => ({
              value: d,
              label: d,
            }))}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="City" identifier="city" />
          <Select
            status={touched.city && errors.city ? "error" : null}
            showSearch
            value={values?.city}
            size="large"
            placeholder="Select City"
            style={{
              width: "100%",
            }}
            disabled={values?.state === ""}
            onChange={(e) => handleSpecificChange(e, "city")}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchCity}
            notFoundContent={<Empty />}
            options={(searchText === ""
              ? cityList?.slice(0, 30)
              : dataToRender
            )?.map((d) => ({
              value: d,
              label: d,
            }))}
          />
        </Col>
      </Row>
      {/* 3rd row */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={24} lg={24}>
          <div style={{ justifyContent: "flex-start" }} className="flexCenter">
            <LabelInfo
              info="The categories in which this influencer create content"
              title="Categories"
              identifier="categories"
            />
            {HelperText(values?.categories?.map((x) => x.categoryName) + " ")}
          </div>
          <Select
            status={touched.categories && errors.categories ? "error" : null}
            id="categories"
            onChange={(e) => handleSpecificChange(e, "categories")}
            showArrow
            style={{ width: "100%" }}
            allowClear
            suffixIcon={
              <img
                src={ImageList.TagBlackImageIcon}
                alt="icon"
                className="tagsIcon"
              />
            }
            mode="multiple"
            maxTagCount="responsive"
            size="large"
          >
            {CategoryList?.map((item, i) => {
              return (
                <Option key={i} value={item.label}>
                  {" "}
                  {item.label}{" "}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      {/* 4th row */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={12} lg={12}>
          <div style={{ justifyContent: "flex-start" }} className="flexCenter">
            <NormalLabel title="Date of birth" identifier="DOB" />
            {HelperText(formatDate(values?.DOB))}
          </div>
          <DatePicker
            size="large"
            placeholder=""
            id="DOB"
            onChange={(e) => handleSpecificChange(e, "DOB")}
            style={{ width: "100%" }}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="Availability" identifier="availability" />

          <Select
            size="large"
            id="availability"
            value={values?.availability !== "" && values?.availability}
            status={
              touched.availability && errors.availability ? "error" : null
            }
            name="availability"
            style={{ width: "100%" }}
            onChange={(e) => handleSpecificChange(e, "availability")}
          >
            <Option value="available">Available</Option>
            <Option value="busy">busy</Option>
          </Select>
        </Col>
      </Row>
      {/* 5th row */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={12} lg={12}>
          <LabelInfo
            title="Barter"
            info="Is influencer ready to work in barter system with the brands."
            identifier="barter"
          />
          <div className="barterContainer">
            <Typography variant="body2" className="font barterText">
              Barter?
            </Typography>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(e) => handleSpecificChange(e, "barterAvailability")}
              checked={values?.barterAvailability}
            />
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div style={{ justifyContent: "flex-start" }} className="flexCenter">
            <NormalLabel title="Budget" identifier="budget" />
            {HelperText(
              nFormatter(values?.budget?.min) +
                " to " +
                nFormatter(values?.budget?.max)
            )}
          </div>
          <Select
            size="large"
            id="budget"
            status={touched.budget && errors.budget ? "error" : null}
            name="budget"
            style={{ width: "100%" }}
            onChange={handleBudgetChange}
          >
            <Option value={1}>0₹-10K₹</Option>
            <Option value={2}>10K₹-25K₹</Option>
            <Option value={3}>25K₹-50K₹</Option>
            <Option value={4}>50K₹-100K₹</Option>
            <Option value={5}>₹1L+</Option>
          </Select>
        </Col>
      </Row>
      {/* 6th row */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={12} lg={12}>
          <div style={{ justifyContent: "flex-start" }} className="flexCenter">
            <NormalLabel
              title="Instagram Profile Link"
              identifier="instagramProfile"
            />
          </div>
          <Input
            name="instagramProfile"
            id="instagramProfile"
            value={values.instagramProfile}
            size="large"
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel
            title="Instagram Profile Picture"
            identifier="instagramDP"
          />
          <Input
            name="instagramDP"
            id="instagramDP"
            value={values.instagramDP}
            size="large"
            onChange={handleChange}
          />
        </Col>
      </Row>
      {/* 7th row */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={12} lg={12}>
          <div style={{ justifyContent: "flex-start" }} className="flexCenter">
            <NormalLabel
              title="Instagram Followers"
              identifier="instagramFollowers"
            />
            {HelperText("like 130K, 1.3M")}
          </div>
          <Input
            size="large"
            style={{ width: "100%" }}
            id="instagramFollowers"
            name="instagramFollowers"
            value={values?.instagramFollowers}
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel
            title="Instagram Followings"
            identifier="instagramFollowing"
          />

          <Input
            size="large"
            style={{ width: "100%" }}
            id="instagramFollowing"
            name="instagramFollowing"
            value={values?.instagramFollowing}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </FormWrapper>
  );
};

export default InfluencerInfoForm;

const FormWrapper = styled.div`
  width: 100%;
  .tagsIcon {
    width: 15px;
    height: 15.83px;
    margin-right: 10px;
    left: 10px !important;
  }
  .barterContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    width: 100%;
  }
  .barterText {
    margin-right: 1rem;
  }
`;
