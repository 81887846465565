import { Typography } from "@mui/material";
import { Divider } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { FontType } from "../../../config/Values";
import { chatUser } from "../../../redux/chatUserSlice";
import { calTime } from "../../../config/Function";

const SingleUserInfoCard = ({ item, i }) => {
  const dispatch = useDispatch();
  // as soon as any name is clicked, information of that user will be dispatched
  // and stored in redux so that we can easily fill information in navbar of that particular room.
  const openChatBox = () => {
    dispatch(
      chatUser({
        id: item?.id,
        uid: item?.connectionId,
        name: item?.receiverName,
        imageUrl: item?.imageUrl,
        room: item?.room,
        uploader: item?.uploader,
        connectionId: item?.connectionId,
        isClosed: item?.isClosed,
      })
    );
  };

  return (
    <>
      {i !== 0 && <Divider style={{ margin: "0.4rem 0" }} />}
      <InfoWrapper onClick={openChatBox}>
        <img src={item.imageUrl} alt={item.receiverName} className="avatar" />
        <div className="textContainer">
          <Typography className="userName">{item.receiverName}</Typography>
          <Typography className="lastMessage">
            {item?.lastMessage !== ""
              ? item?.lastMessage?.length > 60
                ? item?.lastMessage?.substring(0, 60) + "..."
                : item?.lastMessage
              : item?.uploader?.isRequired
              ? "Media"
              : ""}
          </Typography>
        </div>
        <Typography className="time"> {calTime(item?.timeStamps)}</Typography>
      </InfoWrapper>
    </>
  );
};

export default SingleUserInfoCard;

const InfoWrapper = styled.div`
  background-color: ${Colors.white};
  padding: 0.5rem;
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  cursor: pointer;
  .avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 3rem;
    margin-right: 1rem;
    object-fit: cover;
    object-position: center;
  }
  .textContainer {
    flex: 1;
  }
  .userName {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 600;
    color: ${Colors.textBlack};
    text-transform: capitalize;
  }
  .lastMessage {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 400;
    color: ${Colors.cardText};
    font-size: small;
  }
  .time {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 400;
    color: ${Colors.cardText};
    font-size: 0.5rem;
  }
`;
