import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { useSelector } from "react-redux";
import StepFourForm from "../../../components/forms/CreateCampaign/StepFour";
import StepOneForm from "../../../components/forms/CreateCampaign/StepOne";
import StepThreeForm from "../../../components/forms/CreateCampaign/StepThree";
import StepTwoForm from "../../../components/forms/CreateCampaign/StepTwo";
import StepZero from "../../../components/forms/EditCampaign/StepZero";
import CardHeading from "../../../components/headings/CardHeading";
import AntSteps from "../../../components/stepper/AntSteps";
import { ImageList } from "../../../config/ImageList";
import DesktopLayout from "../../../theme/DesktopLayout";
import useViewport from "../../../viewport/useViewport";
import { LocalStorageIdentifier } from "../../../config/LocalStorageInfo";
import { EditWrapper } from "./EditStyle";
import { useParams } from "react-router-dom";
import { getSingleCampaignDetailAPI } from "../../../functions/campaign";
import {
  fetchCampaignDetailAsync,
  setBrandUserId,
} from "../../../redux/createCampaignSlice";
import { useDispatch } from "react-redux";

const EditCampaign = () => {
  const { currentStep } = useSelector((state) => state.stepper);
  const user = useSelector((state) => state.user);
  const { isMidrange, isTablet } = useViewport();
  const { campaignId } = useParams();
  const [campaignData, setCampaignData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    // make api all and prefill the localstorage values
    if (user?.token !== "") {
      dispatch(
        fetchCampaignDetailAsync({ campaignId, authtoken: user?.token })
      );
    }
  }, [campaignId, user]);
  useEffect(() => {
    if (user) {
      dispatch(setBrandUserId({ brandUserId: user?._id }));
    }
  }, [user]);
  return (
    <DesktopLayout>
      <EditWrapper isMidrange={isMidrange} isTablet={isTablet}>
        <div className="mt2 formContainer">
          <>
            {" "}
            <div className="headingContainer">
              <img
                className="loudspeakerIcon"
                src={ImageList.LoudspeakerImageIcon}
                alt="speaker"
              />{" "}
              <CardHeading title="Campaign Info" weight="700" />
            </div>
            <StepOneForm campaignData={campaignData} />{" "}
          </>

          <>
            {" "}
            <div className="headingContainer">
              <CardHeading title="Influencer Info" weight="700" />
            </div>
            <StepTwoForm />{" "}
          </>

          <>
            {" "}
            <div className="headingContainer">
              <CardHeading title="Campaign Media" weight="700" />
            </div>
            <StepThreeForm />{" "}
          </>

          <>
            {" "}
            <div className="headingContainer">
              <CardHeading title="Publish Your Campaign" weight="700" />
            </div>
            <StepFourForm edit={true} campaignId={campaignId} />{" "}
          </>
        </div>
      </EditWrapper>
    </DesktopLayout>
  );
};

export default EditCampaign;
