import React from "react";
import DesktopLayout from "../../../theme/DesktopLayout";
import { AllStoryWrapper } from "./AllStoryStyle";
import { Avatar, Table } from "antd";
import { Typography } from "@mui/material";
import { formatDate } from "../../../config/Function";
const { Column } = Table;

const AllStory = () => {
  const data = [
    {
      storyMediaUrl:
        "https://res.cloudinary.com/axbordev/video/upload/v1681409082/txqerfee1rl3oos7gzc8.mp4",
      storyMediaThumbnail:
        "https://res.cloudinary.com/axbordev/video/upload/v1681409082/txqerfee1rl3oos7gzc8.jpg",
      storyType: "video",
      textMessage: "",
      createdAt: "2021-06-23T07:59:52.708+00:00",
    },
    {
      storyMediaUrl: "",
      storyMediaThumbnail: "",
      storyType: "text",
      textMessage:
        "beforeUpload only prevent reject promise, the prevented file would still show in file list. Here is the example you can keep prevented files out of list by return UPLOAD.LIST_IGNORE.",
      createdAt: "2021-06-23T07:59:52.708+00:00",
    },
  ];
  return (
    <DesktopLayout>
      <AllStoryWrapper>
        <Table
          dataSource={data}
          scroll={{
            y: 540,
            x: 1200,
          }}
        >
          <Column
            width={150}
            title="Story Type"
            dataIndex="storyType"
            key="storyType"
            fixed="left"
          />

          <Column
            title="Uploaded On"
            dataIndex="createdAt"
            key="createdAt"
            width={150}
            render={(item) => <Typography> {formatDate(item)} </Typography>}
          />
          <Column
            title="Story"
            dataIndex="storyMediaUrl"
            key="storyMediaUrl"
            width={200}
            render={(item) => {
              return (
                <>
                  {item === "" ? (
                    <Avatar size="large" shape="square">
                      T
                    </Avatar>
                  ) : (
                    <a
                      href={item === "" ? "##" : item}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Avatar
                        src={item?.replace(".mp4", ".jpg")}
                        size="large"
                        shape="square"
                      />
                    </a>
                  )}
                </>
              );
            }}
          />

          <Column
            title="Story Text"
            dataIndex="textMessage"
            key="textMessage"
          />
        </Table>
      </AllStoryWrapper>
    </DesktopLayout>
  );
};

export default AllStory;
