import { InputLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import { Colors } from "../../../../config/Colors";
import SmallHeading from "../../../headings/SmallHeading";
import { UserSettingsWrapper } from "./Style";
import { Switch } from "antd";

const UserSettings = () => {
  const [settingValues, setSettingValues] = useState({
    emailOnShortlist: true,
    emailOnRejection: false,
    emailOnMessage: false,
    emailOnNewCampaign: false,
    emailOnMonthlyUpdate: true,
    subscribeToNewsLetter: false,
  });

  const onChange = (checked, name) => {
    setSettingValues({ ...settingValues, [name]: checked });
    console.log(`switch to ${checked}=====${name}`);
  };

  const SettingOptions = (value, name, text) => {
    return (
      <div className="singleSetting mt1">
        <Switch
          checked={value}
          onChange={(e) => onChange(e, name)}
          className="switch"
        />
        <Typography className="settingText">{text}</Typography>
      </div>
    );
  };

  return (
    <UserSettingsWrapper>
      <SmallHeading
        title="Platform Settings"
        color={Colors.textBlack}
        weight="500"
      />
      <InputLabel className="settingsLabel">Account</InputLabel>
      {/* email on shortlist */}
      {SettingOptions(
        settingValues?.emailOnShortlist,
        "emailOnShortlist",
        "Email me when a brand shortlists me"
      )}
      {/* email on rejection */}
      {SettingOptions(
        settingValues?.emailOnRejection,
        "emailOnRejection",
        "Email me when a brand rejects me"
      )}
      {/* email on message */}
      {SettingOptions(
        settingValues?.emailOnMessage,
        "emailOnMessage",
        "Email me when I receive any message"
      )}
      {/* label for Application */}
      <InputLabel className="settingsLabel mt3">Application</InputLabel>
      {/* mail on new launch and campaigns */}
      {SettingOptions(
        settingValues?.emailOnNewCampaign,
        "emailOnNewCampaign",
        "New launches and campaigns"
      )}
      {/* monthly campaign update */}
      {SettingOptions(
        settingValues?.emailOnMonthlyUpdate,
        "emailOnMonthlyUpdate",
        "Monthly update on campaigns"
      )}
      {/* subscribe to news letter */}
      {SettingOptions(
        settingValues?.subscribeToNewsLetter,
        "subscribeToNewsLetter",
        "Monthly update on campaigns"
      )}
    </UserSettingsWrapper>
  );
};

export default UserSettings;
