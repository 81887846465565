import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
import { nFormatter } from "../../config/Function";
import { LocalStorageIdentifier } from "../../config/LocalStorageInfo";

const CampaignDescription = ({ campaignDetail, apiCall }) => {
  let brandInfo =
    localStorage.getItem(LocalStorageIdentifier.stepZeroData) !== "" &&
    localStorage.getItem(LocalStorageIdentifier.stepZeroData) !== null &&
    JSON.parse(localStorage.getItem(LocalStorageIdentifier.stepZeroData));

  return (
    <DescriptionWrapper>
      {/* {JSON.stringify(campaignDetail.gender, null, 4)} */}
      <Typography className="description mt2">
        <span className="descriptionHighlight">
          {" "}
          {apiCall ? campaignDetail?.brand?.name : brandInfo?.label}
        </span>{" "}
        requires{" "}
        <span className="descriptionHighlight"> {campaignDetail?.gender} </span>{" "}
        influencers, for{" "}
        <span className="descriptionHighlight">
          {campaignDetail?.campaignPlatform?.platformName}{" "}
        </span>
        with{" "}
        <span className="descriptionHighlight">
          {" "}
          {nFormatter(campaignDetail?.followersRange?.min) +
            "-" +
            nFormatter(campaignDetail?.followersRange?.max)}
        </span>{" "}
        followers and age between{" "}
        <span className="descriptionHighlight">
          {campaignDetail?.age?.min + " - " + campaignDetail?.age?.max}
        </span>{" "}
        years, that are active in the categories -{" "}
        {campaignDetail?.campaignCategories?.map((item, i) => (
          <span key={i} className="descriptionHighlight">
            {apiCall ? item.categoryName : item}
            {campaignDetail?.campaignCategories?.length - 2 === i
              ? ","
              : campaignDetail?.campaignCategories?.length - 1 === i
              ? "."
              : ","}{" "}
          </span>
        ))}
      </Typography>
    </DescriptionWrapper>
  );
};

export default CampaignDescription;

const DescriptionWrapper = styled.div`
  .description {
    font-size: 1.07rem;
    font-weight: 300;
    color: ${Colors.textBlack};
  }
  .descriptionHighlight {
    font-weight: 500;
  }
`;
