import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
import { FontType } from "../../config/Values";
const NormalText = ({ title, format }) => {
  return (
    <TextWrapper variant="body1" format={format}>
      {title}
    </TextWrapper>
  );
};

export default NormalText;

const TextWrapper = styled(Typography)`
  font-family: ${FontType.openSans}, sans-serif !important;
  color: ${Colors.textBlack};
  font-weight: 500 !important;
  text-transform: ${(prop) => prop.format && "capitalize"};
`;
