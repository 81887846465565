import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

export const FormFourWrapper = styled.div`
  width: 100%;
  height: auto;
  /* previous and next button */
  .prevButton {
    color: ${Colors.lightPurple} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border: 2px solid ${Colors.lightPurple};
    border-radius: ${BorderRadius.smallRadius};
    opacity: 0.5;
  }
  .prevButton:hover {
    border: 2px solid ${Colors.lightPurple};
  }
  .nextButton {
    background-color: ${Colors.lightPurple} !important;
    color: ${Colors.white} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    border: 2px solid ${Colors.lightPurple};
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: ${BorderRadius.smallRadius};
  }
`;
