import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

export const UserSettingsWrapper = styled.div`
  flex: 1;
  /* height: 100%; */
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1.5rem;
  .settingsLabel {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 700;
    color: ${Colors.textGrey};
    text-transform: uppercase;
    font-size: 0.78rem;
  }
  .ant-switch-checked {
    background-color: ${Colors.lightPurple} !important;
  }
  .ant-switch {
    background-color: ${Colors.switchBg};
  }
  .ant-switch:hover {
    background-color: ${Colors.switchBg};
  }
  .ant-switch-checked:hover {
    background-color: ${Colors.lightPurple} !important;
  }
  .singleSetting {
    display: flex;
    align-items: center;
  }
  .switch {
    margin-right: 0.8rem;
  }
  .settingText {
    font-family: ${FontType.openSans}, sans-serif !important;
    color: ${Colors.cardText};
    font-weight: 400;
    font-size: 0.9rem;
  }
`;
