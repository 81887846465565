import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

export const StepTwoWrapper = styled.div`
  width: 100%;
  height: auto;

  .inputContainer {
    margin-bottom: 1rem;
    flex: 1;
  }

  .tagsIcon {
    width: 15px;
    height: 15.83px;
    margin-right: 10px;
    left: 10px !important;
  }
  /* ant input box css override */
  .ant-input {
    color: ${Colors.textBlack} !important;
    font-weight: 300 !important;
    font-family: ${FontType.openSans}, sans-serif !important;
  }
  .alertMessage {
    color: ${Colors.alertRed} !important;
    margin-left: 0.2rem;
    font-size: 12px;
  }
  .dateContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
  }
  .categorySelect span.ant-select-arrow {
    margin-left: 10px;
    position: absolute;
    left: 0;
  }

  .ant-select-selection-overflow-item:first-child {
    padding-left: 28px !important;
  }
  .ant-select-selection-overflow-item:nth-last-child(2) {
    padding-left: 40px !important;
  }
  .ant-select-selection-placeholder {
    padding-left: 28px;
  }
  .ageInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }
  .ageInput {
    width: 4.563rem;
    color: ${Colors.textBlack} !important;
  }
  .ageInput::placeholder {
    text-align: center;
    font-weight: 400;
  }
  .ageSeparatorText {
    margin: 0 0.43rem;
    font-size: 10px;
    color: ${Colors.textBlack} !important;
    font-weight: 600;
  }
  .barterContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 40px;
    width: 100%;
  }
  .barterText {
    margin-right: 0.8rem !important;
    color: ${Colors.textBlack} !important;
    font-size: 14px;
    font-weight: 400;
  }

  .ant-switch-checked {
    background-color: ${Colors.lightGreen};
  }
  /* previous and next button */
  .prevButton {
    color: ${Colors.lightPurple} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border: 2px solid ${Colors.lightPurple};
    border-radius: ${BorderRadius.smallRadius};
    opacity: 0.5;
  }
  .prevButton:hover {
    border: 2px solid ${Colors.lightPurple};
  }
  .nextButton {
    background-color: ${Colors.lightPurple} !important;
    color: ${Colors.white} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    border: 2px solid ${Colors.lightPurple};
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: ${BorderRadius.smallRadius};
  }
`;
