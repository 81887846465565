import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius, FontType, Shadow } from "../../../config/Values";

export const RecentCampaignWrapper = styled.div`
  height: 100%;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  .headerBox {
    padding: 1.5rem 1.5rem 1.3rem 1.5rem;
  }
  .brandLogo {
    width: 3.7em;
    height: 3.7em;
    border-radius: 0.8rem;
  }
  .basicInfo {
    flex: 1;
    padding-left: 1rem;
  }
  .nameAndTime {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .brandName {
    color: ${Colors.textBlack};
    font-family: ${FontType.openSans}, sans-serif !important;
  }
  .time {
    color: ${Colors.textGrey};
    font-weight: 400;
    font-size: 0.9rem;
  }
  .editButton {
    border-radius: 0.55rem;
    padding: 0.2rem 1rem;
    background: ${Colors.lightPurple};
    text-transform: capitalize;
  }
  .editButton:hover {
    background: ${Colors.lightPurple};
  }
  /* =================content================= */
  .contentContainer {
    padding: 0rem 1.5rem;
  }
  .description {
    color: ${Colors.cardText};
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 300;
    font-size: 1rem;
  }
  .campaignBanner {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    object-fit: cover;
    object-position: center;
    box-shadow: ${Shadow.dark};
  }

  .iconContainer {
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 0.4rem;
  }
  .influencerInfo {
    display: flex;
    align-items: center;
  }
`;
