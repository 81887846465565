import { configureStore } from "@reduxjs/toolkit";
import todoReducer from "./todoSlice";
import navigationReducer from "./navigationSlice";
import sideNavStatusReducer from "./sideNavExpansionSlice";
import snackbarReducer from "./snackbarSlice";
import bannerReducer from "./bannerSlice";
import userReducer from "./currentUserSlice";
import chatUserReducer from "./chatUserSlice";
import uploadedImageReducer from "./imageUploadSlice";
import stepReducer from "./stepSlice";
import searchTypeReducer from "./searchTypeSlice";
import monitorDataReducer from "./monitorDataSlice";
import createCampaignReducer from "./createCampaignSlice";
import campaignChatInfoReducer from "./campaignChatSlice";
export default configureStore({
  reducer: {
    todos: todoReducer,
    currentLocation: navigationReducer,
    sideNavStatus: sideNavStatusReducer,
    snackbarInfo: snackbarReducer,
    banner: bannerReducer,
    user: userReducer,
    chatUser: chatUserReducer,
    uploadedImage: uploadedImageReducer,
    stepper: stepReducer,
    search: searchTypeReducer,
    monitorData: monitorDataReducer,
    createCampaign: createCampaignReducer,
    campaignChatInfo: campaignChatInfoReducer, //this stores the value of campaign id and name so as to summonthe chats of that campaign
  },
});
