import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import CardHeading from "../../headings/CardHeading";
import { TodoWrapper } from "./TodoStyle";
import { Colors } from "../../../config/Colors";
import SingleTodo from "./SingleTodo";
import { Divider, Empty } from "antd";
import AddTodoModal from "../../modal/AddTodoModal";
import { useState } from "react";
import { Icons } from "../../../config/IconsList";
import { getTodosAsync } from "../../../redux/todoSlice";
import { useDispatch, useSelector } from "react-redux";

const TodoListCard = () => {
  const todos = useSelector((state) => state.todos);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // useState
  const [isModalOpen, setIsModalOpen] = useState(false);
  // functions
  const showModal = () => {
    setIsModalOpen(true);
  };
  // useEffect
  useEffect(() => {
    if (user?.token !== "") {
      dispatch(getTodosAsync(user?.token));
    }
  }, [dispatch, user]);

  // jsx
  return (
    <TodoWrapper>
      {/* {JSON.stringify(todos, null, 4)} */}
      <AddTodoModal
        showModal={showModal}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        user={user?.token !== "" && user}
      />
      <div className="flexBetween">
        <CardHeading title="To Do List" />
        <IconButton
          aria-label="add new to-do"
          style={{ padding: 0 }}
          onClick={showModal}
        >
          <Icons.AddIcon style={{ color: Colors.textBlack }} />
        </IconButton>
      </div>
      <div className="main_box mt3">
        {todos?.map((item, i) => {
          return (
            <div key={i}>
              {i !== 0 && <Divider style={{ margin: ".8em 0" }} />}
              <SingleTodo todo={item} i={i} />
            </div>
          );
        })}
        {todos?.length === 0 && <Empty />}
      </div>
    </TodoWrapper>
  );
};

export default TodoListCard;
