import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius } from "../../../config/Values";

export const AllUserWrapper = styled.div`
  .filterContainer {
    min-height: 4rem;
    max-height: fit-content;
    padding: 1.5rem;
    background-color: ${Colors.white};
    border-radius: ${BorderRadius.mediumRadius};
  }
`;
