import { Typography } from "@mui/material";
import React from "react";
import { numberWithCommas } from "../../../../config/Function";
import { Icons } from "../../../../config/IconsList";
import { AllUsersCardWrapper } from "./Style";

const AllUsersCard = ({ Icon, amount, title, percent, percentType }) => {
  return (
    <AllUsersCardWrapper>
      <div className="flexBetween" style={{ alignItems: "flex-start" }}>
        <div className="iconContainer flexCenter">
          <Icon />
        </div>
        <Icons.MoreVertIcon className="moreIcon" />
      </div>
      {/* amount */}
      <div className="amount mtp5">{numberWithCommas(amount)}</div>
      {/* title and % info */}
      <div className="flexBetween">
        <Typography className="title"> {title} </Typography>
        <Typography className="percentage title">
          {" "}
          {percentType === "gain" ? "+" : "-"}
          {percent}%{" "}
        </Typography>
      </div>
    </AllUsersCardWrapper>
  );
};

export default AllUsersCard;
