import { Progress } from "antd";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import NormalText from "../../headings/NormalText";

const SingleCampaignTrack = ({ campaign }) => {
  let percentCompleted = Math.round(
    (campaign.shortlisted / campaign.totalNeeded) * 100
  );
  return (
    <SingleTrackWrapper className="flexBetween">
      <img
        src={campaign?.campaignBanner}
        alt={campaign?.campaignName}
        className="brandLogo"
      />
      <div className="contentContainer">
        <NormalText title={campaign?.campaignName.substring(0, 20) + "..."} />
        <Progress
          strokeLinecap="round"
          percent={percentCompleted}
          status="active"
          strokeColor={{
            "0%": "#108ee9",
            "100%": percentCompleted < 50 ? Colors.alertRed : "#87d068",
          }}
        />
      </div>
    </SingleTrackWrapper>
  );
};

export default SingleCampaignTrack;

const SingleTrackWrapper = styled.div`
  .ant-progress-bg {
    height: 3px !important;
  }
  .brandLogo {
    height: 3.5em;
    width: 3.5em;
    border-radius: 1rem;
    object-fit: cover;
    object-position: center;
  }
  .contentContainer {
    flex: 1;
    padding-left: 1.5rem;
  }
`;
