import { Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Icons } from "../../config/IconsList";
import FadingDivider from "../dividers/FadingDivider";
import { DeskNavWrapper } from "./NavStyles";
import { NavLink, useLocation } from "react-router-dom";
import { NavOptions } from "../../config/NavOptions";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentLocation } from "../../redux/navigationSlice";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { logoutUser } from "../../redux/currentUserSlice";
const NavigatorDesktop = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const user = useSelector((state) => state.user);
  // this state wether the sidenav is expanded or not
  const sideNavStatus = useSelector((state) => state.sideNavStatus);

  // run this every time location is changed
  useEffect(() => {
    let newLocationObj = NavOptions.filter(
      (item) => item?.linkTo === location.pathname
    )[0];
    // call dispatch
    if (
      newLocationObj?.title === "" ||
      newLocationObj?.folderName === "" ||
      !newLocationObj?.title ||
      !newLocationObj?.folderName
    ) {
      let myTitle = location.pathname.split("/")[1];
      let myFolderName = "Variable";
      newLocation(myTitle, myFolderName);
    } else {
      newLocation(newLocationObj?.title, newLocationObj?.folderName);
    }
  }, [location.pathname]);

  const newLocation = (title, folderName) => {
    dispatch(
      changeCurrentLocation({
        title,
        folderName,
      })
    );
  };

  const logout = async () => {
    await signOut(auth)
      .then(() => {
        console.log("User signed out.");
        dispatch(logoutUser());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <DeskNavWrapper isSideNavExpanded={sideNavStatus.isSideNavExpanded}>
      {/* logo */}
      <div className="logoContainer">
        <img src={Icons.logo} className="logo_image" alt="Yellow Box Logo" />
        <p className="company_name">The Yellow Box</p>
      </div>
      <FadingDivider />
      {/* Dashboard Navigation Options */}
      {NavOptions?.map((item, index) => {
        return (
          <NavLink
            to={item.linkTo}
            key={index}
            className={({ isActive }) =>
              isActive ? "activeClassName" : "inactiveClassName"
            }
          >
            <item.icon className="iconStyle" />
            <Typography className="dashboard_title">{item.title}</Typography>
          </NavLink>
        );
      })}
      {user && (
        <Button variant="text" onClick={logout}>
          Logout
        </Button>
      )}
    </DeskNavWrapper>
  );
};

export default NavigatorDesktop;
