import { createSlice } from "@reduxjs/toolkit";

export const chatUserSlice = createSlice({
  name: "chatUser",
  initialState: {
    id: "",
    uid: "",
    name: "",
    room: "",
    imageUrl: "",
    uploader: "",
    connectionId: "",
    isClosed: "",
  },
  reducers: {
    chatUser: (state, action) => {
      state.id = action.payload.id;
      state.uid = action.payload.uid;
      state.name = action.payload.name;
      state.room = action.payload.room;
      state.imageUrl = action.payload.imageUrl;
      state.uploader = action.payload.uploader;
      state.connectionId = action.payload.connectionId;
      state.isClosed = action.payload.isClosed;
    },
  },
});

export const { chatUser } = chatUserSlice.actions;
export default chatUserSlice.reducer;
