import { Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
import { Icons } from "../../config/IconsList";
import { FontType } from "../../config/Values";

const LabelInfo = ({ title, identifier, info }) => {
  return (
    <LabelWrapper>
      <label htmlFor={identifier}> {title} </label>
      <Tooltip title={info}>
        <Icons.InfoIconOutlined className="infoIcon" />
      </Tooltip>
    </LabelWrapper>
  );
};

export default LabelInfo;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  label {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: ${Colors.textBlack};
    line-height: 2rem;
    margin-left: 3px;
  }
  .infoIcon {
    font-size: 11px;
    margin-left: 0.5rem;
    font-weight: 300 !important;
  }
`;
