import React from "react";
import MyMessages from "../../components/messages";
import DesktopLayout from "../../theme/DesktopLayout";

const BrandChat = () => {
  return (
    <DesktopLayout>
      <MyMessages />
    </DesktopLayout>
  );
};

export default BrandChat;
