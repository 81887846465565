import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { setSideNavExpanded } from "../../redux/sideNavExpansionSlice";
import { NavOptions } from "../../config/NavOptions";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { Colors } from "../../config/Colors";
import { changeCurrentLocation } from "../../redux/navigationSlice";
import FadingDivider from "../dividers/FadingDivider";
import { Icons } from "../../config/IconsList";
import { BorderRadius, PaddingValues } from "../../config/Values";

export default function SideDrawer() {
  const dispatch = useDispatch();
  let location = useLocation();
  // this state whether the sidenav is expanded or not
  const sideNavStatus = useSelector((state) => state.sideNavStatus);

  // this function toggle the expansion state of side nav
  const toggleNavExpansion = () => {
    dispatch(
      setSideNavExpanded({
        isSideNavExpanded: !sideNavStatus.isSideNavExpanded,
        motionType: "clicked",
      })
    );
  };
  // run this every time location is changed
  React.useEffect(() => {
    let newLocationObj = NavOptions.filter(
      (item) => item.linkTo === location.pathname
    )[0];
    // call dispatch
    if (
      newLocationObj?.title === "" ||
      newLocationObj?.folderName === "" ||
      !newLocationObj?.title ||
      !newLocationObj?.folderName
    ) {
      let myTitle = location.pathname.split("/")[1];
      let myFolderName = "Variable";
      newLocation(myTitle, myFolderName);
    } else {
      newLocation(newLocationObj?.title, newLocationObj?.folderName);
    }
  }, [location.pathname]);

  const newLocation = (title, folderName) => {
    dispatch(
      changeCurrentLocation({
        title,
        folderName,
      })
    );
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleNavExpansion}
      onKeyDown={toggleNavExpansion}
    >
      <OptionWrapper isSideNavExpanded={sideNavStatus.isSideNavExpanded}>
        {/* logo */}
        <div className="logoContainer">
          <img src={Icons.logo} className="logo_image" alt="Yellow Box Logo" />
          <p className="company_name">The Yellow Box</p>
        </div>
        <FadingDivider />
        {NavOptions?.map((item, index) => {
          return (
            <NavLink
              to={item.linkTo}
              key={index}
              className={({ isActive }) =>
                isActive ? "activeClassName" : "inactiveClassName"
              }
            >
              <item.icon className="iconStyle" />
              <Typography className="dashboard_title">{item.title}</Typography>
            </NavLink>
          );
        })}
      </OptionWrapper>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="left"
          open={sideNavStatus.isSideNavExpanded}
          onClose={toggleNavExpansion}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

// css styling with styled component to ensure active and inactive route styling
const OptionWrapper = styled.div`
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: ${(prop) => !prop.isSideNavExpanded && "center"};
    padding: ${PaddingValues.smallPadding};
  }
  .logo_image {
    max-width: 100%;
    max-height: 2rem;
  }
  .company_name {
    color: ${Colors.textGrey};
    margin: 0 0 0 0.7em;
    font-weight: 600;
    font-size: ${(prop) => (prop.isSideNavExpanded ? "16px" : "0px")};
    transition: all 0.5s ease-in-out;
  }
  .inactiveClassName {
    display: flex;
    align-items: center;
    justify-content: ${(prop) => !prop.isSideNavExpanded && "center"};
    padding: ${PaddingValues.smallPadding};
    text-decoration: none;
  }
  .activeClassName {
    display: flex;
    align-items: center;
    justify-content: ${(prop) => !prop.isSideNavExpanded && "center"};
    padding: ${PaddingValues.smallPadding};
    text-decoration: none;
    background-color: ${Colors.activeBg};
    border-radius: ${BorderRadius.smallRadius};
    color: ${Colors.black};
  }
  .iconStyle {
    font-size: 1.2em;
    color: ${Colors.lightPurple};
  }
  .dashboard_title {
    font-family: "Open Sans", sans-serif !important;
    margin-left: 0.8em;
    font-size: 0.9em;
    font-weight: 500;
    color: ${Colors.black70};
    font-size: ${(prop) => (prop.isSideNavExpanded ? "14px" : "0px")};
    transition: all 0.5s ease-in-out;
  }
`;
