import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
export const getTodosAsync = createAsyncThunk(
  "todos/getTodosAsync",
  async (authtoken) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ADMIN}/todo`,
      {
        headers: {
          authorization: authtoken,
        },
      }
    );

    if (response.data.success) {
      const newArr = response.data?.todos?.sort(function (a, b) {
        let dateA = new Date(a.createdAt),
          dateB = new Date(b.createdAt);
        return dateB - dateA;
      });

      return newArr;
    }
  }
);

export const addTodoAsync = createAsyncThunk(
  "todos/addTodoAsync",
  async (values, thunkAPI) => {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_ADMIN}/todo`,
      {
        description: values.description,
        isPrivate: values.isPrivate,
        time: values.time,
      },
      {
        headers: {
          authorization: values.token,
        },
      }
    );

    if (resp.data.success) {
      message.success(
        `${resp.data.todoItem.description} has been added to your list.`
      );
      return resp.data.todoItem;
    }
  }
);

export const toggleCompleteAsync = createAsyncThunk(
  "todos/completeTodoAsync",
  async (values, thunkAPI) => {
    const resp = await axios.patch(
      `${process.env.REACT_APP_API_ADMIN}/todo`,
      { isCompleted: values?.isCompleted, todoId: values?.todoId },
      {
        headers: {
          authorization: values?.token,
        },
      }
    );
    if (resp.data.success) {
      console.log("TOGGLE RESPONSE==>", resp);
      return resp.data.todoItem;
    } else {
      return values.todoId;
    }
  }
);

export const deleteTodoAsync = createAsyncThunk(
  "todos/deleteTodoAsync",
  async (values, thunkAPI) => {
    console.log("MY TOEKN TOKEN TOKEN==>", values);

    const resp = await axios.delete(
      `${process.env.REACT_APP_API_ADMIN}/todo?todoId=${values?._id}`,
      {
        headers: {
          authorization: values?.token,
        },
      }
    );
    if (resp.data.success) {
      console.log("THUNK RESPONSE==>", resp);
      message.success("Deleted Successfully");
      return resp.data.deletedTodo;
    }
  }
);

export const todoSlice = createSlice({
  name: "todos",
  initialState: [],
  extraReducers: (builder) => {
    builder.addCase(getTodosAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(addTodoAsync.fulfilled, (state, { payload }) => {
      state.unshift(payload);
    });
    builder.addCase(toggleCompleteAsync.fulfilled, (state, { payload }) => {
      const index = state.findIndex((todo) => todo._id === payload._id);
      state[index].isCompleted = payload.isCompleted;
    });
    builder.addCase(deleteTodoAsync.fulfilled, (state, { payload }) => {
      return state.filter((todo) => todo._id !== payload._id);
    });
  },
});

export const { addTodo, toggleComplete, deleteTodo } = todoSlice.actions;

export default todoSlice.reducer;
