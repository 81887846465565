import "./App.css";
import React, { useEffect } from "react";
import ViewportProvider from "./viewport";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SettingScreen from "./pages/settings";
import HomeScreen from "./pages/home";
// react-slick css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomSnackbar from "./components/snackbar/CustomSnackbar";
import UserProfile from "./pages/user/profile";
import { useDispatch, useSelector } from "react-redux";
import { changeBannerBg } from "./redux/bannerSlice";
import { Icons } from "./config/IconsList";
import AllUsers from "./pages/user/all";
import AllCampaigns from "./pages/campaigns/all";
import SingleCampaign from "./pages/campaigns/SingleCampaign";
import CreateCampaign from "./pages/campaigns/create/CreateCampaign";
import Login from "./pages/auth/Login/Login";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseConfig";
import { checkPhoneNumber } from "./functions/auth";
import { getAsyncUser, noUser } from "./redux/currentUserSlice";
import { message } from "antd";
import ProtectedRoute from "./components/route/ProtectedRoute";
import PreviewCampaign from "./pages/preview";
import EditCampaign from "./pages/campaigns/edit";
import CreateUser from "./pages/user/create";
import PageNotFound from "./pages/PageNotFound";
import AllInfluencer from "./pages/influencer";
import CampaignWiseRevenue from "./pages/revenue/campaignWise";
import BrandWiseRevenue from "./pages/revenue/brandWise/BrandWiseRevenue";
import EditUserProfile from "./pages/user/edit";
import UnverifiedCampaign from "./pages/campaigns/unverified";
import CreateReels from "./pages/reels/create";
import AllReels from "./pages/reels/all";
import CreateStory from "./pages/story/create";
import AllStory from "./pages/story/all";
import UnAuthorized from "./pages/UnAuthorize";
import BrandChat from "./pages/chat";
import InstaData from "./pages/campaigns/data/InstaData";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRed = useSelector((state) => state.user);
  let location = useLocation();
  // here include the path name where we want to display only color in banner
  let isColor =
    location.pathname === "/" ||
    location.pathname === "/settings" ||
    location.pathname === "/all/users" ||
    location.pathname === "/all/campaigns" ||
    location.pathname === "/create/campaign" ||
    location.pathname === "/create/user";
  // change the banner background based on the path location
  useEffect(() => {
    if (isColor) {
      dispatch(
        changeBannerBg({
          color: true,
          bgUrl: "",
        })
      );
    } else {
      dispatch(
        changeBannerBg({
          color: false,
          bgUrl: Icons.bannerImage,
        })
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdTokenResult();
        console.log("APP JS USER FROM FIREBASE==>", user);
        if (await checkPhoneNumber(user?.uid)) {
          dispatch(getAsyncUser(idToken.token));
        } else {
          dispatch(noUser());
          message.error("Server Error.");
        }
      } else {
        dispatch(noUser());
        navigate("/login");
        console.log(user);
      }
    });
    return () => unsubscribe();
  }, [dispatch]);
  // if user is present than redirect user from login page to home page
  useEffect(() => {
    if (location.pathname === "/login") {
      navigate("/", { replace: true });
    }
  }, [userRed]);
  useEffect(() => {
    if (userRed?.email !== "") {
      if (userRed?.role !== "brand") {
        navigate("/unautorize", { replace: true });
      }
    }
  }, [userRed, location.pathname]);

  return (
    <ViewportProvider>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<HomeScreen user={userRed} />} />
          <Route path="/settings" element={<SettingScreen />} />
          {/* users */}
          <Route path="/edit/:userId/user" element={<EditUserProfile />} />
          <Route path="/user/:userId" element={<UserProfile />} />
          <Route path="/all/users" element={<AllUsers />} />
          <Route path="/create/user" element={<CreateUser />} />
          <Route path="/chats" element={<BrandChat />} />
          {/* influencer */}
          <Route path="/influencer/all/info" element={<AllInfluencer />} />
          {/* brand */}
          {/* campaigns */}
          <Route path="/all/campaigns" element={<AllCampaigns />} />
          <Route
            path="/all/unverified/campaigns"
            element={<UnverifiedCampaign />}
          />
          {/* list of all the campaign with the revenue info */}
          <Route
            path="/campaign/all/info"
            element={<CampaignWiseRevenue />}
          />{" "}
          {/* list of all the brand with the revenue info */}
          <Route path="/brand/all/info" element={<BrandWiseRevenue />} />{" "}
          <Route path="/create/campaign" element={<CreateCampaign />} />
          <Route path="/edit/:campaignId/campaign" element={<EditCampaign />} />
          <Route path="/campaign/:campaignId" element={<SingleCampaign />} />
          <Route
            path="/brand/campaign/insta/data/:id"
            element={<InstaData />}
          />
          {/* preview */}
          <Route path="/preview/campaign" element={<PreviewCampaign />} />
          {/* Reels */}
          <Route path="/create/reels" element={<CreateReels />} />
          <Route path="/all/reels" element={<AllReels />} />
          {/* Story */}
          <Route path="/create/story" element={<CreateStory />} />
          <Route path="/all/story" element={<AllStory />} />
          <Route path="/unautorize" element={<UnAuthorized />} />
        </Route>
        {/* auth */}
        <Route path="/login" element={<Login />} />
        {/* not avaialbale */}
        <Route path="*" element={<PageNotFound />} />{" "}
      </Routes>
    </ViewportProvider>
  );
};

export default App;
