import { Divider } from "@mui/material";
import { Col, Empty, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserChatList } from "../../../config/DummyData";
import MessageAreaNav from "../Nav/MessageAreaNav";
import Search from "../Search";
import SingleUserInfoCard from "../SingleUserInfoCard";

import { MessageAreaWrapper } from "./Style";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";

const MessageArea = () => {
  const { user, campaignChatInfo } = useSelector((state) => ({ ...state }));
  //campaignChatInfo: this holds the id and name of the campaign whose chat brand wants to see
  let userToPreview = 1;
  const [searchQuery, setSearchQuery] = useState("");
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);

  // firebase chat list call

  const q = query(
    collection(db, "userChatList"),
    where("campaignId", "==", campaignChatInfo?.id),
    where("userId", "==", user?._id),
    orderBy("timeStamps", "desc"),
    limit(10)
  );
  useEffect(() => {
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setLists(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
      setLoading(false);
    });

    return () => unsubscribe();
  }, [campaignChatInfo?.id]);

  // filter list of user based on the id of the currentUser and return the list to which this user had any conversation
  const ChatList = UserChatList.filter((item) => item.id === userToPreview)[0]
    .connection;
  // filter user based on the search query typed
  const filteredItems = lists.filter((item) => {
    return item.receiverName.toLowerCase().includes(searchQuery.toLowerCase());
  });
  // if search query has any input then run filtered array else other array
  let ArrayToRender = searchQuery === "" ? lists : filteredItems;
  // handle search
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <MessageAreaWrapper>
      {/* {JSON.stringify(lists, 2, 4)} */}
      {/* nav bar stating who is the user currently logged in (mostly admin or brand) */}
      <MessageAreaNav user={user} />
      <Search searchQuery={searchQuery} handleSearch={handleSearch} />
      <Divider className="dividerLine" />
      {/* main container where different users will be there */}
      <div className="chatUserBox">
        {ArrayToRender?.length > 0 ? (
          ArrayToRender?.map((item, i) => {
            return <SingleUserInfoCard item={item} key={i} i={i} />;
          })
        ) : (
          <div style={{ textAlign: "center" }} className="mt2">
            <Empty />
          </div>
        )}
      </div>
    </MessageAreaWrapper>
  );
};

export default MessageArea;
