import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AllUsersCard from "../../../components/cards/UserProfile/AllUsers/AllUsersCard";
import AllUserTable from "../../../components/table/User/AllUserTable";
import { Icons } from "../../../config/IconsList";
import { getMonitorDataAsync } from "../../../redux/monitorDataSlice";
import DesktopLayout from "../../../theme/DesktopLayout";
import { AllUserWrapper } from "./AllUserStyle";

const AllUsers = () => {
  const user = useSelector((state) => state.user);
  const monitorData = useSelector((state) => state.monitorData);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.token) {
      getMonitorData();
    }
  }, [user]);

  const getMonitorData = () => {
    dispatch(getMonitorDataAsync(user?.token));
  };

  return (
    <DesktopLayout>
      <AllUserWrapper>
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <AllUsersCard
              title="Total Users"
              amount={monitorData?.totalUsersCount}
              percentType="gain"
              percent="12"
              Icon={Icons.GroupIcon}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <AllUsersCard
              title="Active Users"
              amount={monitorData?.activeUsers}
              percentType="loss"
              percent="5"
              Icon={Icons.ProfileIcon}
            />{" "}
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <AllUsersCard
              title="Verified Users"
              amount={monitorData?.verifiedUsers}
              percentType="gain"
              percent="18"
              Icon={Icons.DoneIcon}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <AllUsersCard
              title="UnVerified Users"
              amount={monitorData?.unVerifiedUsers}
              percentType="gain"
              percent="10"
              Icon={Icons.ClearIcon}
            />
          </Col>
        </Row>
        {/* Admin filter */}
        <AllUserTable />
      </AllUserWrapper>
    </DesktopLayout>
  );
};

export default AllUsers;
