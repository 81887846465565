import styled from "styled-components";

export const UnverifiedWrapper = styled.div`
  min-height: 100vh;
  flex-wrap: wrap;
  z-index: 1;
  .bannerImage {
    height: 100%;
  }
`;
