import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

export const StepOneWrapper = styled.div`
  width: 100%;
  height: auto;
  label {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: ${Colors.textBlack};
    line-height: 2rem;
    margin-left: 3px;
  }
  .ant-input {
    color: ${Colors.textBlack} !important;
    font-weight: 300 !important;
    font-family: ${FontType.openSans}, sans-serif !important;
  }
  .inputContainer {
    margin-bottom: 1rem;
    /* background-color: red; */
    flex: 1;
  }
  .alertMessage {
    color: ${Colors.alertRed} !important;
    margin-left: 0.2rem;
    font-size: 12px;
  }
  .dateContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 48%;
  }
  .dateSeparator {
    margin-bottom: -1.8rem;
  }
  .ant-picker .ant-picker-input span.ant-picker-suffix {
    margin-left: 1px;
    margin-right: 10px;
    order: -1;
  }
  .ant-picker-input > input {
    color: ${Colors.textBlack} !important;
    font-weight: 400 !important;
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 13px;
  }

  .calenderIcon {
    width: 14px;
    height: 14px;
  }
  .platformIcon {
    width: 14px;
    height: 14px;
  }
  .instructionContainer {
    flex: 1;
    min-height: 14rem;
    max-height: 14rem;
    overflow: scroll;
    border: 1px solid ${Colors.greyD9};
    border-radius: ${BorderRadius.smallRadius};
  }
  .instructionContainer ul {
    padding-left: 1.5rem;
  }
  .instructionContainer ul li {
    color: ${Colors.textBlack} !important;
    font-weight: 300;
    font-family: ${FontType.openSans}, sans-serif;
    margin-top: 0.5rem;
  }

  .prevButton {
    color: ${Colors.lightPurple} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border: 2px solid ${Colors.lightPurple};
    border-radius: ${BorderRadius.smallRadius};
    opacity: 0.5;
  }
  .prevButton:hover {
    border: 2px solid ${Colors.lightPurple};
  }
  .nextButton {
    background-color: ${Colors.lightPurple} !important;
    color: ${Colors.white} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    border: 2px solid ${Colors.lightPurple};
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: ${BorderRadius.smallRadius};
  }
`;
