import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { Icons } from "../../../../config/IconsList";
import { BorderRadius, FontType } from "../../../../config/Values";

const ViewAllCampaignByUser = () => {
  return (
    <MyWrapper className="flexCenter">
      <Icons.ViewAllIcon style={{ fontSize: "1.5rem" }} />
      View all
    </MyWrapper>
  );
};

export default ViewAllCampaignByUser;

const MyWrapper = styled(Button)`
  flex: 1;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius} !important;
  border: 1px solid ${Colors.cardText} !important;
  flex-direction: column;
  font-size: 2rem !important;
  color: ${Colors.cardText} !important;
  font-family: ${FontType.openSans}, sans-serif !important;
  font-weight: 300 !important;
  cursor: pointer;
  text-transform: initial !important;
`;
