export const LocalStorageIdentifier = {
  stepCompleted: "stepCompleted",
  stepZeroData: "stepZeroData",
  stepOneData: "stepOneData",
  stepTwoData: "stepTwoData",
  stepThreeData: "stepThreeData",
  stepFourData: "stepFourData",
  createCampaignData: "createCampaignData",
  isCreateCampaignComplete: "isCreateCampaignComplete",
  isEditCampaignComplete: "isEditCampaignComplete",
  editCampaignData: "editCampaignData",
  editStepCompleted: "editStepCompleted",
  editStepZeroData: "editStepZeroData",
  editStepOneData: "editStepOneData",
  editStepTwoData: "editStepTwoData",
  editStepThreeData: "editStepThreeData",
  editStepFourData: "editStepFourData",
  campaignCredit: "campaignCredit",
};
