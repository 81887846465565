import { Divider, IconButton, Typography } from "@mui/material";
import { Col } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Colors } from "../../../config/Colors";
import { Chats, LargeText } from "../../../config/DummyData";
import { Icons } from "../../../config/IconsList";
import MessageText from "../messageText";
import MessageAreaNav from "../Nav/MessageAreaNav";

import { ChatRoomWrapper } from "./Style";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import moment from "moment";

const ChatRoom = () => {
  const chatUser = useSelector((state) => state.chatUser);
  const user = useSelector((state) => state.user);
  const [divHeight, setDivHeight] = useState("");
  const [messages, setMessages] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const divRef = useRef(null);
  const [text, setText] = useState("");

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      const divHeight = div.offsetHeight;
      console.log(`The height of the div is ${divHeight}px.`);
      setDivHeight(`${divHeight}px`);
    }
  }, [divRef]);

  const q = query(
    collection(db, "messages"),
    where("connectionId", "==", chatUser?.uid),
    orderBy("timeStamps", "desc"),
    limit(20)
  );
  useEffect(() => {
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(newMessages);
      setLastVisible(snapshot.docs[snapshot.docs?.length - 1]);
    });
    setLoading(false);

    return () => unsubscribe();
  }, [chatUser.uid]);

  // structuring data to send to firebase for chat message
  let data = {
    connectionId: chatUser?.uid,
    docModel: "User",
    imageUrl: user?.profilePicture?.url,
    senderId: user?._id,
    text: text,
    room: chatUser?.room,
    timeStamps: moment().format(),
    uploader: { ...chatUser?.uploader, isRequired: false },
    urlLinks: [],
  };
  const sendMessage = async () => {
    if (text.replace(/\s/g, "")?.length > 0) {
      await addDoc(collection(db, "messages"), data);

      await updateDoc(doc(db, "userChatList", chatUser?.id), {
        lastMessage: text,
        timeStamps: moment().format(),
      });

      setText("");
    }
  };
  return (
    <ChatRoomWrapper>
      {/* nav */}

      <MessageAreaNav user={chatUser} isRoom={true} />

      {/* area where chat is displayed */}

      <div className="chatArea">
        {messages?.toReversed().map((message, i) => {
          return (
            <MessageText currentUserId={user?._id} message={message} key={i} />
          );
        })}
      </div>

      {/* type message here */}

      <div className="typeMessageBox flexBetween">
        <input
          className="inputMessage"
          placeholder="Reply..."
          onChange={(e) => setText(e.target.value)}
          value={text}
          onSubmit={sendMessage}
        />
        <IconButton className="sendIcon" onClick={sendMessage}>
          <Icons.RightArrowIcon
            style={{ color: Colors.white, fontWeight: "300", fontSize: 20 }}
          />
        </IconButton>
      </div>
    </ChatRoomWrapper>
  );
};

export default ChatRoom;
