import { Divider, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import useViewport from "../../../viewport/useViewport";

const DosDontsList = ({ campaignDetail }) => {
  const { isTablet } = useViewport();
  return (
    <ListWrapper isTablet={isTablet}>
      {/* do's are written here */}
      <div className="parent c1">
        <Typography className="heading font">Do's</Typography>
        <ul className="unorderedList">
          {campaignDetail?.dos?.map((item, i) => {
            return (
              <li key={i} className="mtp5">
                <Typography className="listItemText">{item}</Typography>
              </li>
            );
          })}
        </ul>
      </div>
      {/* divider */}
      {!isTablet && <Divider orientation="vertical" flexItem />}
      {/* don'ts are written here */}
      <div className="parent c2">
        <Typography className="heading font">Don'ts</Typography>
        <ul className="unorderedList">
          {campaignDetail?.donts?.map((item, i) => {
            return (
              <li key={i} className="mtp5">
                <Typography className="listItemText">{item}</Typography>
              </li>
            );
          })}
        </ul>
      </div>
    </ListWrapper>
  );
};

export default DosDontsList;

const ListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: blue; */
  flex-direction: ${(prop) => prop.isTablet && "column"};
  .parent {
    max-width: ${(prop) => (prop.isTablet ? "95%" : "50%")};
  }
  .c1 {
    padding-right: ${(prop) => (prop.isTablet ? "0" : "1.5rem")};
  }
  .c2 {
    padding-left: ${(prop) => (prop.isTablet ? "0" : "1.5rem")};
  }
  .heading {
    font-size: 1.2rem;
    font-weight: 700;
    color: ${Colors.textBlack};
    /* background-color: red; */
  }
  .unorderedList {
    margin-left: 1.2rem;
  }
  .listItemText {
    font-size: 0.9rem;
    font-weight: 300;
    color: ${Colors.textBlack};
  }
`;
