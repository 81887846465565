import React from "react";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
import { FontType } from "../../config/Values";
const NormalLabel = ({ title, identifier }) => {
  return (
    <LabelWrapper>
      <label htmlFor={identifier}> {title} </label>
    </LabelWrapper>
  );
};

export default NormalLabel;

const LabelWrapper = styled.div`
  label {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: ${Colors.textBlack};
    line-height: 2rem;
    margin-left: 3px;
  }
`; 
 