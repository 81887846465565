import { Typography } from "@mui/material";
import { Avatar } from "antd";
import React from "react";
import { AboutText } from "../../../../config/DummyData";
import { ImageList } from "../../../../config/ImageList";
import { PreviewWrapper } from "./Style";

const PreviewProfile = ({ values }) => {
  // helper jsx

  const BasicInfo = (imageUrl, text) => {
    return (
      <div className="info_container">
        <img src={imageUrl} alt="info" className="info_icon" />
        <Typography className="info_text fontMont"> {text} </Typography>
      </div>
    );
  };

  return (
    <PreviewWrapper>
      {/* {JSON.stringify(values, null, 4)} */}
      {/* cover photo */}
      <img src={ImageList.Cover_Photo} alt="cover" className="cover_photo" />
      {/* profile picture */}
      <div className="flexCenter" style={{ width: "100%" }}>
        <Avatar
          size={85}
          src={values?.profilePicture?.url}
          className="profile_picture"
        />
      </div>
      {/* user name */}
      <Typography className="user_name fontMont">
        {values?.firstName} {values?.lastName}
        {values?.firstName === "" && values?.lastName === "" && "John Doe"}
      </Typography>
      {/* basic info : location, age and barter */}
      <div
        className="flexEvenly mt1"
        style={{ width: "100%", padding: "0 1.2rem" }}
      >
        {BasicInfo(
          ImageList.Location_On_Profile,
          values?.city === "" ? "Location" : values?.city
        )}
        {values?.role === "brand" ? (
          <>
            {" "}
            {BasicInfo(
              ImageList.Age_On_Profile,
              "Linkedin " + values?.linkedIn
            )}
            {BasicInfo(ImageList.Barter_On_Profile, "Instagram")}
          </>
        ) : (
          <>
            {" "}
            {BasicInfo(ImageList.Age_On_Profile, "Age " + values?.age)}
            {BasicInfo(
              ImageList.Barter_On_Profile,
              values?.barterAvailability ? "Barter: Yes" : "Barter: No"
            )}
          </>
        )}
      </div>
      <div className="container_box mt1">
        {/* about */}
        <Typography className="about fontPoppins">
          {values?.role === "brand" ? AboutText?.brand : AboutText?.influencer}
        </Typography>
        {/* categories */}
        <div className="info_container mt1">
          <img
            src={ImageList.Categories_On_Profile}
            alt="info"
            className="info_icon"
          />
          <div
            style={{ flex: 1, flexWrap: "wrap", minHeight: "2rem" }}
            className="info_container"
          >
            {values?.categories?.map((item, i) => {
              return (
                <Typography
                  className="info_text fontMont"
                  key={i}
                  textAlign="center"
                  marginLeft="2px"
                >
                  {" "}
                  {item?.categoryName}
                  {i < values?.categories?.length - 1 ? ", " : ""}
                </Typography>
              );
            })}
            {values?.categories?.length < 1 && (
              <Typography
                className="info_text fontMont"
                textAlign="center"
                marginLeft="2px"
              >
                {" "}
                No category selected
              </Typography>
            )}
          </div>
        </div>
      </div>
    </PreviewWrapper>
  );
};

export default PreviewProfile;
