import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius } from "../../../config/Values";

export const CreateCampaignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(prop) => (prop.isTablet ? "2rem 1.2rem" : "2rem 4rem")};
  .formContainer {
    width: ${(prop) => (prop.isTablet ? "100%" : "70%")} !important;
    min-height: 12rem;
    max-height: fit-content;
    background-color: ${Colors.white};
    border-radius: ${BorderRadius.mediumRadius};
    padding: 1rem 1rem 1rem 1rem;
  }
  .headingContainer {
    display: flex;
    align-items: center;
  }
  .loudspeakerIcon {
    width: 1.5rem !important;
    /* height: 1rem !important; */
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    margin-right: 0.5rem;
  }
`;
