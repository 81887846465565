import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius } from "../../../../config/Values";

export const RoleBasedWrapper = styled.div`
  width: 100%;
  min-height: 25rem;
  flex: 1;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1.5rem;
`;
