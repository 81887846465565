import styled from "styled-components";
import { Colors } from "../../../config/Colors";

export const ProgressTrackWrapper = styled.div`
  height: 25em;
  background-color: ${Colors.white};
  border-radius: 1rem;
  padding: 1.5rem;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
