import { Row } from "antd";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius } from "../../../config/Values";

export const MyMessageWrapper = styled.div`
  height: 90vh;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  overflow: hidden;
`;

export const MessageAreaWrapper = styled.div`
  flex: 1 !important;
  overflow-y: scroll !important;
  border-right: 1px solid ${Colors.lightGrey};
  ::-webkit-scrollbar {
    display: none;
  }
  .chatUserBox {
    height: 100% !important;
  }
  .dividerLine {
    background-color: ${Colors.lightGrey} !important;
  }
`;
