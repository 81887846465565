import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

const TABLE_PADDING_VALUE = "1rem";

export const CampaignTableWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: ${Colors.white};
  border-radius: ${(prop) =>
    prop.all
      ? `${BorderRadius.mediumRadius} ${BorderRadius.mediumRadius} 0 0`
      : BorderRadius.mediumRadius};
  display: flex;
  overflow-x: ${(prop) => prop.isTablet && "scroll"};
  ::-webkit-scrollbar {
    display: none;
  }
  .tableParent {
    width: 100%;
    border-radius: ${BorderRadius.mediumRadius};
    border-collapse: collapse;
    margin: 1rem 1.5rem;
  }
  .tableHeader {
    padding: ${TABLE_PADDING_VALUE} !important;
    font-size: 0.67rem;
    color: ${Colors.cardText};
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
  }
  .divider {
    width: 100%;
    height: 2px;
    background-color: red;
  }
  tr {
    border-bottom: 1px solid ${Colors.lightGrey};
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  tr:first-child {
    border-top: none;
  }
  tr:last-child {
    border-bottom: none;
  }
  tr:nth-last-child(2) {
    border-bottom: none;
  }
  td {
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  .ant-progress-bg {
    height: 3px !important;
  }
  .statusContainer {
    display: flex;
    align-items: center;
    color: ${Colors.textGrey};
    font-family: ${FontType.openSans}, sans-serif;
  }
  .brand_logo {
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 4rem;
    object-fit: cover;
    object-position: center;
    margin-right: 0.9rem;
  }
  .campaignName {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 500;
    color: ${Colors.textBlack};
  }
  .statusText {
    height: 7px;
    width: 7px;
    border-radius: 12px;
  }
  .brandName {
    font-size: 0.6rem;
    color: ${Colors.cardText};
    text-transform: lowercase;
    font-family: ${FontType.openSans}, sans-serif !important;
    text-decoration: none;
    text-transform: capitalize;
  }
`;
