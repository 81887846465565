import { Button } from "@mui/material";
import React from "react";
import { Colors } from "../../config/Colors";
import { Icons } from "../../config/IconsList";

const ViewAllButton = ({ url }) => {
  return (
    <Button
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        "&:hover": { backgroundColor: "transparent" },
        color: Colors.textGrey,
        textTransform: "capitalize",
      }}
      endIcon={<Icons.ViewAllIcon style={{ fontSize: 14 }} />}
    >
      View All
    </Button>
  );
};

export default ViewAllButton;
