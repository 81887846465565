import React, { useEffect, useState } from "react";
import { InstaWrapper } from "./Style";
import axios from "axios";

const UserInstaDetail = ({ thisUser }) => {
  const [userInstaData, setUserInstaData] = useState({});

  const callGraphAPI = async () => {
    const { data } = await axios.get(
      `https://www.instagram.com/api/v1/users/web_profile_info/?username=${thisUser?.testUsername}`,
      {
        headers: {
          "User-Agent":
            "Instagram 76.0.0.15.395 Android (24/7.0; 640dpi; 1440x2560; samsung; SM-G930F; herolte; samsungexynos8890; en_US; 138226743)",
        },
      }
    );
    console.log(data);
  };
  useEffect(() => {
    callGraphAPI();
  }, [thisUser]);

  return (
    <InstaWrapper>
      <h1>{thisUser?.testUsername} </h1>
      {JSON.stringify(userInstaData, 2, 4)}
    </InstaWrapper>
  );
};

export default UserInstaDetail;
