import styled from "styled-components";
import { Colors } from "../../config/Colors";

export const TopFixedNavWrapper = styled.div`
  margin-bottom: 1em;
  .fixed_top_nav_container {
    width: 100%;
    height: 5em;
    background: transparent !important;
    padding: ${(prop) => (prop.isMobile ? "0 0.1em" : "0 1em")};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .location_info_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .folderName {
    color: ${Colors.white50};
    font-weight: 300;
    font-size: 12px;
    text-align: center;
  }
  .slash {
    color: ${Colors.white};
    font-weight: 300;
  }
  .pageTitle {
    color: ${Colors.white};
    font-weight: 300;
    font-size: 13px;
  }
  .hamburger_open {
    color: ${Colors.white};
    font-weight: 300;
    margin-left: ${(prop) => (prop.isMobile ? "1em" : "1.8em")};
    cursor: pointer;
  }
  .pageHeaderTitle {
    font-weight: 600;
    font-size: 16px;
    color: ${Colors.white};
    text-align: left;
    margin-top: 0.4em;
    text-transform: capitalize;
  }
  .user_noti_box {
    flex: 1;
    padding-right: ${(prop) => (prop.isMobile ? "1em" : "2.5em")};
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .user_avatar {
    margin-right: ${(prop) => (prop.isMobile ? "1em" : "1em")};
  }
`;
