import { createSlice } from "@reduxjs/toolkit";

export const campaignChatInfoSlice = createSlice({
  name: "campaignChat",
  initialState: {
    campaignName: "",
    id: "",
  },
  reducers: {
    setCampaignChatInfo: (state, action) => {
      state.id = action.payload.id;
      state.campaignName = action.payload.campaignName;
    },
  },
});

export const { setCampaignChatInfo } = campaignChatInfoSlice.actions;
export default campaignChatInfoSlice.reducer;
