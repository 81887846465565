import { Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SnackbarSeverity } from "../../config/Values";
import { snackbarAlert } from "../../redux/snackbarSlice";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
import CardHeading from "../headings/CardHeading";

const ReshootModal = ({ isOpen, handleCancel }) => {
  const dispatch = useDispatch();
  const [inputNotes, setInputNotes] = useState("");

  const handleOk = () => {
    dispatch(
      snackbarAlert({
        message: "Influencer has been asked to reshoot.",
        open: true,
        messageType: SnackbarSeverity.success,
      })
    );
    handleCancel();
  };
  return (
    <ModalWrapper
      open={isOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Ask To Reshoot">
      <CardHeading title="Notes (state reason)" />
      <textarea
        rows="20"
        cols="10"
        className="mt2 textArea"
        onChange={(e) => setInputNotes(e.target.value)}
        value={inputNotes}
      />
    </ModalWrapper>
  );
};

export default ReshootModal;

const ModalWrapper = styled(Modal)`
  border: 2px solid ${Colors.textBlack};
  border-radius: 10px;
  padding: 0;
  .textArea {
    width: 100%;
  }
`;
