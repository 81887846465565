import styled from "styled-components";
import { Colors } from "../../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../../config/Values";

const TABLE_PADDING_VALUE = "1rem";

export const AppliedWrapper = styled.div`
  max-height: 70vh;
  padding: 1.3rem;
  flex: 1;
  overflow: scroll;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  overflow-x: ${(prop) => prop.isTablet && "scroll"};
  ::-webkit-scrollbar {
    display: none;
  }

  .tableParent {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
  }
  .tableHeader {
    font-size: 0.67rem;
    color: ${Colors.cardText};
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 1rem !important;
  }

  .nameAvatarCol {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 1;
  }
  .tableData {
    display: flex;
    align-items: center;
    color: ${Colors.textGrey};
    font-family: ${FontType.openSans}, sans-serif !important;
    text-decoration: none;
  }
  .influencerText {
    margin-left: 0.7rem;
  }
  .name {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${Colors.textBlack};
    text-align: left;
    text-transform: capitalize !important;
  }
  .email {
    font-size: 0.6rem;
    color: ${Colors.cardText};
    text-transform: lowercase;
    font-family: ${FontType.openSans}, sans-serif !important;
    text-align: left;
  }

  .tableAction {
    display: flex;
    justify-content: flex-end;
  }
  .appliedCol {
    font-size: 0.6rem;
  }
  .tagIcon {
    width: 14px;
    height: 14px;
  }

  tr {
    border-bottom: 1px solid ${Colors.lightGrey};
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  tr:first-child {
    border-top: none;
    margin-bottom: 1rem;
  }
  tr:last-child {
    border-bottom: none;
  }
  tr:nth-last-child(2) {
    border-bottom: none;
  }
  td {
    padding: ${TABLE_PADDING_VALUE} 0 !important ;
  }
  .centerAlignText {
    text-align: right;
    font-size: 0.7rem;
    color: ${Colors.tableText};
  }
`;
