import React from "react";
import styled from "styled-components";

const FadingDivider = () => {
  return (
    <DividerWrapper>
      <div className="divider div-transparent"></div>
    </DividerWrapper>
  );
};

export default FadingDivider;

const DividerWrapper = styled.div`
  /* background-color: red; */
  margin-bottom: 1em;
  .divider {
    position: relative;
    height: 1px;
    margin: 0.3rem 0;
  }

  .div-transparent:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.1),
      transparent
    );
  }
`;
