import { Button, InputLabel, Typography } from "@mui/material";
import { Dropdown, Input } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardHeading from "../../../components/headings/CardHeading";
import AllCampaignTable from "../../../components/table/campaign/AllCampaignScreen";
import { CategoryList } from "../../../config/DummyData";
import { Icons } from "../../../config/IconsList";
import { changeSearchParameters } from "../../../redux/searchTypeSlice";
import DesktopLayout from "../../../theme/DesktopLayout";
import useViewport from "../../../viewport/useViewport";
import { AllCampaignWrapper } from "./AllCampaignStyle";

const AllCampaigns = () => {
  const { istTablet, isMobile } = useViewport();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchBy, setSearchBy] = useState("brand");
  const [searchText, setSearchText] = useState("");
  // handleChangeSearchType
  const handleChangeSearchType = ({ key }) => {
    let mySearchType = CategoryList?.find((item) => item.key === key);
    setSearchBy(mySearchType.searchType);
  };

  const handleSearch = () => {
    if (searchText !== "") {
      dispatch(
        changeSearchParameters({
          searchType: searchBy,
          searchValue: searchText,
        })
      );
    }
  };

  return (
    <DesktopLayout>
      <AllCampaignWrapper istTablet={istTablet} isMobile={isMobile}>
        <div className="headerContainer flexBetween">
          {/* page heading */}
          <div>
            {" "}
            <CardHeading title="All Campaigns" />
            <Typography className="subHeading">
              This page contains all the campaigns launched on the App
            </Typography>
          </div>
          {/* Add new campaign - button */}
          <Button
            className="addButton"
            onClick={() => navigate("/create/campaign", { replace: true })}
          >
            + New campaign
          </Button>
        </div>
        {/* filters and search */}
        <div className="filterContainer">
          <div className="flexBetween searchContainer">
            <Input
              placeholder={`Search user by ${searchBy}`}
              allowClear
              className="searchInputBox"
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleSearch}
              prefix={<Icons.SearchIcon onClick={handleSearch} />}
            />
          </div>
          <div className="searchByContainer">
            <InputLabel style={{ fontSize: 8 }}>Search Type</InputLabel>
            <Dropdown
              menu={{ items: CategoryList, onClick: handleChangeSearchType }}
              placement="bottom"
            >
              <span
                style={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontSize: 12,
                }}
              >
                {" "}
                {searchBy}{" "}
              </span>
            </Dropdown>
          </div>
        </div>
        {/* table */}
        <AllCampaignTable />
      </AllCampaignWrapper>
    </DesktopLayout>
  );
};

export default AllCampaigns;
