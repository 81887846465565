import { Button, Typography } from "@mui/material";
import { Col, message, Row, Skeleton } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ImageAndRoleCard from "../../../components/cards/CreateUser/ImageAndRole";
import PreviewProfile from "../../../components/cards/CreateUser/Preview";
import RoleBasedInfo from "../../../components/cards/CreateUser/RoleBasedInfo";
import CreateUserInfoCard from "../../../components/cards/CreateUser/UserInfo";
import { editUserProfileAPI, singleUserInfoAPI } from "../../../functions/user";
import DesktopLayout from "../../../theme/DesktopLayout";
import useViewport from "../../../viewport/useViewport";
import { CreateUserWrapper } from "./EditProfileStyle";
const initialValues = {
  _id: "",
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  role: "influencer",
  DOB: "",
  categories: [],
  profilePicture: {
    reference: "default_profile_picture",
    url: "https://res.cloudinary.com/axbordev/image/upload/v1678789382/default_profile_image_bo61ub.webp",
    isDefault: true,
  },
  about: "",
  tagLine: "",
  headQuarters: "",
  linkedIn: "",
  gender: "",
  age: 14,
  state: "",
  city: "",
  availability: "",
  barterAvailability: true,
  budget: {
    min: 0,
    max: 10000,
  },
  websiteUrl: "",
  instagramProfile: "",
  instagramFollowing: 0,
  instagramFollowers: 0,
  instagramDP: "",
  isCelebrity: false,
};
const EditUserProfile = () => {
  const { isMobile, isTablet } = useViewport();
  const { userId } = useParams();
  const user = useSelector((state) => state.user);
  const [userInfo, setUserInfo] = useState(initialValues);
  const [userProfileId, setUserProfileId] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: userInfo,
    // validationSchema: CreateUserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("CREATE NEW USER VALUES==>", values);
      editUserProfileAPI(values, userProfileId, userId, user?.token)
        .then((res) => {
          message.success("User Created Successfully!");
          console.log("USER CREATED", res.data);
          resetForm();
        })
        .catch((err) => {
          console.log(err);
          message.error("Some error occured while creating new user.");
        });
    },
  });
  useEffect(() => {
    if (user?.token !== "") {
      getUserInfo();
    }
  }, [user]);

  const getUserInfo = () => {
    setLoadingData(true);
    singleUserInfoAPI(userId, user?.token)
      .then((res) => {
        console.log("EDIT USER  DATA=>", res.data);
        const { _id, ...roleInfo } = res.data.user?.userProfile;
        const userData = res.data.user;
        const { userProfile, ...rest } = userData;
        setUserInfo({ ...rest, ...roleInfo });
        setLoadingData(false);
        setUserProfileId(res.data.user?.userProfile?._id);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
        setLoadingData(false);
      });
  };

  return (
    <DesktopLayout>
      <CreateUserWrapper
        className="mt1"
        isMobile={isMobile}
        isTablet={isTablet}
      >
        {/* heading and save button */}
        <div className="flexBetween">
          <div style={{ flex: 1 }}>
            <Typography className="font create_user_heading">
              Edit {userInfo?.firstName + "'s"} profile below
            </Typography>
            <Typography className="font create_user_subheading mtp4 mb2">
              Welcome to the user profile updation form!. The values appering in
              red need not be filled untill and unless changes are to be made.
            </Typography>
          </div>
          <Button
            variant="outlined"
            className="save_button"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>

        {/* 1st row */}
        <Row gutter={[24, 24]}>
          <Col xs={24} md={8} lg={8} style={{ display: "flex" }}>
            <Skeleton loading={loadingData} active>
              <ImageAndRoleCard
                values={values}
                handleChange={handleChange}
                initialValues={initialValues}
              />
            </Skeleton>
          </Col>
          <Col xs={24} md={16} lg={16} style={{ display: "flex" }}>
            <Skeleton loading={loadingData} active>
              <CreateUserInfoCard
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </Skeleton>
          </Col>
        </Row>
        {/* 2nd row */}
        <Row gutter={[24, 24]} style={{ marginTop: "1.5rem" }}>
          <Col xs={24} md={16} lg={16} style={{ display: "flex" }}>
            <Skeleton loading={loadingData} active>
              <RoleBasedInfo
                edit={true}
                values={values}
                handleChange={handleChange}
                initialValues={initialValues}
                errors={errors}
                touched={touched}
              />
            </Skeleton>
          </Col>
          <Col xs={24} md={8} lg={8} style={{ display: "flex" }}>
            <Skeleton loading={loadingData} active>
              <PreviewProfile
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </Skeleton>
          </Col>
        </Row>
        {/* {JSON.stringify(values, null, 4)} */}
      </CreateUserWrapper>
    </DesktopLayout>
  );
};

export default EditUserProfile;
