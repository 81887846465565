import { Button, Typography } from "@mui/material";
import { Avatar } from "antd";
import React from "react";
import { Colors } from "../../../../config/Colors";
import { UserList } from "../../../../config/DummyData";
import { Icons } from "../../../../config/IconsList";
import useViewport from "../../../../viewport/useViewport";
import SmallHeading from "../../../headings/SmallHeading";
import { UserCampaignWrapper } from "./Style";

const UserCampaign = ({ campaign, count }) => {
  const user = UserList;
  const { isTablet } = useViewport();
  let imageUrl =
    "https://demos.creative-tim.com/argon-dashboard-pro/assets/img/home-decor-1.jpg";
  return (
    <UserCampaignWrapper>
      <img
        src={campaign?.campaignBanner}
        alt="banner"
        className="campaignBanner"
      />
      <div className="flexBetween">
        <Typography className="campaignCount">Campaign #{count}</Typography>
        <Typography className="campaignCount flexCenter">
          <Icons.RupeeIcon style={{ fontSize: 12 }} />
          {campaign?.budget?.min}- {campaign?.budget?.max}
        </Typography>
      </div>
      <SmallHeading
        title={campaign?.brand?.name}
        color={Colors.textBlack}
        weight="600"
      />
      <Typography className="description">{campaign?.campaignName}</Typography>
      <div className="flexBetween mt2">
        <Button
          variant="outlined"
          className="viewButton"
          href={`/campaign/${campaign?._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Project
        </Button>
        <Avatar.Group
          maxCount={4}
          maxPopoverTrigger="none"
          maxStyle={{ overflow: "hidden", display: "none" }}
        >
          {UserList.map((user, i) => {
            return (
              <Avatar size={22} key={i} alt={user.name} src={user.imageUrl} />
            );
          })}
        </Avatar.Group>
      </div>
    </UserCampaignWrapper>
  );
};

export default UserCampaign;
