import React from "react";
import styled from "styled-components";
import { Shadow } from "../../config/Values";

const MyFooter = () => {
  return (
    <FooterWrapper>
      ©{new Date().getFullYear()} developed by{" "}
      <a
        href="https://biliweb.com"
        className="companyName"
        target="_blank"
        rel="noopener noreferrer">
        Bili
      </a>
    </FooterWrapper>
  );
};

export default MyFooter;

const FooterWrapper = styled.div`
  height: auto;
  background-color: transparent;
  box-shadow: ${Shadow.light};
  padding: 2rem 0rem;
  text-transform: none;
  .companyName {
    text-decoration: none;
  }
`;
