import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius } from "../../../../config/Values";

const TABLE_PADDING_VALUE = "1rem";

export const BrandWiseInfoWrapper = styled.div`
  height: auto;
  flex: 1;
  padding: 1.5rem;
  background-color: ${Colors.white};
  border-radius: ${(prop) =>
    prop.all
      ? `${BorderRadius.mediumRadius} ${BorderRadius.mediumRadius} 0 0`
      : BorderRadius.mediumRadius};
  overflow-x: ${(prop) => prop.isTablet && "scroll"};
  ::-webkit-scrollbar {
    display: none;
  }
  .singleBrandMainBox {
    width: 100%;
  }
  .tableParent {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
  }
  .tableHeader {
    padding: ${TABLE_PADDING_VALUE} !important;
    font-size: 0.67rem;
    color: ${Colors.cardText};
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
  .tableData {
    color: ${Colors.textBlack};
    text-align: center;
  }

  tr {
    border-bottom: 1px solid ${Colors.lightGrey};
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  tr:first-child {
    border-top: none;
    margin-bottom: 1rem;
  }
  tr:last-child {
    border-bottom: none;
  }
  tr:nth-last-child(2) {
    border-bottom: none;
  }
  td {
    padding: ${TABLE_PADDING_VALUE} !important;
  }
`;
