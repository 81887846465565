import { IconButton, Typography } from "@mui/material";
import { Avatar, Divider, Empty } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { Colors } from "../../../../config/Colors";
import { Icons } from "../../../../config/IconsList";
import SmallHeading from "../../../headings/SmallHeading";
import { MessagesWrapper } from "./Style";

const Messages = ({ user }) => {
  const [messages, setMessages] = useState("");
  const handleChangeMessage = (e) => {
    setMessages(e.target.value);
  };
  return (
    <MessagesWrapper>
      <SmallHeading
        title="Conversation"
        color={Colors.textBlack}
        weight="500"
      />
      <Divider style={{ marginTop: -10 }} />
      {/* message display box */}
      {/* <div className="messageBox">
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <img className="avatar" src={user?.imageUrl} alt={user?.name} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 8,
              marginTop: 4,
            }}>
            <Typography className="username"> {user?.firstName} </Typography>
            <div className="userBox">
              If two equally difficult paths, Hi, I’m Alec Thompson, Decisions:
            </div>
            <Typography className="time">
              {" "}
              {moment().startOf("hour").fromNow()}{" "}
            </Typography>
          </div>
        </div>
        <div className="adminBox">
          Music is something that every person has his or her own specific
          opinion about
        </div>
      </div> */}
      <div className="messageBox">
        <Empty />
      </div>

      <Divider style={{ marginBottom: 12 }} />
      {/* type message here */}
      <div className="typeMessageBox flexBetween">
        <input type="text" className="inputMessage" placeholder="Reply..." />
        <IconButton className="sendIcon">
          <Icons.RightArrowIcon
            style={{ color: Colors.white, fontWeight: "300", fontSize: 20 }}
          />
        </IconButton>
      </div>
    </MessagesWrapper>
  );
};

export default Messages;
