import React, { useEffect } from "react";
import CountInfoCard from "../../components/cards/countInfo/CountInfoCard";
import DesktopLayout from "../../theme/DesktopLayout";
import { ChartWrapper, HomeWrapper, TableWrapper } from "./HomeStyles";

import SalesLineChart from "../../components/charts/SalesLineChart";
import { Col, Row } from "antd";
import { Colors } from "../../config/Colors";
import SmallHeading from "../../components/headings/SmallHeading";
import BlogSynopCarousel from "../../components/carousel/BlogSynopCarousel";
import TeamMembersCard from "../../components/cards/TeamMembers";
import TodoListCard from "../../components/cards/ToDo/TodoListCard";
import ProgressTrack from "../../components/cards/ProgressTrack";
import RecentCampaignCard from "../../components/cards/RecentCampaign";
import CampaignWiseInfo from "../../components/table/campaign/HomeScreen";
import SingleCampaignMoneyInfo from "../../components/cards/SingleCampaignMoney";
import BrandWiseInfo from "../../components/table/Brand/HomeScreen";
import InfluencerInfo from "../../components/table/Influencer/HomeScreen";
import HomeScreenCategoryCard from "../../components/cards/Categories";
import { Icons } from "../../config/IconsList";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMonitorDataAsync } from "../../redux/monitorDataSlice";

const HomeScreen = ({ user }) => {
  // const { isMobile, isTablet } = useViewport();
  const monitorData = useSelector((state) => state.monitorData);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.token) {
      getMonitorData();
    }
  }, [user]);

  const getMonitorData = () => {
    dispatch(getMonitorDataAsync(user?.token));
  };
  return (
    <DesktopLayout>
      <HomeWrapper className="flexBetween">
        <CountInfoCard
          title="Total Impressions"
          Icon={Icons.VisibilityIcon}
          amount="5231"
          margin="gain"
          marginValue="55"
          compareTo="yesterday"
        />
        <CountInfoCard
          title="Total Campaigns"
          Icon={Icons.CampaignIcon}
          amount={monitorData?.totalUsersCount}
          margin="loss"
          marginValue="23"
          compareTo="last week"
        />
        <CountInfoCard
          title="Campaign Cost"
          Icon={Icons.RupeeIcon}
          amount={monitorData?.newClients}
          margin="loss"
          marginValue="18"
          compareTo="last month"
        />
        <CountInfoCard
          title="Wallet Amount"
          Icon={Icons.RupeeIcon}
          amount="₹ 12,98450"
          margin="gain"
          marginValue="34"
          compareTo="last quarter"
        />
      </HomeWrapper>
      {/* ================================================================================= */}
      {/* graph div and blog synopsis */}
      <Row
        style={{
          marginTop: 21,
          padding: "0 !important",
        }}
        gutter={24}
        justify="space-between"
      >
        {/* graph chart */}
        <Col xs={24} md={24} lg={14} style={{}}>
          <ChartWrapper>
            <SmallHeading title="Total Sales" color={Colors.black70} />
            <SalesLineChart />
          </ChartWrapper>
        </Col>
        {/* blog synopsis */}
        <Col xs={0} md={0} lg={10}>
          <BlogSynopCarousel />
        </Col>
      </Row>
      {/* ================================================================================= */}
      {/* team members & to-do list & progress-track */}
      <Row gutter={[24, 16]} className="mt2">
        {/* team members */}
        <Col xs={24} md={12} lg={8}>
          {/* <TeamMembersCard /> */}
        </Col>
        {/* todo list */}
        <Col xs={24} md={12} lg={8}>
          {/* <TodoListCard /> */}
        </Col>
        {/* progress track */}
        <Col xs={24} md={24} lg={8}>
          {/* <ProgressTrack /> */}
        </Col>
      </Row>
      {/* ================================================================================= */}
      {/* recent campaign && brand wise && campaign wise && due amount  */}
      <TableWrapper gutter={[24, 16]} className="mt3 mb3">
        <Col xs={24} md={24} lg={10}>
          <div className="recentCampaignContainer">
            <RecentCampaignCard user={user} />
          </div>
        </Col>
        {/* second column */}
        <Col
          xs={24}
          md={24}
          lg={14}
          style={{
            height: "fit-content",
          }}
        >
          {/* 2nd column 1st row */}
          <CampaignWiseInfo all={false} />
          <TodoListCard />
        </Col>
      </TableWrapper>
      {/* ================================================================================= */}
    </DesktopLayout>
  );
};

export default HomeScreen;
