import React from "react";
import BrandWiseInfo from "../../../components/table/Brand/HomeScreen/BrandWiseInfo";
import DesktopLayout from "../../../theme/DesktopLayout";

const BrandWiseRevenue = () => {
  return (
    <DesktopLayout>
      <BrandWiseInfo all={true} />
    </DesktopLayout>
  );
};

export default BrandWiseRevenue;
