import { Typography } from "@mui/material";
import { Tooltip } from "antd";
import React from "react";
import useViewport from "../../../viewport/useViewport";
import { CountInfoCardWrapper } from "./CountInfoStyles";

const CountInfoCard = ({
  title,
  Icon,
  amount,
  margin,
  marginValue,
  compareTo,
  toolTipMessage,
}) => {
  const { isTablet, isMobile } = useViewport();

  return (
    <CountInfoCardWrapper isTablet={isTablet} isMobile={isMobile}>
      <div className="contentContainer flexBetween">
        <div className="cardInfoContainer">
          <Tooltip title={toolTipMessage}>
            <Typography variant="body1" className="cardTitle">
              {title}
            </Typography>
          </Tooltip>
          <Typography variant="h5" className="cardAmount">
            {amount}
          </Typography>
        </div>
        <div className="cardIconContainer flexCenter">
          <Icon className="cardIcon" />
        </div>
      </div>
      {/* gain or loss info */}

      <div className="gainLossContainer">
        <Typography variant="body2" className="gainLossText">
          {margin === "loss" ? (
            <span className="lossText"> -{marginValue}% </span>
          ) : (
            <span className="gainText"> +{marginValue}% </span>
          )}
          than {compareTo}{" "}
        </Typography>
      </div>
    </CountInfoCardWrapper>
  );
};

export default CountInfoCard;
