import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../config/Colors";
import { TopFixedNavWrapper } from "./TopNavStyles";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { setSideNavExpanded } from "../../redux/sideNavExpansionSlice";
import { Badge } from "@mui/material";
import useViewport from "../../viewport/useViewport";
import { Avatar, Tooltip } from "antd";
import { Icons } from "../../config/IconsList";

const TopFixedNav = () => {
  const dispatch = useDispatch();
  const { isMobile } = useViewport();
  const user = useSelector((state) => state.user);
  //  this gives the info about the current location of website page in terms of title and foldername
  const currentLocation = useSelector((state) => state.currentLocation);
  // this state wether the sidenav is expanded or not
  const sideNavStatus = useSelector((state) => state.sideNavStatus);
  // this function toggle the expansion state of side nav
  const toggleNavExpansion = () => {
    dispatch(
      setSideNavExpanded({
        isSideNavExpanded: !sideNavStatus.isSideNavExpanded,
        motionType: "clicked",
      })
    );
  };
  // jsx return
  return (
    <TopFixedNavWrapper isMobile={isMobile}>
      <div className="fixed_top_nav_container">
        <div className="location_info_box">
          <div
            className="location_info_box"
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <div>
              <Icons.WindowIcon
                style={{ color: Colors.white, fontSize: 16, marginBottom: -2 }}
              />{" "}
              <span className="slash">/ </span>
              <span className="folderName">
                {currentLocation.folderName}
              </span>{" "}
              <span className="slash"> / </span>
              <span className="pageTitle">{currentLocation.title}</span>
            </div>

            {/* page title */}
            <div className="pageHeaderTitle">{currentLocation.title}</div>
          </div>
          <div onClick={toggleNavExpansion}>
            {sideNavStatus.isSideNavExpanded ? (
              <MenuOpenOutlinedIcon className="hamburger_open" />
            ) : (
              <MenuIcon className="hamburger_open" />
            )}
          </div>
        </div>

        {/* user and notification */}
        <div className="user_noti_box">
          <Tooltip title={user?.name}>
            <Avatar src={user?.profilePicture?.url} className="user_avatar" />
          </Tooltip>
          <Badge badgeContent={4} color="error">
            <NotificationsIcon
              style={{ color: Colors.white, fontWeight: 400 }}
            />
          </Badge>
        </div>
      </div>
    </TopFixedNavWrapper>
  );
};

export default TopFixedNav;
