import { createSlice } from "@reduxjs/toolkit";

export const bannerSlice = createSlice({
  name: "bannerSlice",
  initialState: {
    color: true,
    bgUrl: "",
  },
  reducers: {
    changeBannerBg: (state, action) => {
      state.color = action.payload.color;
      state.bgUrl = action.payload.bgUrl;
    },
  },
});

export const { changeBannerBg } = bannerSlice.actions;
export default bannerSlice.reducer;
