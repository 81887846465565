import React from "react";
import InfluencerInfo from "../../components/table/Influencer/HomeScreen/InfluencerInfo";
import DesktopLayout from "../../theme/DesktopLayout";

const AllInfluencer = () => {
  return (
    <DesktopLayout>
      <InfluencerInfo all={true} />
    </DesktopLayout>
  );
};

export default AllInfluencer;
