import { Button, FormGroup, Typography } from "@mui/material";
import { Col, Input, message, Row, Segmented, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import { Colors } from "../../../config/Colors";
import CreateBrandExcelTable from "../../table/excelUpload/CreateBrandExcelTable";
import CreateUserExcelTable from "../../table/excelUpload/CreateUserExcelTable";

const CreateUserByExcel = ({ setSegmentRoleValue, segmentRoleValue }) => {
  // state
  const [loading, setLoading] = useState(false);
  const [excelRows, setExcelRows] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [rows, setRows] = useState([]);

  // =========================================================================
  // =========================functions=======================================
  // =========================================================================
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = (await axios.get("http://localhost:4000/api/v1/jokes"))
        .data;
      setRows(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        const categories = [
          "Photography",
          "Health & Fitness",
          "LifeStyle",
          "Technology",
          "Food & Beverage",
          "Fashion & Beauty",
        ];
        const restObj = {
          categories,
          budget: {
            min: 0,
            max: 10000,
          },
          barterAvailability: true,
          availability: "available",
        };
        const newArray = json.map((obj) => {
          delete obj["budget minimum"];
          delete obj["budget maximum"];
          return { ...obj, ...restObj };
        });
        setExcelRows(newArray);
        console.log("JSON VALUE readUploadFile", json);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  // remove files
  const removeFile = () => {
    setSelectedFile(null);
    setExcelRows([]);
    window.location.reload();
  };
  const handleExcelSubmit = () => {
    if (
      window.confirm(
        `Are you sure you want to create ${excelRows?.length} user?`
      )
    ) {
      console.log("Users created");
      message.success("Users created successfully!!");
    }
  };

  function renderDataTable() {
    return segmentRoleValue === "brand" ? (
      <CreateBrandExcelTable data={excelRows} />
    ) : (
      <CreateUserExcelTable data={excelRows} />
    );
  }

  return (
    <>
      {/* {JSON.stringify(excelRows, null, 4)} */}
      <div className="container">
        <Row>
          <Col style={{ display: "flex" }}>
            <Input
              style={{ textAlign: "center" }}
              id="inputEmpGroupFile"
              name="file"
              type="file"
              onChange={readUploadFile}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <Button
              variant="outlined"
              disabled={excelRows?.length < 1}
              style={{
                backgroundColor: Colors.black40,
                marginLeft: 12,
                minWidth: "15rem",
              }}
              className="save_button"
              onClick={handleExcelSubmit}
            >
              Create {excelRows?.length > 0 && excelRows?.length} Users
            </Button>
          </Col>
          <Col>
            <Segmented
              onChange={setSegmentRoleValue}
              options={[
                {
                  label: "Influencer",
                  value: "influencer",
                },
                {
                  label: "Brand",
                  value: "brand",
                },
              ]}
              style={{
                marginLeft: 10,
                borderRadius: 10,
                background: "transparent",
                minWidth: "4rem",
                width: "25rem",
                maxWidth: "28rem",
                alignSelf: "center",
              }}
              block={true}
            />
          </Col>
        </Row>
        {loading && <Spin />}
        <div className="mt2"> {renderDataTable()}</div>
      </div>
      <div></div>
    </>
  );
};

export default CreateUserByExcel;
