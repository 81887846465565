import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius, FontType } from "../../../config/Values";

export const SingleCampaignMoneyWrapper = styled.div`
  width: 100%;
  height: 18rem;
  max-height: 20rem;
  background: ${(prop) =>
    prop.isGradient
      ? `linear-gradient(142.9deg, #EB7251 7.73%, #E14A5E 95.99%)`
      : Colors.bsDark};
  border-radius: ${BorderRadius.mediumRadius};
  .mainBox {
    padding: 1.7rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .price {
    font-weight: 700;
    color: ${Colors.white};
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 2.5rem;
  }
  .title {
    font-weight: 500;
    color: ${Colors.white80};
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 0.9rem;
    margin-top: 0.4rem;
  }
  .percentPaid {
    color: ${Colors.white80};
    font-size: 1rem;
    margin-top: 0.4rem;
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 500;
  }
  .addCreditButton {
    text-transform: initial;
    background-color: ${Colors.white};
    color: ${Colors.black};
    border-radius: ${BorderRadius.smallRadius};
    font-weight: 600;
  }
  .addCreditButton:hover {
    background-color: ${Colors.white};
  }
  .progressHeading {
    font-size: 0.8rem;
    color: ${Colors.white};
    font-family: ${FontType.openSans}, sans-serif !important;
  }
  .ant-progress-bg {
    height: 3px !important;
  }
`;
