import styled from "styled-components";
import { Colors } from "../../config/Colors";
import { BorderRadius, PaddingValues } from "../../config/Values";

export const DeskNavWrapper = styled.div`
  width: 100%;
  height: 92.4%;
  border-radius: ${BorderRadius.mediumRadius};
  background-color: white;
  overflow-y: scroll;
  padding: ${PaddingValues.miniPadding};
  ::-webkit-scrollbar {
    display: none;
  }
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: ${(prop) => !prop.isSideNavExpanded && "center"};
    padding: ${PaddingValues.smallPadding};
  }
  .logo_image {
    max-width: 100%;
    max-height: 2rem;
  }
  .company_name {
    color: ${Colors.textGrey};
    margin: 0 0 0 0.7em;
    font-weight: 600;
    font-size: ${(prop) => (prop.isSideNavExpanded ? "16px" : "0px")};
    transition: all 0.5s ease-in-out;
  }
  .inactiveClassName {
    display: flex;
    align-items: center;
    justify-content: ${(prop) => !prop.isSideNavExpanded && "center"};
    padding: ${PaddingValues.smallPadding};
    text-decoration: none;
  }
  .activeClassName {
    display: flex;
    align-items: center;
    justify-content: ${(prop) => !prop.isSideNavExpanded && "center"};
    padding: ${PaddingValues.smallPadding};
    text-decoration: none;
    background-color: ${Colors.activeBg};
    border-radius: ${BorderRadius.smallRadius};
    color: ${Colors.black};
  }
  .iconStyle {
    font-size: 1.2em;
    color: ${Colors.lightPurple};
  }
  .dashboard_title {
    font-family: "Open Sans", sans-serif !important;
    margin-left: 0.8em;
    font-size: 0.9em;
    font-weight: 500;
    color: ${Colors.black70};
    font-size: ${(prop) => (prop.isSideNavExpanded ? "14px" : "0px")};
    transition: all 0.5s ease-in-out;
  }
`;
