import axios from "axios";

export const createCampaignAPI = async (values, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ADMIN}/create-campaign-dashboard`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const editCampaignAPI = async (campaignId, values, authtoken) => {
  console.log(authtoken);
  return await axios.patch(
    `${process.env.REACT_APP_API_ADMIN}/edit-campaign?campaignId=${campaignId}`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const deleteCampaignAPI = async (campaignId, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_ADMIN}/delete-campaign`,
    { campaignId },
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const approveCampaignAPI = async (values, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ADMIN}/approve-campaign`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getCampaignAPI = async (values, authtoken) => {
  // console.log(authtoken);
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/my-campaigns?page=${values?.page}&limit=${values?.limit}&brandId=${values?.brandId}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getCampaignByStatusAPI = async (values, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API_ADMIN}/get-campaigns-by-status`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getSingleCampaignDetailAPI = async (campaignId, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/campaign-info?campaignId=${campaignId}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getLatestCampaignAPI = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API_ADMIN}/latest-campaign`, {
    headers: {
      authorization: authtoken,
    },
  });
};

export const getProgressTrackAPI = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API_ADMIN}/progress-track`, {
    headers: {
      authorization: authtoken,
    },
  });
};

export const getRevenueByCampaignAPI = async (page, limit, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/revenue-by-campaign?page=${page}&limit=${limit}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getInfluencerDetailCampaignWiseAPI = async (values, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/get-campaign-influencers-dashboard?page=${values.page}&limit=${values.limit}&campaignId=${values.campaignId}&influencerType=${values.influencerType}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const shortlistInfluencerForCampaignAPI = async (values, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ADMIN}/approve-influencer`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const approveOrRejectMediaAPI = async (values, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ADMIN}/approve-or-reshoot-links`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};
