import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMonitorDataAPI } from "../functions/user";

const initialState = {
  totalUsersCount: "",
  activeUsers: "",
  verifiedUsers: "",
  unVerifiedUsers: "",
  newClients: "",
  token: "",
  isLoading: true,
};

// calling user data ayncronus way
export const getMonitorDataAsync = createAsyncThunk(
  "data/getMonitorData",
  async (authtoken, thunkAPI) => {
    try {
      const resp = await getMonitorDataAPI(authtoken);
      const info = resp.data;
      let data = {
        totalUsersCount: info.totalUsersCount,
        activeUsers: info.activeUsers,
        verifiedUsers: info.verifiedUsers,
        unVerifiedUsers: info.unVerifiedUsers,
        newClients: info.newClients,
        isLoading: false,
      };

      return data;
    } catch {
      console.log("ERROR IN CATCH");
      console.log("THUNKAPI=>", thunkAPI);
      return thunkAPI.rejectWithValue("something wait wrong");
    }
  }
);

const monitorData = createSlice({
  name: "data",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMonitorDataAsync.pending, (state) => {
        state.totalUsersCount = "";
        state.activeUsers = "";
        state.verifiedUsers = "";
        state.unVerifiedUsers = "";
        state.newClients = "";
        state.isLoading = true;
      })
      .addCase(getMonitorDataAsync.fulfilled, (state, { payload }) => {
        state.totalUsersCount = payload.totalUsersCount;
        state.activeUsers = payload.activeUsers;
        state.verifiedUsers = payload.verifiedUsers;
        state.unVerifiedUsers = payload.unVerifiedUsers;
        state.newClients = payload.newClients;
        state.isLoading = payload.isLoading;
      })
      .addCase(getMonitorDataAsync.rejected, (state) => {
        state.totalUsersCount = "";
        state.activeUsers = "";
        state.verifiedUsers = "";
        state.unVerifiedUsers = "";
        state.newClients = "";
        state.isLoading = false;
      });
  },
});

// export const { login, logoutUser, noUser } = monitorData.actions;

export default monitorData.reducer;
