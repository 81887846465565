import { Typography, Avatar, Button } from "@mui/material";
import { message, Pagination, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Colors } from "../../../../config/Colors";
import { UserList } from "../../../../config/DummyData";
import { Icons } from "../../../../config/IconsList";
import { getInfluencerListAPI } from "../../../../functions/influencer";
import useViewport from "../../../../viewport/useViewport";
import ViewAllButton from "../../../Button/ViewAllButton";
import { PaginationWrapper } from "../../campaign/AllCampaignScreen/Style";
import { InfluencerInfoWrapper } from "./Style";

const InfluencerInfo = ({ all }) => {
  // heer {all} signifies that it is a page dedicated to this particular table
  const { isTablet } = useViewport();
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);

  const [influencerList, setInfluencerList] = useState([]);

  useEffect(() => {
    if (user?.token !== "") {
      getInfluencerInfo();
    }
  }, [user, page, limit]);

  const getInfluencerInfo = () => {
    getInfluencerListAPI(page, limit, user?.token)
      .then((res) => {
        console.log("INFLUENCER LIST", res.data.data);
        setInfluencerList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };
  // page  size change: number of item to be displayed on a single page
  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setLimit(pageSize);
  };
  // change page number
  const handleChangePageNumber = (page, pageSize) => {
    setPage(page);
  };
  return (
    <>
      <InfluencerInfoWrapper isTablet={isTablet} all={all}>
        <table className="tableParent">
          {/* table header row */}
          <tr>
            <th className="tableHeader">Influencer</th>
            <th className="tableHeader centerAlignText">Connects</th>
            <th className="tableHeader centerAlignText">Status</th>
            <th className="tableHeader centerAlignText">Joined</th>
            <th className="tableHeader"></th>
          </tr>
          {/* table content row */}
          {influencerList?.slice(0, 5).map((influencer, i) => {
            return (
              <tr key={i}>
                <td>
                  <a
                    href={`/user/${influencer?._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tableData"
                  >
                    <Avatar
                      variant="rounded"
                      sx={{ width: 37, height: 37 }}
                      style={{ borderRadius: 10 }}
                      src={influencer?.profilePicture?.url}
                    />
                    <div className="influencerText">
                      <Typography className="name">
                        {" "}
                        {influencer.firstName + " " + influencer?.lastName}{" "}
                      </Typography>
                      <Typography className="email">
                        {influencer.email}
                      </Typography>
                    </div>
                  </a>
                </td>
                <td className="centerAlignText">
                  {influencer?.userProfile?.campaignsJoined?.length}
                </td>
                <td className="centerAlignText">
                  <Tag
                    style={{
                      color: influencer.isVerified
                        ? Colors.textGreen
                        : Colors.alertRed,
                      textTransform: "uppercase",
                      fontSize: 8,
                    }}
                    color={
                      influencer.isVerified
                        ? Colors.faintedGreen
                        : Colors.faintedPink
                    }
                  >
                    {influencer?.isVerified ? "Verified" : influencer.status}
                  </Tag>
                </td>
                <td className="centerAlignText">
                  {moment(influencer.joinedDate).format("DD/MM/YYYY")}
                </td>
                <td className="centerAlignText" style={{ cursor: "pointer" }}>
                  <Button
                    href={`/edit/${influencer?._id}/user`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                      color: Colors.tableText,
                      textTransform: "capitalize",
                      fontSize: "0.7rem",
                    }}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            );
          })}
          {/* view all button */}
          {!all && (
            <tr>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "absolute",
                  bottom: 0,
                }}
              >
                <ViewAllButton url="/influencer/all/info" />
              </td>
            </tr>
          )}
          {/* table ends here */}
        </table>
      </InfluencerInfoWrapper>
      {/* pagination */}
      {all && (
        <PaginationWrapper>
          <Pagination
            pageSizeOptions={[1, 4, 10, 50, 100]}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={1}
            total={500}
            onChange={handleChangePageNumber}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default InfluencerInfo;
