import { Typography } from "@mui/material";
import { Icons } from "./IconsList";
import { ImageList } from "./ImageList";

export const GraphData = [
  {
    month: "Jan",
    uv: 0,
    pv: 0,
    amt: 0,
  },
  {
    month: "Feb",
    uv: 600,
    pv: 1398,
    amt: 1000,
  },
  {
    month: "Mar",
    uv: 1200,
    pv: 900,
    amt: 2000,
  },
  {
    month: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    month: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    month: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    month: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    month: "Aug",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    month: "Sep",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    month: "Oct",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    month: "Nov",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    month: "Dec",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
];

export const BlogData = [
  {
    title: "Blogging As A Professional Career Option",
    imageUrl:
      "https://cdn.searchenginejournal.com/wp-content/uploads/2022/04/reverse-image-search-627b7e49986b0-sej-760x400.png",
  },
  {
    title: "How to leverage YouTube short videos for Influencer Marketing",
    imageUrl:
      "https://media.istockphoto.com/id/1013256572/vector/infinity.jpg?s=612x612&w=0&k=20&c=hwIacJ0GvUYxv4ZXVIh5PSMX90KOYEQjkyosTSh_F9c=",
  },
  {
    title: "5 Reasons To Consider Influencer Marketing For Your E-Commerce",
    imageUrl:
      "https://img.freepik.com/premium-photo/woman-with-glasses-virtual-reality-neon-lights-girl-vr-goggles-having-experience_179369-356.jpg",
  },
];

export const TeamMembers = [
  {
    name: "John Michael",
    imageUrl:
      "https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000",
    onlineStatus: "online",
    role: "admin",
  },

  {
    name: "Alex Smith",
    imageUrl:
      "https://t4.ftcdn.net/jpg/02/97/24/51/360_F_297245133_gBPfK0h10UM3y7vfoEiBC3ZXt559KZar.jpg",
    onlineStatus: "offline",
    role: "admin",
  },
  {
    name: "Samantha Ivy",
    imageUrl:
      "https://media.istockphoto.com/id/1339074391/photo/young-brunette-teenager-wearing-business-style-at-office-success-sign-doing-positive-gesture.jpg?b=1&s=170667a&w=0&k=20&c=1ZUYHPsofWAkRUZQZiHYYFACpWm-5qvm4RCLBvQ5uk8=",
    onlineStatus: "offline",
    role: "admin",
  },
  {
    name: "John Michael",
    imageUrl:
      "https://img.freepik.com/free-photo/portrait-successful-man-having-stubble-posing-with-broad-smile-keeping-arms-folded_171337-1267.jpg?w=2000",
    onlineStatus: "online",
    role: "editor",
  },
  {
    name: "John Michael",
    imageUrl:
      "https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000",
    onlineStatus: "online",
    role: "admin",
  },

  {
    name: "Alex Smith",
    imageUrl:
      "https://t4.ftcdn.net/jpg/02/97/24/51/360_F_297245133_gBPfK0h10UM3y7vfoEiBC3ZXt559KZar.jpg",
    onlineStatus: "offline",
    role: "admin",
  },
  {
    name: "Alex Smith",
    imageUrl:
      "https://t4.ftcdn.net/jpg/02/97/24/51/360_F_297245133_gBPfK0h10UM3y7vfoEiBC3ZXt559KZar.jpg",
    onlineStatus: "offline",
    role: "admin",
  },
  {
    name: "Samantha Ivy",
    imageUrl:
      "https://media.istockphoto.com/id/1339074391/photo/young-brunette-teenager-wearing-business-style-at-office-success-sign-doing-positive-gesture.jpg?b=1&s=170667a&w=0&k=20&c=1ZUYHPsofWAkRUZQZiHYYFACpWm-5qvm4RCLBvQ5uk8=",
    onlineStatus: "offline",
    role: "admin",
  },
  {
    name: "John Michael",
    imageUrl:
      "https://img.freepik.com/free-photo/portrait-successful-man-having-stubble-posing-with-broad-smile-keeping-arms-folded_171337-1267.jpg?w=2000",
    onlineStatus: "online",
    role: "editor",
  },
];

export const BrandCampaignTracker = [
  {
    brandName: "Spotify",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/991px-Spotify_icon.svg.png",
    totalNeeded: 300,
    shortlisted: 121,
  },

  {
    brandName: "Boat",
    imageUrl:
      "https://gumlet.assettype.com/swarajya%2F2021-01%2F7a6e91f6-5862-4ac5-bb08-eb5bf2a3e20c%2FboAt_logo.jpg?q=75&auto=format%2Ccompress&w=400&dpr=2.6",
    totalNeeded: 500,
    shortlisted: 348,
  },
  {
    brandName: "KDC",
    imageUrl:
      "https://static.vecteezy.com/system/resources/thumbnails/009/124/623/small_2x/kdc-logo-kdc-letter-kdc-letter-logo-design-initials-kdc-logo-linked-with-circle-and-uppercase-monogram-logo-kdc-typography-for-technology-business-and-real-estate-brand-vector.jpg",
    totalNeeded: 400,
    shortlisted: 0,
  },
  {
    brandName: "MamaEarth",
    imageUrl:
      "https://i.pinimg.com/736x/d7/08/7e/d7087e7c5909da949c3ad78b12fd4f98.jpg",
    totalNeeded: 230,
    shortlisted: 10,
  },
  {
    brandName: "Alphabet",
    imageUrl:
      "https://gumlet.assettype.com/swarajya%2F2021-01%2F7a6e91f6-5862-4ac5-bb08-eb5bf2a3e20c%2FboAt_logo.jpg?q=75&auto=format%2Ccompress&w=400&dpr=2.6",
    totalNeeded: 410,
    shortlisted: 88,
  },
];

export const UserList = [
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).`,
    _id: 1,
    name: "Michael Lewis",
    firstName: "Michael",
    lastName: "Lewis",
    imageUrl:
      "https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000",
    totalConnects: 68,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "michaellewis4u@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).`,
    _id: 2,
    name: "Jessica Stones",
    firstName: "Jessica",
    lastName: "Stones",
    imageUrl:
      "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg",
    totalConnects: 37,
    status: "unverified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "stonesJessica1789@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 3,
    name: "Arundhati Sharma",
    firstName: "Arundhati",
    lastName: "Sharma",
    imageUrl:
      "https://discoverymood.com/wp-content/uploads/2020/04/Mental-Strong-Women-min.jpg",
    totalConnects: 119,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "arundhati96sharma@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 4,
    name: "Siyal Saxena",
    firstName: "Siyal",
    lastName: "Saxena",
    imageUrl:
      "https://img.freepik.com/free-photo/handsome-good-looking-indian-man-sitting-chair-relaxing-isolated-white-wall_231208-2648.jpg?w=2000",
    totalConnects: 11,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 5,
    name: "Jessica Stones",
    firstName: "Jessica",
    lastName: "Stones",
    imageUrl:
      "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg",
    totalConnects: 37,
    status: "unverified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "theartisticstones@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 6,
    name: "Pragya Sharma",
    firstName: "Pragya",
    lastName: "Sharma",
    imageUrl:
      "https://discoverymood.com/wp-content/uploads/2020/04/Mental-Strong-Women-min.jpg",
    totalConnects: 19,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 7,
    name: "Lavyana James",
    firstName: "Lavyana",
    lastName: "James",
    imageUrl:
      "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg",
    totalConnects: 83,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 8,
    name: "Jessica Stones",
    firstName: "Jessica",
    lastName: "Stones",
    imageUrl:
      "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg",
    totalConnects: 91,
    status: "unverified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },

  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 9,
    name: "Arundhati Sharma",
    firstName: "Arundhati",
    lastName: "Sharma",
    imageUrl:
      "https://discoverymood.com/wp-content/uploads/2020/04/Mental-Strong-Women-min.jpg",
    totalConnects: 119,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 10,
    name: "Priya Yadav",
    firstName: "Priya",
    lastName: "Yadav",
    imageUrl:
      "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg",
    totalConnects: 65,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 11,
    name: "Mahima Gautam",
    firstName: "Mahima",
    lastName: "Gautam",
    imageUrl:
      "https://discoverymood.com/wp-content/uploads/2020/04/Mental-Strong-Women-min.jpg",
    totalConnects: 16,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 12,
    name: "Jessica Stones",
    imageUrl:
      "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg",
    totalConnects: 37,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 13,
    name: "Arundhati Sharma",
    firstName: "Arundhati",
    lastName: "Sharma",
    imageUrl:
      "https://discoverymood.com/wp-content/uploads/2020/04/Mental-Strong-Women-min.jpg",
    totalConnects: 119,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },
  {
    mobile: 8809456414,
    role: "influencer",
    location: "Mumbai",
    description: `Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).

`,
    _id: 14,
    name: "Jessica Stones",
    firstName: "Jessica",
    lastName: "Stones",
    imageUrl:
      "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg",
    totalConnects: 37,
    status: "verified",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    email: "alexapdyf18@gmail.com",
  },
];

export const HomePageBrandData = [
  {
    _id: "1",
    brandName: "Spotify",
    budget: 120000,
    status: "working",
    completion: 60,
    totalCampaigns: 1,
    revenue: 910000,
    success: 100,
    imageUrl:
      "https://1000logos.net/wp-content/uploads/2021/04/Spotify-logo.png",
  },
  {
    _id: "2",
    brandName: "Jira",
    budget: 580000,
    status: "completed",
    completion: 100,
    totalCampaigns: 11,
    revenue: 119000,
    success: 100,
    imageUrl:
      "https://cdn.icon-icons.com/icons2/2699/PNG/512/atlassian_jira_logo_icon_170511.png",
  },
  {
    _id: "3",
    brandName: "Slack",
    budget: 35000,
    status: "canceled",
    completion: 30,
    totalCampaigns: 8,
    revenue: 900000,
    success: 100,
    imageUrl: "https://cdn.mos.cms.futurecdn.net/SDDw7CnuoUGax6x9mTo7dd.jpg",
  },
  {
    _id: "4",
    brandName: "Boat",
    budget: 210000,
    status: "canceled",
    completion: 60,
    totalCampaigns: 21,
    revenue: 87000,
    success: 100,
    imageUrl:
      "https://gumlet.assettype.com/swarajya%2F2021-01%2F7a6e91f6-5862-4ac5-bb08-eb5bf2a3e20c%2FboAt_logo.jpg?q=75&auto=format%2Ccompress&w=400&dpr=2.6",
  },
  {
    _id: "5",
    brandName: "Mercedes-Benz",
    budget: 3400000,
    status: "completed",
    completion: 100,
    totalCampaigns: 5,
    revenue: 290000,
    success: 100,
    imageUrl:
      "https://1000logos.net/wp-content/uploads/2018/04/Mercedes-Benz-Logo.png",
  },
  {
    _id: "6",
    brandName: "MamaEarth",
    budget: 97000,
    status: "working",
    completion: 45,
    totalCampaigns: 1,
    revenue: 77000,
    success: 100,
    imageUrl:
      "https://i2.wp.com/www.indiaretailing.com/wp-content/uploads/2021/05/mamaearth-logo.png?fit=1024%2C602&ssl=1",
  },
  {
    _id: "2",
    brandName: "Jira",
    budget: 580000,
    status: "completed",
    completion: 100,
    totalCampaigns: 11,
    revenue: 87000,
    success: 100,
    imageUrl:
      "https://cdn.icon-icons.com/icons2/2699/PNG/512/atlassian_jira_logo_icon_170511.png",
  },
  {
    _id: "3",
    brandName: "Slack",
    budget: 35000,
    status: "canceled",
    completion: 30,
    totalCampaigns: 11,
    revenue: 87000,
    success: 100,
    imageUrl: "https://cdn.mos.cms.futurecdn.net/SDDw7CnuoUGax6x9mTo7dd.jpg",
  },
  {
    _id: "4",
    brandName: "Boat",
    budget: 210000,
    status: "canceled",
    completion: 60,
    totalCampaigns: 11,
    revenue: 87000,
    success: 100,
    imageUrl:
      "https://gumlet.assettype.com/swarajya%2F2021-01%2F7a6e91f6-5862-4ac5-bb08-eb5bf2a3e20c%2FboAt_logo.jpg?q=75&auto=format%2Ccompress&w=400&dpr=2.6",
  },
];

export const CategoriesList = [
  {
    _id: 1,
    categoryName: "LifeStyle",
    totalCampaigns: 120,
    imageUrl:
      "https://res.cloudinary.com/axborapp/image/upload/v1671775202/Greentech_1_llloor.png",
    currentRunning: 25,
  },
  {
    _id: 2,
    categoryName: "Technology",
    totalCampaigns: 74,
    imageUrl:
      "https://res.cloudinary.com/axborapp/image/upload/v1671775126/Devices_amayhp.png",
    currentRunning: 12,
  },
  {
    _id: 3,
    categoryName: "Photography",
    totalCampaigns: 49,
    imageUrl:
      "https://res.cloudinary.com/axborapp/image/upload/v1671775132/Man_taking_picture_of_a_dog_rtwftb.png",
    currentRunning: 18,
  },
  {
    _id: 4,
    categoryName: "Food & Beverage",
    totalCampaigns: 67,
    imageUrl:
      "https://res.cloudinary.com/axborapp/image/upload/v1671775136/Chef_with_food_is_going_to_cook_r7jmby.png",
    currentRunning: 22,
  },
  {
    _id: 5,
    categoryName: "Health & Fitness",
    totalCampaigns: 97,
    imageUrl:
      "https://res.cloudinary.com/axborapp/image/upload/v1671775140/Man_with_a_smartwatch_is_running_vzvpkr.png",
    currentRunning: 9,
  },
  {
    _id: 6,
    categoryName: "Fashion & Beauty",
    totalCampaigns: 113,
    imageUrl:
      "https://res.cloudinary.com/axborapp/image/upload/v1671775175/Woman_leads_a_beauty_vlog_cjzquo.png",
    currentRunning: 73,
  },
];

export const MessageData = [
  {
    sender: 1,
    receiver: "alpha1",
    createdAt: "2021-06-23T07:59:52.708+00:00",
    text: "Hello kaise hai aaplog!!",
  },
  {
    sender: "alpha1",
    receiver: 1,
    createdAt: "2021-06-23T07:59:52.708+00:00",
    text: "I am fine bro, tell me about yourself!!",
  },
];

export const Chats = [
  {
    uid: 12,
    messages: [
      {
        text: "Hi jesicca its 12",
        senderId: 1,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl:
          "https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000",
      },
      {
        text: "Hello bro!!",
        senderId: 2,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[1].imageUrl,
      },
      {
        text: "Kaisa hai, bata jeevan me or kya chal rah ahai bhai. kisis k saath to kuch accha ho yaar",
        senderId: 1,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[0].imageUrl,
      },
      {
        text: "Hmmm",
        senderId: 2,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[1].imageUrl,
      },
    ],
  },
  {
    uid: 13,
    messages: [
      {
        text: "Hi this is 13",
        senderId: 3,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[2].imageUrl,
      },
      {
        text: "Hello bro!!",
        senderId: 1,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[0].imageUrl,
      },
      {
        text: "Kaisa hai, bata jeevan me or kya chal rah ahai bhai. kisis k saath to kuch accha ho yaar",
        senderId: 3,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[2].imageUrl,
      },
    ],
  },
  {
    uid: 14,
    messages: [
      {
        text: "Hi this is 14 bro",
        senderId: 4,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[3].imageUrl,
      },
      {
        text: "Hello bro!!",
        senderId: 1,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[0].imageUrl,
      },
      {
        text: "Kaisa hai, bata jeevan me or kya chal rah ahai bhai. kisis k saath to kuch accha ho yaar",
        senderId: 1,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[0].imageUrl,
      },
      {
        text: "yes bro",
        senderId: 4,
        createdAt: "2021-06-23T07:59:52.708+00:00",
        imageUrl: UserList[3].imageUrl,
      },
    ],
  },
];

export const UserChatList = [
  {
    id: 1,
    connection: [
      {
        uid: 12,
        otherUserName: UserList[1].name,
        lastMessage: "Hmmm",
        firstName: UserList[1].firstName,
        lastName: UserList[1].lastName,
        senderName: UserList[1].name,
        imageUrl: UserList[1].imageUrl,
        senderId: UserList[1]._id,
        createdAt: "2021-06-23T07:59:52.708+00:00",
      },
      {
        uid: 13,
        otherUserName: UserList[2].name,
        lastMessage:
          "Kaisa hai, bata jeevan me or kya chal rah ahai bhai. kisis k saath to kuch accha ho yaar",
        senderName: UserList[2].name,
        firstName: UserList[2].firstName,
        lastName: UserList[2].lastName,
        imageUrl: UserList[2].imageUrl,
        senderId: UserList[2]._id,
        createdAt: "2021-06-23T07:59:52.708+00:00",
      },
      {
        uid: 14,
        otherUserName: UserList[3].name,
        lastMessage: "yes bro",
        senderName: UserList[3].name,
        firstName: UserList[3].firstName,
        lastName: UserList[3].lastName,
        senderId: UserList[3]._id,
        imageUrl: UserList[3].imageUrl,
        createdAt: "2021-06-23T07:59:52.708+00:00",
      },
    ],
  },
  {
    id: 2,
    connection: [
      {
        uid: 12,
        otherUserName: UserList[0].name,
        lastMessage: "Hmmm",
        senderId: UserList[1]._id,
        senderName: UserList[1].name,
        imageUrl: UserList[1].imageUrl,
        createdAt: "2021-06-23T07:59:52.708+00:00",
      },
    ],
  },
  {
    id: 3,
    connection: [],
  },
  {
    id: 4,
    connection: [],
  },
  {
    id: 5,
    connection: [],
  },
];
export const LargeText = `        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum. Why do we use it? It is a long established fact that a
        reader will be distracted by the readable content of a page when looking
        at its layout. The point of using Lorem Ipsum is that it has a
        more-or-less normal distribution of letters, as opposed to using
        'Content here, content here', making it look like readable English. Many
        desktop publishing packages and web page editors now use Lorem Ipsum as
        their default model text, and a search for 'lorem ipsum' will uncover
        many web sites still in their infancy. Various versions have evolved
        over the years, sometimes by accident, sometimes on purpose (injected
        humour and the like). Where does it come from? Contrary to popular
        belief, Lorem Ipsum is not simply random text. It has roots in a piece
        of classical Latin literature from 45 BC, making it over 2000 years old.
        Richard McClintock, a Latin professor at Hampden-Sydney College in
        Virginia, looked up one of the more obscure Latin words, consectetur,
        from a Lorem Ipsum passage, and going through the cites of the word in
        classical literature, discovered the undoubtable source. Lorem Ipsum
        comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
        Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
        This book is a treatise on the theory of ethics, very popular during the
        Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
        amet..", comes from a line in section 1.10.32. The standard chunk of
        Lorem Ipsum used since the 1500s is reproduced below for those
        interested. Sections 1.10.3Lorem Ipsum is simply dummy text of the
        printing and typesetting industry. Lorem Ipsum has been the industry's
        standard dummy text ever since the 1500s, when an unknown printer took a
        galley of type and scrambled it to make a type specimen book. It has
        survived not only five centuries, but also the leap into electronic
        typesetting, remaining essentially unchanged. It was popularised in the
        1960s with the release of Letraset sheets containing Lorem Ipsum
        passages, and more recently with desktop publishing software like Aldus
        PageMaker including versions of Lorem Ipsum. Why do we use it? It is a
        long established fact that a reader will be distracted by the readable
        content of a page when looking at its layout. The point of using Lorem
        Ipsum is that it has a more-or-less normal distribution of letters, as
        opposed to using 'Content here, content here', making it look like
        readable English. Many desktop publishing packages and web page editors
        now use Lorem Ipsum as their default model text, and a search for 'lorem
        ipsum' will uncover many web sites still in their infancy. Various
        versions have evolved over the years, sometimes by accident, sometimes
        on purpose (injected humour and the like). Where does it come from?
        Contrary to popular belief, Lorem Ipsum is not simply random text. It
        has roots in a piece of classical Latin literature from 45 BC, making it
        over 2000 years old. Richard McClintock, a Latin professor at
        Hampden-Sydney College in Virginia, looked up one of the more obscure
        Latin words, consectetur, from a Lorem Ipsum passage, and going through
        the cites of the word in classical literature, discovered the
        undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
        of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
        Cicero, written in 45 BC. This book is a treatise on the theory of
        ethics, very popular during the Renaissance. The first line of Lorem
        Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section
        1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is
        reproduced below for those interested. Sections 1.10.32 and 1.10.33 from
        "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their
        exact original form, accompanied by English versions from the 1914
        translation by H. Rackham.Lorem Ipsum is simply dummy text of the
        printing and typesetting industry. Lorem Ipsum has been the industry's
        standard dummy text ever since the 1500s, when an unknown printer took a
        galley of type and scrambled it to make a type specimen book. It has
        survived not only five centuries, but also the leap into electronic
        typesetting, remaining essentially unchanged. It was popularised in the
        1960s with the release of Letraset sheets containing Lorem Ipsum
        passages, and more recently with desktop publishing software like Aldus
        PageMaker including versions of Lorem Ipsum. Why do we use it? It is a
        long established fact that a reader will be distracted by the readable
        content of a page when looking at its layout. The point of using Lorem
        Ipsum is that it has a more-or-less normal distribution of letters, as
        opposed to using 'Content here, content here', making it look like
        readable English. Many desktop publishing packages and web page editors
        now use Lorem Ipsum as their default model text, and a search for 'lorem
        ipsum' will uncover many web sites still in their infancy. Various
        versions have evolved over the years, sometimes by accident, sometimes
        on purpose (injected humour and the like). Where does it come from?
        Contrary to popular belief, Lorem Ipsum is not simply random text. It
        has roots in a piece of classical Latin literature from 45 BC, making it
        over 2000 years old. Richard McClintock, a Latin professor at
        Hampden-Sydney College in Virginia, looked up one of the more obscure
        Latin words, consectetur, from a Lorem Ipsum passage, and going through
        the cites of the word in classical literature, discovered the
        undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
        of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
        Cicero, written in 45 BC. This book is a treatise on the theory of
        ethics, very popular during the Renaissance. The first line of Lorem
        Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section
        1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is
        reproduced below for those interested. Sections 1.10.32 and 1.10.33 from
        "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their
        exact original form, accompanied by English versions from the 1914
        translation by H. Rackham.2 and 1.10.33 from "de Finibus Bonorum et
        Malorum" by Cicero are also reproduced in their exact original form,
        accompanied by English versions from the 1914 translation by H. Rackham.`;

export const DeliverableCampaignType = [
  {
    _id: 1,
    name: "instaReel",
    label: "Instagram Reel",
    imageUrl: ImageList.InstagramImageIcon,
  },
  {
    _id: 2,
    name: "instagramPost",
    label: "Instagram Post",
    imageUrl: ImageList.InstagramImageIcon,
  },
  {
    _id: 3,
    name: "facebookPost",
    label: "Facebook Post",
    imageUrl: ImageList.FacebookImageIcon,
  },
  {
    _id: 4,
    name: "youtubeShorts",
    label: "YouTube Shorts",
    imageUrl: ImageList.YouTubeImageIcon,
  },
  {
    _id: 5,
    name: "youTubeVideo",
    label: "YouTube Video",
    imageUrl: ImageList.YouTubeImageIcon,
  },
  {
    _id: 6,
    name: "instagramStory",
    label: "Instagram Story",
    imageUrl: ImageList.InstagramImageIcon,
  },
];
export const CampaignsList = [
  {
    _id: 1,
    barter: "no",
    campaignBanner:
      "https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    campaignTitle: "Explore your skincare from the power of OORJA",
    campaignBrand: "Nike",
    campaignBrandLogo:
      "https://www.everysize.com/mag/wp-content/uploads/nike-logo-swoosh-symbol.jpg",
    campaignCategory: [CategoriesList[0], CategoriesList[1], CategoriesList[4]],
    campaignTotalCapacity: 420,
    shortlistedInfluencerCount: 361,
    campaignDescription:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    campaignPrice: 78000,
    shortlistedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[1],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[3],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[4],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
    ],
    visitorCount: 1200,
    isCanceled: false,
    dos: [
      "The product shall be the main focus",
      "Use complimenting colours",
      "Follow the guidelines",
      "Submit the campaign content on time",
      "The image should be visually appealing",
      "Mention the caption in the chat which you will be using while posting the image",
      "Make sure the content is high quality, well lit and not heavily edited",
    ],
    donts: [
      "Any sort of nuidity and profanity shall not be entertained",
      "Do not post without approval",
      "Do not share product images available publicly on the internet",
    ],
    appliedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[0],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[1],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[2],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[3],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[4],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[10],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[11],
      },
    ],
    submitedAssignment: [
      {
        submittedBy: UserList[3],
        submittedById: UserList[3]._id,
        submittedOn: "2021-06-23T07:59:52.708+00:00",
        link: "https://youtu.be/TQVGCAU-wRo",
        status: "submitted",
      },
      {
        submittedBy: UserList[7],
        submittedById: UserList[7]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/TGLxjppFqeA",
        status: "reshoot",
      },
      {
        submittedBy: UserList[9],
        submittedById: UserList[9]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/__ozZfxNZwA",
        status: "approved",
      },
    ],
    uploadedCampaigns: [
      {
        submittedBy: UserList[3],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[4],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[5],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[6],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[8],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[9],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
    ],
    campaignPlatforms: [
      {
        platformName: "instagram",
        platformLogo: Icons.InstagramIcon,
      },
      {
        platformName: "youtube",
        platformLogo: Icons.YouTubeIcon,
      },
      {
        platformName: "facebook",
        platformLogo: Icons.FacebookIcon,
      },
    ],
    age: {
      min: 18,
      max: 30,
    },
    gender: "Male & Female",
    followers: {
      min: 10000,
      max: 25000,
    },
    followersFB: {
      min: 20000,
      max: 50000,
    },
    subscribers: {
      min: 100000,
    },
    campaignPeriod: {
      startDate: "2022-05-12T07:59:52.708+00:00",
      endDate: "2022-06-12T07:59:52.708+00",
    },
    campaignPaymentType: ["cash"], //in real this will be object id of paymentType
    campaignStatus: "active",
    deliverableType: [
      DeliverableCampaignType[0],
      DeliverableCampaignType[1],
      DeliverableCampaignType[2],
      DeliverableCampaignType[3],
      DeliverableCampaignType[4],
    ],
    productReference: [
      {
        _id: 1,
        productName: "Hangover nimbu soda flame",
        imageUrl:
          "https://images.unsplash.com/photo-1606107557195-0e29a4b5b4aa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80",
        price: 1200,
      },
      {
        _id: 2,
        productName: "Nike just do it",
        imageUrl:
          "https://images.unsplash.com/photo-1491553895911-0055eca6402d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        price: 4000,
      },
    ],
    referenceImages: [
      {
        _id: 1,
        imageUrl:
          "https://images.unsplash.com/photo-1605408499391-6368c628ef42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      },
      {
        _id: 2,
        imageUrl:
          "https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1325&q=80",
      },
    ],
    referenceVideo: [
      {
        _id: 1,
        videoUrl:
          "https://res.cloudinary.com/dxcyg98s1/video/upload/v1672916577/azgorrlywlfmhrslccra.mp4",
      },
    ],
    pricePerInfluencer: {
      min: 10000,
      max: 20000,
    },
  },
  {
    _id: 2,
    barter: "no",
    campaignBanner:
      "https://static.magicpin.com/storage/blog/images/mamaearth%20cover.PNG",
    campaignTitle: "Explore your skincare with Mamaearth",
    campaignBrand: "mamaearth",
    campaignBrandLogo:
      "https://i2.wp.com/www.indiaretailing.com/wp-content/uploads/2021/05/mamaearth-logo.png?fit=1024%2C602&ssl=1",
    campaignCategory: [
      CategoriesList[2],
      CategoriesList[3],
      CategoriesList[4],
      CategoriesList[1],
      CategoriesList[5],
    ],
    campaignTotalCapacity: 420,
    shortlistedInfluencerCount: 101,
    campaignDescription:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    campaignPrice: 94000,
    shortlistedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[2],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[10],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[0],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
    ],
    visitorCount: 1900,
    isCanceled: true,
    dos: [
      "The product shall be the main focus",
      "Use complimenting colours",
      "Follow the guidelines",
      "Submit the campaign content on time",
      "The image should be visually appealing",
      "Mention the caption in the chat which you will be using while posting the image",
      "Make sure the content is high quality, well lit and not heavily edited",
    ],
    donts: [
      "Any sort of nuidity and profanity shall not be entertained",
      "Do not post without approval",
      "Do not share product images available publicly on the internet",
    ],
    appliedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[0],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[1],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[2],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[3],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[4],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[10],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[11],
      },
    ],
    submitedAssignment: [
      {
        submittedBy: UserList[3],
        submittedById: UserList[3]._id,
        submittedOn: "2021-06-23T07:59:52.708+00:00",
        link: "https://youtu.be/TQVGCAU-wRo",
        status: "submitted",
      },
      {
        submittedBy: UserList[7],
        submittedById: UserList[7]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/TGLxjppFqeA",
        status: "reshoot",
      },
      {
        submittedBy: UserList[9],
        submittedById: UserList[9]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/__ozZfxNZwA",
        status: "approved",
      },
    ],
    uploadedCampaigns: [
      {
        submittedBy: UserList[3],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[4],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[5],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[6],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[8],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[9],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
    ],
    campaignPlatforms: [
      {
        platformName: "instagram",
        platformLogo: Icons.InstagramIcon,
      },
      {
        platformName: "youtube",
        platformLogo: Icons.YouTubeIcon,
      },
      {
        platformName: "facebook",
        platformLogo: Icons.FacebookIcon,
      },
    ],
    age: {
      min: 18,
      max: 30,
    },
    gender: "Male & Female",
    followers: {
      min: 10000,
      max: 25000,
    },
    followersFB: {
      min: 20000,
      max: 50000,
    },
    subscribers: {
      min: 100000,
    },
    campaignPeriod: {
      startDate: "2022-05-12T07:59:52.708+00:00",
      endDate: "2022-06-12T07:59:52.708+00",
    },
    campaignPaymentType: ["cash"], //in real this will be object id of paymentType
    campaignStatus: "active",
    deliverableType: [
      DeliverableCampaignType[0],
      DeliverableCampaignType[1],
      DeliverableCampaignType[2],
      DeliverableCampaignType[3],
      DeliverableCampaignType[4],
    ],
    productReference: [
      {
        _id: 1,
        productName: "Hangover nimbu soda flame",
        imageUrl: "https://images.freekaamaal.com/post_images/1605876623.png",
        price: 1200,
      },
      {
        _id: 2,
        productName: "Nike just do it",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2hMBPqWRwK_QyWv4EBjN6o9tzRBp8UljrJW4VPkLHA9r3KK4aeNp06_0jL854EAX6jRY&usqp=CAU",
        price: 4000,
      },
    ],
    referenceImages: [
      {
        _id: 1,
        imageUrl:
          "https://mamaearthp.imgix.net/wysiwyg/Womens-Day-Sale-Banner_Mobile-without-CTA-830x360.jpg?auto=format",
      },
      {
        _id: 2,
        imageUrl:
          "https://mamaearthp.imgix.net/wysiwyg/335-_Website_pillarMobile.jpg?auto=format",
      },
    ],
    referenceVideo: [
      {
        _id: 1,
        videoUrl:
          "https://res.cloudinary.com/dxcyg98s1/video/upload/v1672916577/azgorrlywlfmhrslccra.mp4",
      },
    ],
    pricePerInfluencer: {
      min: 10000,
      max: 20000,
    },
  },
  {
    _id: 3,
    barter: "no",
    campaignBanner:
      "https://cdn.shopify.com/s/files/1/0057/8938/4802/products/last-yellow_1500x.jpg?v=1655371180",
    campaignTitle: "Plug Into Nirvana: The first of its kind",
    campaignBrand: "Boat",
    campaignBrandLogo:
      "https://play-lh.googleusercontent.com/YQherpZ71HhMJZGJJBR9yD_3Z8f3ejEZbHrA1c9MhXqE0UiflPefUjaevApLQ6rqeQ",
    campaignCategory: [CategoriesList[0], CategoriesList[1], CategoriesList[4]],
    campaignTotalCapacity: 420,
    shortlistedInfluencerCount: 121,
    campaignDescription:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    campaignPrice: 78000,
    shortlistedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[1],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[3],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[4],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
    ],
    visitorCount: 1200,
    isCanceled: false,
    dos: [
      "The product shall be the main focus",
      "Use complimenting colours",
      "Follow the guidelines",
      "Submit the campaign content on time",
      "The image should be visually appealing",
      "Mention the caption in the chat which you will be using while posting the image",
      "Make sure the content is high quality, well lit and not heavily edited",
    ],
    donts: [
      "Any sort of nuidity and profanity shall not be entertained",
      "Do not post without approval",
      "Do not share product images available publicly on the internet",
    ],
    appliedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[0],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[1],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[2],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[3],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[4],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[10],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[11],
      },
    ],
    submitedAssignment: [
      {
        submittedBy: UserList[3],
        submittedById: UserList[3]._id,
        submittedOn: "2021-06-23T07:59:52.708+00:00",
        link: "https://youtu.be/TQVGCAU-wRo",
        status: "submitted",
      },
      {
        submittedBy: UserList[7],
        submittedById: UserList[7]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/TGLxjppFqeA",
        status: "reshoot",
      },
      {
        submittedBy: UserList[9],
        submittedById: UserList[9]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/__ozZfxNZwA",
        status: "approved",
      },
    ],
    uploadedCampaigns: [
      {
        submittedBy: UserList[3],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[4],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[5],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[6],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[8],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[9],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
    ],
    campaignPlatforms: [
      {
        platformName: "instagram",
        platformLogo: Icons.InstagramIcon,
      },
      {
        platformName: "youtube",
        platformLogo: Icons.YouTubeIcon,
      },
      {
        platformName: "facebook",
        platformLogo: Icons.FacebookIcon,
      },
    ],
    age: {
      min: 18,
      max: 30,
    },
    gender: "Male & Female",
    followers: {
      min: 10000,
      max: 25000,
    },
    followersFB: {
      min: 20000,
      max: 50000,
    },
    subscribers: {
      min: 100000,
    },
    campaignPeriod: {
      startDate: "2022-05-12T07:59:52.708+00:00",
      endDate: "2022-06-12T07:59:52.708+00",
    },
    campaignPaymentType: ["cash"], //in real this will be object id of paymentType
    campaignStatus: "active",
    deliverableType: [
      DeliverableCampaignType[0],
      DeliverableCampaignType[1],
      DeliverableCampaignType[2],
      DeliverableCampaignType[3],
      DeliverableCampaignType[4],
    ],
    productReference: [
      {
        _id: 1,
        productName: "Hangover nimbu soda flame",
        imageUrl:
          "https://images.unsplash.com/photo-1606107557195-0e29a4b5b4aa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80",
        price: 1200,
      },
      {
        _id: 2,
        productName: "Nike just do it",
        imageUrl:
          "https://images.unsplash.com/photo-1491553895911-0055eca6402d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        price: 4000,
      },
    ],
    referenceImages: [
      {
        _id: 1,
        imageUrl:
          "https://images.unsplash.com/photo-1605408499391-6368c628ef42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      },
      {
        _id: 2,
        imageUrl:
          "https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1325&q=80",
      },
    ],
    referenceVideo: [
      {
        _id: 1,
        videoUrl:
          "https://res.cloudinary.com/dxcyg98s1/video/upload/v1672916577/azgorrlywlfmhrslccra.mp4",
      },
    ],
    pricePerInfluencer: {
      min: 10000,
      max: 20000,
    },
  },
  {
    _id: 4,
    barter: "yes",
    campaignBanner:
      "https://brand.assets.reebok.com/image/upload/f_auto,q_auto,fl_lossy/reebok_enGB/Images/Gender_Card_03_3kids_Desktop_800x600_tcm265-963803.jpg",
    campaignTitle: "Life is Not a Spectator Sport: Live it with Reebok",
    campaignBrand: "Reebok",
    campaignBrandLogo:
      "https://preview.thenewsmarket.com/Previews/RBOK/StillAssets/321451_v2.jpg",
    campaignCategory: [CategoriesList[5], CategoriesList[1]],
    campaignTotalCapacity: 420,
    shortlistedInfluencerCount: 420,
    campaignDescription:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    campaignPrice: 78000,
    shortlistedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[1],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[3],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[4],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
    ],
    visitorCount: 1200,
    isCanceled: false,
    dos: [
      "The product shall be the main focus",
      "Use complimenting colours",
      "Follow the guidelines",
      "Submit the campaign content on time",
      "The image should be visually appealing",
      "Mention the caption in the chat which you will be using while posting the image",
      "Make sure the content is high quality, well lit and not heavily edited",
    ],
    donts: [
      "Any sort of nuidity and profanity shall not be entertained",
      "Do not post without approval",
      "Do not share product images available publicly on the internet",
    ],
    appliedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[0],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[1],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[2],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[3],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[4],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[10],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[11],
      },
    ],
    submitedAssignment: [
      {
        submittedBy: UserList[3],
        submittedById: UserList[3]._id,
        submittedOn: "2021-06-23T07:59:52.708+00:00",
        link: "https://youtu.be/TQVGCAU-wRo",
        status: "submitted",
      },
      {
        submittedBy: UserList[7],
        submittedById: UserList[7]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/TGLxjppFqeA",
        status: "reshoot",
      },
      {
        submittedBy: UserList[9],
        submittedById: UserList[9]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/__ozZfxNZwA",
        status: "approved",
      },
    ],
    uploadedCampaigns: [
      {
        submittedBy: UserList[3],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[4],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[5],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[6],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[8],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[9],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
    ],
    campaignPlatforms: [
      {
        platformName: "instagram",
        platformLogo: Icons.InstagramIcon,
      },
      {
        platformName: "youtube",
        platformLogo: Icons.YouTubeIcon,
      },
      {
        platformName: "facebook",
        platformLogo: Icons.FacebookIcon,
      },
    ],
    age: {
      min: 18,
      max: 30,
    },
    gender: "Male & Female",
    followers: {
      min: 10000,
      max: 25000,
    },
    followersFB: {
      min: 20000,
      max: 50000,
    },
    subscribers: {
      min: 100000,
    },
    campaignPeriod: {
      startDate: "2022-05-12T07:59:52.708+00:00",
      endDate: "2022-06-12T07:59:52.708+00",
    },
    campaignPaymentType: ["cash"], //in real this will be object id of paymentType
    campaignStatus: "active",
    deliverableType: [
      DeliverableCampaignType[0],
      DeliverableCampaignType[1],
      DeliverableCampaignType[2],
      DeliverableCampaignType[3],
      DeliverableCampaignType[4],
    ],
    productReference: [
      {
        _id: 1,
        productName: "Hangover nimbu soda flame",
        imageUrl:
          "https://images.unsplash.com/photo-1606107557195-0e29a4b5b4aa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80",
        price: 1200,
      },
      {
        _id: 2,
        productName: "Nike just do it",
        imageUrl:
          "https://images.unsplash.com/photo-1491553895911-0055eca6402d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        price: 4000,
      },
    ],
    referenceImages: [
      {
        _id: 1,
        imageUrl:
          "https://images.unsplash.com/photo-1605408499391-6368c628ef42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      },
      {
        _id: 2,
        imageUrl:
          "https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1325&q=80",
      },
    ],
    referenceVideo: [
      {
        _id: 1,
        videoUrl:
          "https://res.cloudinary.com/dxcyg98s1/video/upload/v1672916577/azgorrlywlfmhrslccra.mp4",
      },
    ],
    pricePerInfluencer: {
      min: 10000,
      max: 20000,
    },
  },
  {
    _id: 5,
    barter: "yes",
    campaignBanner:
      "https://brand.assets.reebok.com/image/upload/f_auto,q_auto,fl_lossy/reebok_enGB/Images/Gender_Card_03_3kids_Desktop_800x600_tcm265-963803.jpg",
    campaignTitle: "Life is Not a Spectator Sport: Live it with Reebok",
    campaignBrand: "Reebok",
    campaignBrandLogo:
      "https://preview.thenewsmarket.com/Previews/RBOK/StillAssets/321451_v2.jpg",
    campaignCategory: [CategoriesList[5], CategoriesList[1]],
    campaignTotalCapacity: 420,
    shortlistedInfluencerCount: 420,
    campaignDescription:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    campaignPrice: 78000,
    shortlistedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[1],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[3],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[4],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
    ],
    visitorCount: 1200,
    isCanceled: false,
    dos: [
      "The product shall be the main focus",
      "Use complimenting colours",
      "Follow the guidelines",
      "Submit the campaign content on time",
      "The image should be visually appealing",
      "Mention the caption in the chat which you will be using while posting the image",
      "Make sure the content is high quality, well lit and not heavily edited",
    ],
    donts: [
      "Any sort of nuidity and profanity shall not be entertained",
      "Do not post without approval",
      "Do not share product images available publicly on the internet",
    ],
    appliedInfluencers: [
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[0],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[1],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[2],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[3],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[4],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[5],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[6],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[7],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[8],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[9],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[10],
      },
      {
        appliedOn: "2021-06-23T07:59:52.708+00:00",
        user: UserList[11],
      },
    ],
    submitedAssignment: [
      {
        submittedBy: UserList[3],
        submittedById: UserList[3]._id,
        submittedOn: "2021-06-23T07:59:52.708+00:00",
        link: "https://youtu.be/TQVGCAU-wRo",
        status: "submitted",
      },
      {
        submittedBy: UserList[7],
        submittedById: UserList[7]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/TGLxjppFqeA",
        status: "reshoot",
      },
      {
        submittedBy: UserList[9],
        submittedById: UserList[9]._id,
        submittedOn: "2022-08-12T07:59:52.708+00:00",
        link: "https://youtu.be/__ozZfxNZwA",
        status: "approved",
      },
    ],
    uploadedCampaigns: [
      {
        submittedBy: UserList[3],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[4],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[5],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[6],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[8],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
      {
        submittedBy: UserList[9],
        uploadedOn: "2021-06-27T07:59:52.708+00:00",
        payable: 21000,
        links: [
          {
            type: "instaReel",
            link: "https://www.instagram.com/reel/CmqtOm-Imyn/?igshid=NWQ4MGE5ZTk%3D",
          },
          {
            type: "instagramPost",
            link: "https://www.instagram.com/p/CljqPlOjrOq/?hl=en",
          },
          {
            type: "facebookPost",
            link: "https://www.facebook.com/photo/?fbid=1249252048987188&set=ecnf.100044571993340",
          },
          {
            type: "youtubeShorts",
            link: "https://www.youtube.com/shorts/PjSlmRiSJh8",
          },
          {
            type: "youTubeVideo",
            link: "https://www.youtube.com/watch?v=YCoQwZ9BQ9Q",
          },
        ],
      },
    ],
    campaignPlatforms: [
      {
        platformName: "instagram",
        platformLogo: Icons.InstagramIcon,
      },
      {
        platformName: "youtube",
        platformLogo: Icons.YouTubeIcon,
      },
      {
        platformName: "facebook",
        platformLogo: Icons.FacebookIcon,
      },
    ],
    age: {
      min: 18,
      max: 30,
    },
    gender: "Male & Female",
    followers: {
      min: 10000,
      max: 25000,
    },
    followersFB: {
      min: 20000,
      max: 50000,
    },
    subscribers: {
      min: 100000,
    },
    campaignPeriod: {
      startDate: "2022-05-12T07:59:52.708+00:00",
      endDate: "2022-06-12T07:59:52.708+00",
    },
    campaignPaymentType: ["cash"], //in real this will be object id of paymentType
    campaignStatus: "active",
    deliverableType: [
      DeliverableCampaignType[0],
      DeliverableCampaignType[1],
      DeliverableCampaignType[2],
      DeliverableCampaignType[3],
      DeliverableCampaignType[4],
    ],
    productReference: [
      {
        _id: 1,
        productName: "Hangover nimbu soda flame",
        imageUrl:
          "https://images.unsplash.com/photo-1606107557195-0e29a4b5b4aa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80",
        price: 1200,
      },
      {
        _id: 2,
        productName: "Nike just do it",
        imageUrl:
          "https://images.unsplash.com/photo-1491553895911-0055eca6402d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        price: 4000,
      },
    ],
    referenceImages: [
      {
        _id: 1,
        imageUrl:
          "https://images.unsplash.com/photo-1605408499391-6368c628ef42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      },
      {
        _id: 2,
        imageUrl:
          "https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1325&q=80",
      },
    ],
    referenceVideo: [
      {
        _id: 1,
        videoUrl:
          "https://res.cloudinary.com/dxcyg98s1/video/upload/v1672916577/azgorrlywlfmhrslccra.mp4",
      },
    ],
    pricePerInfluencer: {
      min: 10000,
      max: 20000,
    },
  },
];

export const CategoryList = [
  {
    key: "0",
    searchType: "brand",
    label: <Typography variant="body1">Brand</Typography>,
  },
  {
    key: "1",
    searchType: "campaignName",
    label: <Typography variant="body1">Campaign Name</Typography>,
  },
  {
    key: "2",
    searchType: "category",
    label: <Typography variant="body1">Category</Typography>,
  },
];

export const BrandList = [
  {
    _id: 1,
    name: "Nike",
    logo: "https://1000logos.net/wp-content/uploads/2021/11/Nike-Logo.png",
    tagline: "Just do it",
    campaigns: [CampaignsList[0], CampaignsList[1]],
  },
];

export const SubmittedValues = {
  notes:
    "Below are the media links uploaded by the influencer. Click on each link to view the uploaded media. The links will open in new tab.",
  links: [
    "https://mui.com/material-ui/guides/right-to-left/",
    "https://mui.com/material-ui/guides/composition/",
    "https://mui.com/material-ui/experimental-api/classname-generator/",
  ],
};

export const DosList = [
  "The product shall be the main focus",
  "Use complimenting colours",
  "Follow the guidelines",
  "Submit the campaign content on time",
  "The image should be visually appealing",
  "Mention the caption in the chat which you will be using while posting the image",
  "Make sure the content is high quality, well lit and not heavily edited",
];
export const DontsList = [
  "Any sort of nuidity and profanity shall not be entertained",
  "Do not post without approval",
  "Do not share product images available publicly on the internet",
];

export const AboutText = {
  brand:
    "Bringing you quality & innovation in everything we do. Join our community & let's create something amazing together 🌟 ",
  influencer:
    "Adventure seeker, spreading positivity & inspiration through my passions. Join me on my journey 🌟✨",
};
