import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

export const MessagesWrapper = styled.div`
  flex: 1 !important;
  min-height: 25rem;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  .messageBox {
    overflow-y: scroll;
    /* flex: 1; */
    height: 100%;
    /* background-color: red; */
  }
  .inputMessage {
    flex: 1;
    height: 100%;
    border-radius: 5rem;
    background-color: ${Colors.inputBg};
    border: none;
    padding: 0 0.9rem;
    margin-right: 0.7rem;
  }
  .inputMessage:focus {
    border: none;
    outline: none;
  }
  .sendIcon {
    background-color: ${Colors.lightPurple};
  }
  .sendIcon:hover {
    background-color: ${Colors.faintedGreen};
  }
  .userBox {
    position: relative;
    left: 0;
    background-color: ${Colors.messageBg};
    width: 80%;
    border-radius: 0px 10px 10px 10px;
    padding: 0.8rem;
    line-height: 24px;
  }
  .username {
    font-weight: 600;
    font-family: ${FontType.openSans}, sans-serif !important;
  }
  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 14px;
    object-fit: cover;
    object-position: center;
  }
  .time {
    font-size: 11px;
    color: ${Colors.cardText};
    font-family: ${FontType.openSans}, sans-serif;
    text-align: right;
    width: 87%;
  }
  .adminBox {
    position: relative;
    right: 0;
    background-color: ${Colors.lightPurple};
    width: 89%;
    border-radius: 10px 0px 10px 10px;
    padding: 0.8rem;
    line-height: 24px;
    margin-top: 2rem;
    color: ${Colors.white};
  }
`;
