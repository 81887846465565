import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius, FontType, Shadow } from "../../../config/Values";

export const UserProfileWrapper = styled.div`
  .profileHeaderMain {
    background-color: ${Colors.white};
    height: auto;
    padding: 1rem 1.5rem;
    border-radius: ${BorderRadius.mediumRadius};
    flex-wrap: wrap;
  }
  .userNameAndAvatar {
    display: flex;
    align-items: center;
  }
  .avatar {
    box-shadow: ${Shadow.dark};
  }
  .subText {
    font-size: 0.8rem;
    font-weight: 400;
    color: ${Colors.textGrey};
  }
  .actionsMain {
    background-color: ${Colors.faintedWhiteBg};
    border-radius: ${BorderRadius.mediumRadius};
    padding: 0.4rem 0.4rem;
    width: fit-content;
    position: relative;
  }
  .actionText {
    color: ${Colors.textBlack};
    font-weight: 400;
    margin: 0 0.25rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 10px;
    background-color: transparent;
    font-family: ${FontType.openSans}, sans-serif;
    z-index: 2;
  }

  .glider {
    position: absolute;
    display: flex;
    padding: 0;
    transform: ${(prop) =>
      prop.selectedAction === "1"
        ? "translateX(0)"
        : prop.selectedAction === "2"
        ? "translateX(78%)"
        : "translateX(207%)"};
    height: 40px;
    width: ${(prop) =>
      prop.selectedAction === "1"
        ? prop.divWidth1
        : prop.selectedAction === "2"
        ? prop.divWidth2
        : prop.divWidth3};
    background-color: ${Colors.white};
    z-index: 1;
    border-radius: 0.7rem;
    -webkit-box-shadow: 7px -92px 66px 82px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 7px -92px 66px 82px rgba(0, 0, 0, 0.06);
    box-shadow: 7px -92px 66px 82px rgba(0, 0, 0, 0.06);
    transition: all 0.5s ease 0s;
  }
`;

export const ParticipatedCampaigns = styled.div`
  height: auto;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1.5rem;
  .heading {
    color: ${Colors.textBlack};
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 1rem;
    font-weight: 500;
  }
  .subHeading {
    color: ${Colors.cardText};
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 0.7rem;
    font-weight: 400;
    margin-top: 0.4rem;
  }
  .columnClass {
    display: flex;
  }
`;
