import { Typography } from "@mui/material";
import React from "react";
import { Colors } from "../../config/Colors";
import styled from "styled-components";

const LargeHeading = ({ title }) => {
  return <HeadingWrapper variant="h4">{title}</HeadingWrapper>;
};

export default LargeHeading;
const HeadingWrapper = styled(Typography)`
  color: ${Colors.textBlack};
  font-family: "Open sans", sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
`;
