import { Typography } from "@mui/material";

export const BorderRadius = {
  smallRadius: "0.5rem",
  mediumRadius: "1rem",
  intermediateRadius: "1.5rem",
  largeRadius: "2rem",
};
export const Shadow = {
  light: "0px 3px 9px 0px rgba(0, 0, 0, 0.02)",
  dark: "0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important",
  veryDark:
    "0 8px 29px -8px hsla(0,0%,8%,.15),0 8px 9px -9px hsla(0,0%,8%,.06)!important",
};

export const PaddingValues = {
  miniPadding: "0.5rem",
  smallPadding: "1rem",
  mediumPadding: "1.5rem",
  largePadding: "2rem",
  xlPadding: "2.5rem",
  xxlPadding: "3rem",
  randomPadding: "1.7rem",
};

export const SnackbarSeverity = {
  error: "error",
  warning: "warning",
  info: "info",
  success: "success",
};

export const FontType = {
  openSans: "Open Sans",
};

export const Gradient = {
  primary: "linear-gradient(310deg, #5e72e4, #825ee4)",
};

// user role
export const UserRole = [
  {
    key: "0",
    role: "all",
    label: <Typography variant="body1">All</Typography>,
  },
  {
    key: "1",
    role: "influencer",
    label: <Typography variant="body1">Influencer</Typography>,
  },
  {
    key: "2",
    role: "brand",
    label: <Typography variant="body1">Brand</Typography>,
  },
  {
    key: "3",
    role: "editor",
    label: <Typography variant="body1">Editor</Typography>,
  },
  {
    key: "4",
    role: "admin",
    label: <Typography variant="body1">Admin</Typography>,
  },
];
// verification status
export const VerificationStatus = [
  {
    key: "0",
    status: "all",
    label: <Typography variant="body1">All</Typography>,
  },
  {
    key: "1",
    status: "verified",
    label: <Typography variant="body1">Verified</Typography>,
  },
  {
    key: "2",
    status: "unverified",
    label: <Typography variant="body1">Unverified</Typography>,
  },
];
// verification status
export const CategoryList = [
  {
    key: "63fd1fe8cd2699663ac0d412",
    status: "Photography",
    label: "Photography",
  },
  {
    key: "63fd2036cd2699663ac0d414",
    status: "Fitness",
    label: "Health & Fitness",
  },
  {
    key: "63cbb3fc0de54ad7bdc59a57",
    status: "LifeStyle",
    label: "LifeStyle",
  },
  {
    key: "63cbb4030de54ad7bdc59a59",
    status: "Technology",
    label: "Technology",
  },
  {
    key: "63fd1feccd2699663ac0d413",
    status: "Beverage",
    label: "Food & Beverage",
  },
  {
    key: "63fd203bcd2699663ac0d415",
    status: "Fashion",
    label: "Fashion & Beauty",
  },
];
export const genderOptions = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 4, name: "Others" },
  { id: 5, name: "Male & Female" },
  { id: 3, name: "All" },
];

export const StoryType = [
  { id: 1, label: "Image", value: "image" },
  { id: 2, label: "Video", value: "video" },
  { id: 3, label: "Text", value: "text" },
  { id: 4, label: "URL", value: "url" },
];
