import { Row } from "antd";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
import { BorderRadius, PaddingValues } from "../../config/Values";

export const HomeWrapper = styled.div`
  flex-wrap: wrap;
`;

export const BlogSynopWrapper = styled.div`
  width: 100%;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius} !important;
  padding: 0;
`;

export const ChartWrapper = styled.div`
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius} !important;
  padding: ${PaddingValues.randomPadding};
`;

export const TableWrapper = styled(Row)`
  max-height: min-content;
  .recentCampaignContainer {
    height: 100%;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
