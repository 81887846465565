import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

export const ThirdStepWrapper = styled.div`
  width: 100%;
  min-height: 15rem !important;

  .imageUploadIcon {
    width: 3.646rem;
    height: 3.991rem;
    object-fit: contain;
    object-position: center;
  }
  .uploadHeading {
    font-weight: 600;
    color: ${Colors.textBlack};
    font-size: 12px;
  }
  .uploadSubHeading {
    font-size: 10px;
    color: ${Colors.greyC9};
  }
  .uploadedImage {
    width: 100% !important;
    max-height: 13rem !important;
    border-radius: ${BorderRadius.smallRadius};
    object-fit: cover;
    object-position: center;
  }

  .ant-upload {
    padding: 0.2rem !important;
    height: 14rem !important;
  }
  .imagesListContainer {
    height: 11.46rem;
    padding: 1.2rem 1rem !important;
    border: 1.3px dashed ${Colors.greyDashedBorder};
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    border-radius: ${BorderRadius.smallRadius} !important;
    flex-wrap: wrap;
  }
  .uploadedImageSmall {
    width: 5.836rem;
    height: 3.653rem;
    object-fit: cover;
    object-position: center;
    border-radius: ${BorderRadius.smallRadius} !important;
  }
  .smallUploader {
    background: ${Colors.greyD9};
    opacity: 0.5;
    border-radius: 7px;
    width: 93.38px;
    height: 58.44px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 0.2rem;
  }
  .smallUploadImage {
    width: 1.534rem;
    height: 1.664rem;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    align-self: center;
  }
  .uploadTextContainer {
    margin-top: -0.2rem;
    text-align: center;
  }
  .uploadText {
    color: ${Colors.textBlack} !important;
    font-weight: 400;
    font-size: 12px;
  }
  .UploadFileContainer {
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: ${BorderRadius.smallRadius};
    border: 1px solid ${Colors.greyDashedBorder};
    padding-left: 0.3rem;
  }
  .fileNameBefore {
    font-size: 10px;
    margin-left: 0.2rem;
  }
  .productUploadedImageContainer {
    border: 0.5px solid ${Colors.greyDashedBorder};
    border-radius: 4px;
    width: 1.97rem;
    height: 1.696rem;
  }
  .productUploadedImage {
    width: 1.094rem;
    height: 1.146rem;
    object-fit: cover;
    object-position: center;
  }
  .productUploadedImageAfter {
    width: 2.699rem;
    height: 1.696rem;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }
  .uploadTextButton {
    text-transform: capitalize;
    text-decoration: underline;
    color: ${Colors.textBlack};
    margin-left: 1rem;
  }
  .addButton {
    background-color: ${Colors.lightPurple};
    border-radius: ${BorderRadius.smallRadius};
    text-transform: capitalize;
    height: 40px;
  }
  /* previous and next button */
  .prevButton {
    color: ${Colors.lightPurple} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border: 2px solid ${Colors.lightPurple};
    border-radius: ${BorderRadius.smallRadius};
    opacity: 0.5;
  }
  .prevButton:hover {
    border: 2px solid ${Colors.lightPurple};
  }
  .nextButton {
    background-color: ${Colors.lightPurple} !important;
    color: ${Colors.white} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    border: 2px solid ${Colors.lightPurple};
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: ${BorderRadius.smallRadius};
  }
`;
