import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InstaDataWrapper } from "./Style";
import DesktopLayout from "../../../theme/DesktopLayout";
import { getInstaDataAPI } from "../../../functions/user";
import { useSelector } from "react-redux";
import { Col, Empty, Image, Row, Typography } from "antd";
import { ImageList } from "../../../config/ImageList";
import { nFormatter } from "../../../config/Function";
import CardHeading from "../../../components/headings/CardHeading";
import SmallHeading from "../../../components/headings/SmallHeading";
import ReactPlayer from "react-player";
const InstaData = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  //   local state
  const [instaData, setInstaData] = useState([]);
  const [loading, setLoading] = useState(false);
  // lifecycle method
  useEffect(() => {
    if (user) {
      getInstaDataByCampaign();
    }
  }, [user]);
  // functions
  const getInstaDataByCampaign = () => {
    setLoading(true);
    getInstaDataAPI(id, user?.token)
      .then((res) => {
        setInstaData(res.data?.instaData);
        setLoading(false);
      })
      .catch((err) => {
        console.log("INSTA DATA EROR==>", err);
        setLoading(false);
      });
  };

  return (
    <DesktopLayout>
      <InstaDataWrapper className="card_style" style={{ minHeight: "100vh" }}>
        {/* InstaData {id}=== {JSON.stringify(instaData, 2, 4)} */}

        <CardHeading title="Post by influencers" />
        <SmallHeading title="(Data is synced every 12 hour)" />
        <Row gutter={[16, 24]} className="mt3 row">
          {instaData?.map((v, i) => {
            const isHeic =
              v?.instaData?.display_url?.includes(".heic") ||
              v?.instaData?.display_url?.includes(".HEIC");
            return (
              <Col xs={24} lg={8} key={i} className="col">
                <div className="card_container grow">
                  {v?.instaData?.is_video ? (
                    <img
                      onClick={() =>
                        window.open(v?.instaData?.video_url, "_blank")
                      }
                      src={ImageList.Heic_Image_Placeholder}
                      alt="influencer post"
                      className="insta_image_post"
                      crossOrigin="anonymous"
                    />
                  ) : (
                    <img
                      onClick={() =>
                        window.open(v?.instaData?.display_url, "_blank")
                      }
                      src={
                        isHeic
                          ? ImageList.Heic_Image_Placeholder
                          : v?.instaData?.display_url
                      }
                      alt="influencer post"
                      className="insta_image_post"
                      crossOrigin="anonymous"
                    />
                  )}
                  <div className="content_container">
                    {/* username */}
                    <p>
                      UserName:
                      <Typography.Link
                        href={`https://www.instagram.com/${v?.instaData?.owner?.username}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="username font"
                      >
                        {"  "} {v?.instaData?.owner?.username}{" "}
                      </Typography.Link>
                    </p>
                    {/* caption */}
                    <p>
                      Caption:
                      <Typography.Text
                        type="secondary"
                        className="username font"
                      >
                        {"  "}{" "}
                        {
                          v?.instaData?.edge_media_to_caption?.edges[0]?.node
                            ?.text
                        }{" "}
                      </Typography.Text>
                    </p>
                    {/* comment */}
                    <p>
                      Comment:
                      <Typography.Text type="success" className="username font">
                        {"  "} {v?.instaData?.edge_media_to_comment?.count} (
                        {nFormatter(v?.instaData?.edge_media_to_comment?.count)}
                        )
                      </Typography.Text>
                    </p>
                    {/* likes */}
                    <p>
                      Likes:
                      <Typography.Text type="warning" className="username font">
                        {"  "} {v?.instaData?.edge_liked_by?.count} (
                        {nFormatter(v?.instaData?.edge_liked_by?.count)})
                      </Typography.Text>
                    </p>
                    {/* if it is video then there will be views count as well */}{" "}
                    {v?.instaData?.is_video && (
                      <p>
                        Views:
                        <Typography.Text
                          type="warning"
                          className="username font"
                        >
                          {"  "} {v?.instaData?.video_view_count} (
                          {nFormatter(v?.instaData?.video_view_count)})
                        </Typography.Text>
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        {instaData?.length < 1 && (
          <div className="flexCenter" style={{ width: "100%" }}>
            <Empty />
          </div>
        )}
      </InstaDataWrapper>
    </DesktopLayout>
  );
};

export default InstaData;
