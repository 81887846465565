import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

const TABLE_PADDING_VALUE = "0.7rem";

export const InfluencerInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.white};
  border-radius: ${(prop) =>
    prop.all
      ? `${BorderRadius.mediumRadius} ${BorderRadius.mediumRadius} 0 0`
      : BorderRadius.mediumRadius};
  display: flex;
  overflow-x: ${(prop) => prop.isTablet && "scroll"};
  ::-webkit-scrollbar {
    display: none;
  }
  .tableParent {
    width: 100%;
    border-radius: ${BorderRadius.mediumRadius};
    border-collapse: collapse;
    margin: 1rem 1.5rem;
    /* background-color: red; */
  }
  .tableHeader {
    padding: ${TABLE_PADDING_VALUE} !important;
    font-size: 0.7rem;
    color: ${Colors.tableText};
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    /* background-color: blue; */
  }
  .divider {
    width: 100%;
    height: 2px;
    background-color: red;
  }
  tr {
    border-bottom: 1px solid ${Colors.lightGrey};
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  tr:first-child {
    border-top: none;
  }
  tr:last-child {
    border-bottom: none;
  }
  tr:nth-last-child(2) {
    border-bottom: none;
  }
  td {
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  .ant-progress-bg {
    height: 3px !important;
  }
  .tableData {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${Colors.textGrey};
    font-family: ${FontType.openSans}, sans-serif !important;
  }
  .influencerText {
    margin-left: 0.7rem;
  }
  .centerAlignText {
    text-align: center;
    font-size: 0.7rem;
    color: ${Colors.tableText};
  }
  .name {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${Colors.textBlack};
    text-decoration: none;
  }
  .email {
    font-size: 0.6rem;
    color: ${Colors.cardText};
    text-transform: lowercase;
    font-family: ${FontType.openSans}, sans-serif !important;
    text-decoration: none;
  }
`;
