import { Button, Chip, Typography } from "@mui/material";
import { Avatar, Empty, message, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Colors } from "../../../../../config/Colors";
import { formatDate } from "../../../../../config/Function";
import { Icons } from "../../../../../config/IconsList";
import { ImageList } from "../../../../../config/ImageList";
import {
  approveOrRejectMediaAPI,
  getInfluencerDetailCampaignWiseAPI,
} from "../../../../../functions/campaign";
import useViewport from "../../../../../viewport/useViewport";
import ShortlistedInfluencerDrawer from "../../../../drawer/campaign/ShortlistedInfluencer/ShortlistedInfluencerDrawer";
import CardHeading from "../../../../headings/CardHeading";
import AssignmentSubmittedModal from "../../../../modal/AssignmentSubmittedModal";
import ReshootModal from "../../../../modal/ReshootModal";
import TagIconOutlined from "../../../../tag/TagIconOutlined";
import TagWithBg from "../../../../tag/TagWithBg";
import { ShortlistedWrapper } from "./Style";
import {
  collection,
  getDocs,
  limit as lim,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../../../firebaseConfig";

const ShortlistedInfluencers = ({ campaignId, all, brandId }) => {
  const { isTablet, isMidrange } = useViewport();
  const user = useSelector((state) => state.user);
  // state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReshootModalOpen, setIsReshootModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [shortlistedInfluencers, setShortlistedInfluencers] = useState([]);
  const [toatlInfluencerCount, setToatlInfluencerCount] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [submittedUrls, setSubmittedUrls] = useState([]);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (user?.email !== "") {
      getInfluencerList();
    }
  }, [user, page, limit]);

  const getInfluencerList = () => {
    const values = {
      page,
      limit,
      campaignId,
      influencerType: "shortlisted",
    };
    setRefreshing(true);

    getInfluencerDetailCampaignWiseAPI(values, user?.token)
      .then((res) => {
        console.log("SHORTLISTED INFLUENCERS", res.data);
        setShortlistedInfluencers(res.data.influencers);
        setToatlInfluencerCount(res.data.totalCount);
        setRefreshing(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
        setRefreshing(false);
      });
  };

  const showModal = (val) => {
    setIsModalOpen(true);
    setSubmittedUrls(val);
  };
  const showReshootModal = () => {
    setIsReshootModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsReshootModalOpen(false);
  };

  // reject influencer who has applied for this campaign
  const rejectInfluencer = (influencerId) => {
    if (window.confirm("Are you sure you want to reject this influencer?")) {
      message.warning("Influencer has been asked to reshoot the campaign");
      // this api call will make the rejection call by setting the isApproved to false
      approveTheDeliverables(influencerId, false);
    }
  };
  // connectionId ==>  brandId + influencerId + campaignId
  // room ===> brandId + campaignId;
  const approveTheDeliverables = (influencerId, isApproved) => {
    const connectionId = brandId + influencerId + campaignId;
    const room = brandId + influencerId;
    const myValues = {
      isApproved,
      connectionId,
      room: room,
      brandId: user?._id,
      senderId: user?._id,
    };
    if (window.confirm("Are you sure you want to approve this deliverables?")) {
      approveOrRejectMediaAPI(myValues, user?.token)
        .then((res) => {
          message.success("Media has been approved.");
        })
        .catch((err) => console.log(err.response.data.message));
    }
  };
  // this function check if the demo media url links are approved or not
  function checkDemoApproval(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].isDemoApproved === true) {
        console.log("At least one object is approved", i);
        return true; // At least one object is approved
      }
    }
    return false; // No object is approved
  }
  // jsx
  return (
    <>
      <ShortlistedInfluencerDrawer
        all={true}
        shortlistedInfluencers={shortlistedInfluencers}
        rejectInfluencer={rejectInfluencer}
        page={page}
        setPage={setPage}
        onClose={onClose}
        toatlInfluencerCount={toatlInfluencerCount}
        openDrawer={openDrawer}
        setLimit={setLimit}
        campaignId={campaignId}
        brandId={brandId}
      />

      <AssignmentSubmittedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        submittedUrls={submittedUrls}
      />

      <ReshootModal isOpen={isReshootModalOpen} handleCancel={handleCancel} />
      {/* {JSON.stringify(lists, 2, 4)} */}
      <ShortlistedWrapper isTablet={isTablet}>
        <div className="flexBetween">
          <CardHeading title="Shortlisted Influencers" />
          <div
            onClick={getInfluencerList}
            style={{
              cursor: "pointer",
              transform: refreshing && "rotate(90deg)",
              transition: "all 3s ease-in",
            }}
          >
            {" "}
            <Icons.RefreshIcon />
          </div>
        </div>
        <>
          <table className="tableParent mt1" style={{ width: "100%" }}>
            {/* header */}
            <tr>
              <th className="tableHeader" style={{ textAlign: "left" }}>
                Name
              </th>
              <th className="tableHeader">Status</th>
              <th className="tableHeader">Submission</th>
              <th className="tableHeader" style={{ paddingLeft: 60 }}>
                Action
              </th>
            </tr>

            {shortlistedInfluencers?.map((item, i) => {
              let myStatus =
                item?.mediaUrlLinks?.length === 0 ? "pending" : "submitted";
              let isEq = item?.mediaUrlLinks?.length === 0 ? true : false;

              const isApproved =
                item?.mediaUrlLinks?.length > 0
                  ? checkDemoApproval(item?.mediaUrlLinks)
                  : false;

              return (
                <tr key={i} className="tableRow">
                  <td>
                    <a
                      href={`/user/${item?._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tableData"
                    >
                      <Avatar
                        variant="rounded"
                        sx={{ width: 37, height: 37 }}
                        style={{ borderRadius: 10 }}
                        src={item?.profilePicture?.url}
                      />
                      <div className="influencerText">
                        <Typography className="name">
                          {" "}
                          {item?.firstName + " " + item?.lastName}{" "}
                        </Typography>
                        <Typography className="email">{item?.email}</Typography>
                      </div>
                    </a>
                  </td>
                  <td className="appliedCol">
                    <TagWithBg status={myStatus} title={myStatus} />
                  </td>
                  <td className="appliedCol">
                    {isEq ? (
                      <div className="dash"> - - </div>
                    ) : (
                      <TagIconOutlined
                        title="View"
                        iconUrl={ImageList.EyeImageIcon}
                        onClick={() => showModal(item?.mediaUrlLinks)}
                      />
                    )}
                  </td>
                  <td className="centerAlignText">
                    {isApproved ? (
                      <div className="dash actionDash"> - - </div>
                    ) : !isApproved && item?.mediaUrlLinks?.length > 0 ? (
                      <>
                        <Chip
                          clickable
                          label="Approve"
                          size="small"
                          onClick={() =>
                            approveTheDeliverables(item?._id, true)
                          }
                          style={{
                            color: Colors.darkGreen,
                            cursor: "pointer",
                            textAlign: "center",
                            backgroundColor: Colors.tagGreenBg,
                            borderRadius: "8px",
                            fontWeight: "300",
                            marginRight: !isMidrange && 8,
                            marginTop: isMidrange && 8,
                          }}
                          icon={
                            <img
                              src={ImageList.AcceptFilledImageIcon}
                              alt="accept influencer"
                              className="tagIcon"
                            />
                          }
                        />

                        <Chip
                          clickable
                          onClick={() => rejectInfluencer(item?._id)}
                          label="Reshoot"
                          size="small"
                          style={{
                            color: Colors.textBlack,
                            cursor: "pointer",
                            borderRadius: "8px",
                            background: Colors.tagGreyBg,
                            fontWeight: "300",
                            marginTop: isMidrange && 8,
                          }}
                          icon={
                            <img
                              src={ImageList.ReshootImageIcon}
                              alt="accept influencer"
                              className="tagIcon"
                            />
                          }
                        />
                      </>
                    ) : null}
                  </td>
                </tr>
              );
            })}
            {/* view all button */}
            {!all && (
              <tr>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Button
                    onClick={() => showDrawer()}
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                      color: Colors.textGrey,
                      textTransform: "capitalize",
                    }}
                    endIcon={<Icons.ViewAllIcon style={{ fontSize: 14 }} />}
                  >
                    View All
                  </Button>
                </td>
              </tr>
            )}

            {/* table ends here */}
          </table>
          {shortlistedInfluencers?.length < 1 && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Empty />
            </div>
          )}
        </>
      </ShortlistedWrapper>
    </>
  );
};

export default ShortlistedInfluencers;
