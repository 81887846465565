import { Button, Typography } from "@mui/material";
import { Col, Input, message, Row, Select, Space, Spin } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SmallHeading from "../../../components/headings/SmallHeading";
import NormalLabel from "../../../components/inputLabel/NormalLabel";
import UploadWidget from "../../../components/upload/cloudinaryWidget/UploadWidget";
import { Colors } from "../../../config/Colors";
import { CreateReelsSchema } from "../../../config/Schemas";
import { CategoryList } from "../../../config/Values";
import { createReelsAPI } from "../../../functions/reels";
import DesktopLayout from "../../../theme/DesktopLayout";
import { CreateReelsWrapper } from "./CreateReelsStyle";
const { Option } = Select;

//  initial values
const initialValues = {
  videoUrl: "",
  userId: "",
  coverImageUrl: "",
  displayName: "Ingenkart",
  description: "",
  categories: ["LifeStyle"],
};

const CreateReels = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: CreateReelsSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("CREATE NEW REELS VALUES==>", values);
      setLoading(true);
      createReelsAPI(values, user?.token)
        .then((res) => {
          console.log(res.data);
          message.success("New reel has been created successfully!!");
          setLoading(false);
          clearAllData();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          message.error(err.response.data.message);
        });
    },
  });

  // useEffect
  useEffect(() => {
    if (user?.token !== "") {
      handleChange({
        target: { name: "userId", value: user?._id },
      });
    }
  }, [user, values?.videoUrl]);

  /**
   * handleOnUpload
   */

  const handleOnUpload = async (error, result, widget) => {
    if (error) {
      setError(error);
      widget.close({
        quiet: true,
      });
      return;
    }
    console.log("AFTER VIDEO UPLOAD", result);
    console.log("AFTER VIDEO UPLOAD ERROR", error);
    // let myThumbnail = result?.info?.secure_url.split(".mp4")[0] + ".jpg";
    let myThumbnail = await result?.info?.secure_url.replace(".mp4", ".jpg");
    if (myThumbnail !== "") {
      handleChange({
        target: { name: "videoUrl", value: result?.info?.secure_url },
      });
      handleChange({
        target: { name: "coverImageUrl", value: myThumbnail },
      });
    } else {
      message.error("Something went wrong.");
      message.warning("Kindly reload the page.");
    }
  };

  // clear the form
  const clearAllData = () => {
    handleChange({
      target: { name: "videoUrl", value: initialValues?.videoUrl },
    });
    handleChange({
      target: { name: "description", value: initialValues?.description },
    });
    handleChange({
      target: { name: "coverImageUrl", value: initialValues?.coverImageUrl },
    });
    handleChange({
      target: { name: "categories", value: initialValues?.categories },
    });
  };

  return (
    <DesktopLayout>
      <div className="flexCenter">
        <CreateReelsWrapper>
          {/* {JSON.stringify(values, null, 4)} */}
          <SmallHeading title="Reels" color={Colors.textBlack} weight={"400"} />
          <SmallHeading
            title="Create new reels"
            weight={"300"}
            size="0.8rem"
            mTop="-1rem"
          />
          {/* form starts here */}
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <NormalLabel identifier="displayName" title="Display Name" />
              <Input
                size="large"
                onChange={handleChange}
                name="displayName"
                value={values?.displayName}
                id="displayName"
              />
            </Col>
            <Col span={24}>
              <NormalLabel identifier="description" title="Reels Description" />
              <Input.TextArea
                showCount
                size="large"
                onChange={handleChange}
                name="description"
                value={values?.description}
                placeholder="Your caption for the video goes here!!"
                id="description"
                autoSize={{
                  minRows: 8,
                  maxRows: 12,
                }}
              />
            </Col>
            <Col span={24}>
              <NormalLabel identifier="categories" title="Select Categories" />
              <Select
                mode="multiple"
                id="categories"
                size="large"
                maxTagCount="responsive"
                style={{
                  width: "100%",
                }}
                placeholder="select categories"
                value={values?.categories}
                onChange={(e) =>
                  handleChange({ target: { name: "categories", value: e } })
                }
                optionLabelProp="label"
              >
                {CategoryList?.map((item, i) => {
                  return (
                    <Option key={i} value={item?.label} label={item?.label}>
                      <Space> {item?.label} </Space>
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={24}>
              <NormalLabel identifier="videoUpload" title="Upload video" />
              {/* cloudinary upload widget */}
              <UploadWidget onUpload={handleOnUpload}>
                {({ open }) => {
                  function handleOnClick(e) {
                    e.preventDefault();
                    open();
                  }
                  return (
                    <button
                      className="videoUploadButton flexCenter"
                      onClick={handleOnClick}
                    >
                      {values?.videoUrl === "" ? (
                        "Upload a video"
                      ) : (
                        <>
                          <Typography variant="body1">
                            Video has been successfully uploaded to
                          </Typography>
                          <Button
                            style={{ textTransform: "lowercase" }}
                            href={values?.videoUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {values?.videoUrl.substring(0, 28) + "..."}{" "}
                          </Button>
                        </>
                      )}
                    </button>
                  );
                }}
              </UploadWidget>
              {touched.videoUrl && errors.videoUrl && (
                <Typography
                  color={Colors.alertRed}
                  fontSize="0.6rem"
                  marginTop="0.2rem"
                >
                  {" "}
                  Video was not uploaded properly.Kindly reload the page.{" "}
                </Typography>
              )}
            </Col>
            {/* upload button */}
            <Col span={24}>
              <Button
                className="uploadReelButton"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <Spin /> : "Upload Reel"}
              </Button>
              <Button
                className="clearAllButton uploadReelButton"
                onClick={clearAllData}
              >
                Clear All
              </Button>
            </Col>
          </Row>
        </CreateReelsWrapper>
      </div>
    </DesktopLayout>
  );
};

export default CreateReels;
