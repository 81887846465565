import { createSlice } from "@reduxjs/toolkit";

export const stepSlice = createSlice({
  name: "stepSlice",
  initialState: {
    currentStep: 0,
    data: "",
  },
  reducers: {
    changeStepCount: (state, action) => {
      state.currentStep = action.payload.currentStep;
      state.data = action.payload.data;
    },
  },
});

export const { changeStepCount } = stepSlice.actions;
export default stepSlice.reducer;
