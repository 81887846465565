import axios from "axios";

export const getInfluencerListAPI = async (page, limit, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/influencers?page=${page}&limit=${limit}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};
