import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius, FontType } from "../../../config/Values";

export const CategoryCardWrapper = styled.div`
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1.5rem;
  .parent {
    display: flex;
    align-items: center;
  }
  .info {
    padding-left: 1.3rem;
    flex: 1;
    display: flex;
    align-items: center;
  }
  .categoryInfo {
    font-size: 0.7rem;
    font-family: ${FontType.openSans}, sans-serif !important;
    color: ${Colors.textGrey};
  }
`;
