import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

export const StepZeroWrapper = styled.div`
  padding: 1rem 3rem;
  flex-direction: column;
  .caption {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }
  .nextButton {
    background-color: ${Colors.lightPurple} !important;
    color: ${Colors.white} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    border: 2px solid ${Colors.lightPurple};
    border-radius: ${BorderRadius.smallRadius};
  }
`;
