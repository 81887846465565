import styled from "styled-components";
import { Colors } from "../config/Colors";
import { BorderRadius } from "../config/Values";

export const LayoutWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  .navigatorContainer {
    width: ${(prop) => (prop.isSideNavExpanded ? "17.35%" : "7%")};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
  }

  .childrenContainer {
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: ${(prop) => (prop.isMobile ? "0" : "1.5rem")};
    padding-top: 0.4rem;
    transition: all 0.5s ease-in-out;

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .row {
    display: flex;
    align-items: stretch;
  }

  .col {
    display: flex;
    flex-direction: column;
  }
  .grow {
    flex-grow: 1;
  }
  .card_style {
    width: 100%;
    min-height: 27rem;
    background-color: ${Colors.white};
    border-radius: ${BorderRadius.mediumRadius};
    padding: 1.5rem;
  }
`;

export const BannerWrapper = styled.div`
  width: 100%;
  height: 39.27vh;
  background-image: linear-gradient(
      45deg,
      rgba(94, 114, 228, 0.6),
      rgba(94, 114, 228, 0.6)
    ),
    url("https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;
