import React, { useEffect, useState } from "react";
import DesktopLayout from "../../../theme/DesktopLayout";
import { UnverifiedWrapper } from "./UnverifiedCampaignStyle";
import {
  Avatar,
  Card,
  Dropdown,
  message,
  Pagination,
  Select,
  Skeleton,
  Tooltip,
} from "antd";
import { Icons } from "../../../config/IconsList";
import {
  approveCampaignAPI,
  getCampaignByStatusAPI,
} from "../../../functions/campaign";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Colors } from "../../../config/Colors";
import { PaginationWrapper } from "../../../components/table/User/Style";
import SkeletonButton from "antd/es/skeleton/Button";
const { Meta } = Card;

// list of different actions that can change the status of a campaign
const ActionList = [
  {
    key: "0",
    actionType: "inreview",
    label: <Typography variant="body1">Under review</Typography>,
  },
  {
    key: "1",
    actionType: "working",
    label: <Typography variant="body1">Working (make it live)</Typography>,
  },
  {
    key: "2",
    actionType: "cancelled",
    label: <Typography variant="body1">Cancel</Typography>,
  },
  {
    key: "3",
    actionType: "completed",
    label: <Typography variant="body1">Completed</Typography>,
  },
  {
    key: "4",
    actionType: "rejected",
    label: <Typography variant="body1">Reject</Typography>,
  },
];

const UnverifiedCampaign = () => {
  const user = useSelector((state) => state?.user);
  //   state
  const [campaigns, setCampaigns] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState(["inreview"]);
  const [loading, setLoading] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);

  // useEffect
  useEffect(() => {
    if (user?.token !== "") {
      getUnverifiedCampaigns();
    }
  }, [user, page, limit, status, isStatusChanged]);

  // get the list of campaigns based on the array of status choosen
  const getUnverifiedCampaigns = () => {
    setLoading(true);
    const values = {
      page,
      limit,
      status,
    };
    getCampaignByStatusAPI(values, user?.token)
      .then((res) => {
        console.log("LIST OF CAMPAIGN", res.data);
        setCampaigns(res.data.campaigns);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error(err.response.data.message);
      });
  };

  // this will help to select diiferent action from the dropdown
  const handleChangeActionType = (campaignId, currentStatus, e) => {
    let myAction = ActionList?.find((item) => item.key === e.key);
    if (
      window.confirm(
        `Are you sure you want to change the status from ${currentStatus} to ${myAction?.actionType}`
      )
    ) {
      callActionOnCampaign(campaignId, myAction.actionType);
    }
  };
  // change the status of the campaign based on the action taken and the campaignId.
  // action taken will detemine the status
  const callActionOnCampaign = (campaignId, status) => {
    const values = {
      campaignId,
      status,
    };
    approveCampaignAPI(values, user?.token)
      .then((res) => {
        message.success("Action successfully taken");
        console.log("APPROVED SUCCESSFULLY", res.data);
        setIsStatusChanged((val) => !val);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };

  // page  size change: number of item to be displayed on a single page
  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setLimit(pageSize);
  };
  // change page number
  const handleChangePageNumber = (page, pageSize) => {
    setPage(page);
  };
  const filteredOptions = ActionList.filter(
    (o) => !status.includes(o.actionType)
  ).map((item) => item?.actionType);

  //   jsx
  return (
    <DesktopLayout>
      <Select
        mode="multiple"
        placeholder="Inserted are removed"
        value={status}
        onChange={setStatus}
        style={{
          width: "70%",
          position: "fixed",
          zIndex: 99,
        }}
        options={filteredOptions.map((item) => ({
          value: item,
          label: item,
        }))}
      />

      <UnverifiedWrapper className="flexBetween mt2">
        {campaigns?.map((item, i) => {
          return (
            <Skeleton active loading={loading}>
              <Card
                hoverable
                key={i}
                style={{
                  width: 300,
                }}
                cover={
                  <img
                    className="bannerImage"
                    alt="example"
                    src={item?.campaignBanner}
                  />
                }
                actions={[
                  <>
                    <Dropdown
                      key="action"
                      menu={{
                        items: ActionList,
                        onClick: (e) =>
                          handleChangeActionType(item?._id, item?.status, e),
                      }}
                      placement="bottom"
                    >
                      <span
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: 12,
                        }}
                      >
                        {" "}
                        <Icons.CancelIcon />
                      </span>
                    </Dropdown>
                  </>,
                  <Tooltip title="Approve the campaign" key="approve">
                    <div
                      onClick={() => callActionOnCampaign(item?._id, "working")}
                    >
                      <Icons.VerifiedIcon />
                    </div>
                  </Tooltip>,

                  <a
                    key="viewCampaign"
                    href={`/campaign/${item?._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.ViewIcon />
                  </a>,
                ]}
              >
                <Meta
                  avatar={<Avatar src={item?.brand?.profilePicture?.url} />}
                  title={
                    <Typography className="font">
                      {" "}
                      {item?.brand?.name}{" "}
                      <span
                        style={{
                          color:
                            item?.status === "rejected"
                              ? Colors.alertRed
                              : item?.status === "completed"
                              ? Colors.lightGreen
                              : Colors.lightPurple,
                          textTransform: "capitalize",
                          fontSize: 12,
                        }}
                      >
                        ({item?.status})
                      </span>{" "}
                    </Typography>
                  }
                  description={item?.campaignName.substring(0, 300) + "..."}
                />
              </Card>
            </Skeleton>
          );
        })}
      </UnverifiedWrapper>
      <PaginationWrapper>
        <Pagination
          pageSizeOptions={[1, 4, 10, 50, 100]}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={500}
          onChange={handleChangePageNumber}
        />
      </PaginationWrapper>
    </DesktopLayout>
  );
};

export default UnverifiedCampaign;
