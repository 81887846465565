import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius } from "../../../../config/Values";

export const PreviewWrapper = styled.div`
  width: 100%;
  min-height: 25rem;
  flex: 1;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  position: relative;
  .cover_photo {
    width: 100%;
    height: 9rem;
    object-fit: cover;
    object-position: center;
    border-radius: ${BorderRadius.mediumRadius} ${BorderRadius.mediumRadius} 0 0;
  }
  .profile_picture {
    position: absolute;
    top: 6rem;
    border: 8px solid rgba(255, 255, 255, 0.84);
    backdrop-filter: blur(1.5px);
  }
  .user_name {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
    letter-spacing: -0.333333px;
    margin-top: 1.9rem;
  }
  .info_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .info_icon {
    width: 22px;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    margin-right: 0.5rem;
  }
  .info_text {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.333333px;
    color: ${Colors.grey8B};
    min-width: 1.5rem;
  }
  .container_box {
    padding: 0 1.5rem;
  }
  .about {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.333333px;
    text-align: justify;
    color: ${Colors.black80};
  }
`;
