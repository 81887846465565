import { Avatar, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CardHeading from "../../../components/headings/CardHeading";
import { Colors } from "../../../config/Colors";
import { Icons } from "../../../config/IconsList";
import DesktopLayout from "../../../theme/DesktopLayout";
import { ParticipatedCampaigns, UserProfileWrapper } from "./ProfileStyle";
import { Col, message, Row, Segmented, Skeleton } from "antd";
import useViewport from "../../../viewport/useViewport";
import UserSettings from "../../../components/cards/UserProfile/UserSettings";
import ProfileInfo from "../../../components/cards/UserProfile/UserInfo";
import { HomeWrapper, TableWrapper } from "../../home/HomeStyles";
import Messages from "../../../components/cards/UserProfile/UserMessage";
import UserCampaign from "../../../components/cards/UserProfile/Campaign";
import ViewAllCampaignByUser from "../../../components/cards/UserProfile/Campaign/ViewAllCampaignByUser";
import CountInfoCard from "../../../components/cards/countInfo/CountInfoCard";
import UserSettingsByAdmin from "../../../components/cards/AdminSettings/SettingsForUser";
import MyMessages from "../../../components/messages";
import { useParams } from "react-router-dom";
import { singleUserInfoAPI } from "../../../functions/user";
import { useSelector } from "react-redux";
import UserInstaDetail from "../../../components/cards/AdminSettings/UserInstaDetails/UserInstaDetail";

const UserProfile = () => {
  let { userId } = useParams();
  const userRed = useSelector((state) => state.user);
  const { isMobile } = useViewport();
  // =============================
  // state
  // =============================
  const [selectedAction, setSelectedAction] = useState("1");
  const [divWidth1, setDivWidth1] = useState(null);
  const [divWidth2, setDivWidth2] = useState(null);
  const [divWidth3, setDivWidth3] = useState(null);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  // =============================
  // useEffect
  // =============================
  const campaigns =
    user?.role === "brand"
      ? user?.userProfile?.campaigns
      : user?.role === "influencer"
      ? user?.userProfile?.campaignsJoined
      : [];
  useEffect(() => {
    setLoading(true);
    if (userRed?.token) {
      getUserInfo();
    }
  }, [userRed]);

  // =============================
  // functions
  // =============================

  const getUserInfo = () => {
    setLoading(true);
    singleUserInfoAPI(userId, userRed?.token)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        if (res.data.success) {
          setUser(res.data.user);
        } else {
          message.warning(
            "Some problem encountered in fetching the details of the user."
          );
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };

  return (
    <DesktopLayout>
      <UserProfileWrapper
        selectedAction={selectedAction}
        divWidth1={divWidth1}
        divWidth2={divWidth2}
        divWidth3={divWidth3}
      >
        {/* ============================================================ */}

        {/* profile header */}
        <div className="profileHeaderMain flexBetween">
          <Skeleton loading={loading} avatar active paragraph={1}>
            <div className="userNameAndAvatar">
              <Avatar
                sx={{ width: 70, height: 70, borderRadius: 4, marginRight: 3 }}
                variant="rounded"
                src={user?.profilePicture?.url}
                alt={user?.name}
                className="avatar"
              />
              <div>
                <CardHeading
                  transform="capitalize"
                  title={user?.firstName + " " + user?.lastName}
                />
                <Typography className="subText">
                  <span
                    style={{
                      color:
                        user?.isVerified === true
                          ? Colors.textGreen
                          : Colors.alertRed,
                    }}
                  >
                    {" "}
                    {user?.isVerified ? "verified" : "Unverified"}
                  </span>{" "}
                  ||{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {user?.role}
                  </span>
                </Typography>
              </div>
            </div>
          </Skeleton>

          {/* ============================================================ */}
          {/* actions */}
          <Segmented
            onChange={setSelectedAction}
            options={[
              {
                value: "1",
                label: (
                  <div
                    className="flexCenter"
                    style={{
                      padding: 4,
                    }}
                  >
                    <Icons.AppIcon style={{ marginRight: 8 }} /> App
                  </div>
                ),
              },
              {
                value: "2",
                label: (
                  <div
                    className="flexCenter"
                    style={{
                      padding: 4,
                    }}
                  >
                    <Icons.AppIcon style={{ marginRight: 8 }} /> Settings
                  </div>
                ),
              },
            ]}
          />
        </div>
        {/* ============================================================ */}
        {/* page start */}
        {selectedAction === "1" ? (
          // all the below are when 'App' tab is selected
          <>
            {/* The three initial cards stating the Settings user have in their mobile,
           profile basic info, and any chat they had with admin */}
            <TableWrapper gutter={[16, 24]} className="mt3 mb3">
              <Col sx={24} md={12} lg={8} style={{ display: "flex" }}>
                <UserSettings loading={loading} />
              </Col>
              <Col sx={24} md={12} lg={8} style={{ display: "flex" }}>
                <ProfileInfo user={user} loading={loading} />
              </Col>
              <Col sx={24} md={24} lg={8} style={{ display: "flex" }}>
                <Messages user={user} loading={loading} />
              </Col>
            </TableWrapper>
            {/* monitory and other statistic */}
            <HomeWrapper className="flexBetween mb3">
              <CountInfoCard
                title="Total Impressions"
                toolTipMessage="Total number of people who've viewed the profile"
                Icon={Icons.VisibilityIcon}
                amount="251"
                margin="gain"
                marginValue="55"
                compareTo="yesterday"
                loading={loading}
              />
              <CountInfoCard
                title="User Activity"
                toolTipMessage="Average time spent by user on the app (minutes)"
                Icon={Icons.AlarmClock}
                amount="21"
                margin="loss"
                marginValue="23"
                compareTo="last week"
                loading={loading}
              />
              <CountInfoCard
                title="Total Earnings"
                toolTipMessage="Money earned by this user"
                Icon={Icons.RupeeIcon}
                amount="25000"
                margin="gain"
                marginValue="18"
                compareTo="last month"
                loading={loading}
              />
              <CountInfoCard
                title="Wallet Balance"
                toolTipMessage="Amount available in wallet that need to be dispatched"
                Icon={Icons.WalletIcon}
                amount="₹ 1200"
                margin="loss"
                marginValue="34"
                compareTo="yesterday"
                loading={loading}
              />
            </HomeWrapper>
            {/* last div :- campaigns that this influencer has participated */}
            <ParticipatedCampaigns>
              <div
                className="mb2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "1rem",
                }}
              >
                <Typography className="heading">Campaigns</Typography>
                <Typography className="subHeading">
                  Take a look to the campaigns{" "}
                  <span style={{ fontStyle: "italic" }}>
                    {user?.firstName + " " + user?.lastName}
                  </span>{" "}
                  has been a part of
                </Typography>
              </div>
              {/* 4 columns */}
              <Row>
                {/* {JSON.stringify(campaigns, null, 4)} */}
                {campaigns?.slice(0, 3).map((item, i) => {
                  return (
                    <Col xs={24} md={12} lg={6} className="columnClass" key={i}>
                      <UserCampaign campaign={item} count={i + 1} />
                    </Col>
                  );
                })}
                <Col xs={24} md={12} lg={6} className="columnClass">
                  <ViewAllCampaignByUser />
                </Col>
              </Row>
            </ParticipatedCampaigns>
          </>
        ) : (
          <Row className="settingsByAdmin mt3">
            <Col span={24}>
              <UserSettingsByAdmin />
            </Col>
            <Col span={24}>
              <UserInstaDetail thisUser={user} />
            </Col>
          </Row>
        )}
      </UserProfileWrapper>
    </DesktopLayout>
  );
};

export default UserProfile;
