import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

export const AllUsersCardWrapper = styled.div`
  padding: 1.5rem;
  border-radius: ${BorderRadius.mediumRadius};
  background-color: ${Colors.black40};
  min-height: 8rem;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  .iconContainer {
    border-radius: ${BorderRadius.smallRadius};
    background-color: ${Colors.white};
    height: 2.7rem;
    width: 2.7rem;
  }
  .moreIcon {
    transform: rotate(90deg);
    color: ${Colors.white};
    cursor: pointer;
  }
  .amount {
    color: ${Colors.white};
    font-weight: bold;
    font-size: 1.5rem;
    flex: 1;
    font-family: ${FontType.openSans}, sans-serif;
  }
  .title {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 1rem;
    color: ${Colors.white};
    font-weight: 400;
  }
  .percentage {
    font-weight: 600;
  }
`;
