import { createSlice } from "@reduxjs/toolkit";

export const sideNavExpansionSlice = createSlice({
  name: "sideNavExpansion",
  initialState: {
    isSideNavExpanded: false,
    motionType: "",
  },
  reducers: {
    setSideNavExpanded: (state, action) => {
      state.isSideNavExpanded = action.payload.isSideNavExpanded;
      state.motionType = action.payload.motionType;
    },
  },
});

export const { setSideNavExpanded } = sideNavExpansionSlice.actions;
export default sideNavExpansionSlice.reducer;
