import { Typography } from "@mui/material";
import React from "react";
import { Colors } from "../../config/Colors";
import styled from "styled-components";

const CardHeading = ({ title, weight, transform }) => {
  return (
    <HeadingWrapper variant="h6" weight={weight} textTransform={transform}>
      {title}
    </HeadingWrapper>
  );
};

export default CardHeading;

const HeadingWrapper = styled(Typography)`
  color: ${Colors.textBlack};
  font-family: "Open sans", sans-serif !important;
  font-weight: ${(prop) => (prop?.weight ? prop.weight : "600")} !important;
  letter-spacing: 1px !important;
`;
