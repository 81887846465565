import axios from "axios";

export const createUserAPI = async (values, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ADMIN}/create-or-update-user`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const editUserProfileAPI = async (
  values,
  userProfileId,
  userId,
  authtoken
) => {
  console.log("USER INFO AUTHTOKEN==>", authtoken);
  return await axios.patch(
    `${process.env.REACT_APP_API_ADMIN}/update-user-profile?userId=${userId}&userProfileId=${userProfileId}`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getAllUsersAPI = async (values, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/users?page=${values.page}&limit=${values?.limit}&filterType=${values?.filterType}&filterValue=${values?.filterValue}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const singleUserInfoAPI = async (userId, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/profile?userId=${userId}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const verifyUserAPI = async (values, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ADMIN}/verify-user`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getMonitorDataAPI = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API_ADMIN}/monitor-data`, {
    headers: {
      authorization: authtoken,
    },
  });
};

export const getTeamMembersAPI = async (authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/admins-and-editors`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};
export const getInstaDataAPI = async (campaignId, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/insta-data?campaignId=${campaignId}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};
