import React from "react";
import { Input, Modal, Switch } from "antd";
import ClockPicker from "../picker/ClockPicker";
import { useState } from "react";
import { InputLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addTodoAsync } from "../../redux/todoSlice";

const AddTodoModal = ({ setIsModalOpen, isModalOpen, user }) => {
  const dispatch = useDispatch();

  const todos = useSelector((state) => state.user);
  // useState
  const [todo, setTodo] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [time, setTime] = useState(null);
  // functions
  const handleOk = () => {
    let isPrivate = !isPublic;

    const values = {
      description: todo,
      isPrivate,
      time,
      token: user?.token,
    };
    dispatch(addTodoAsync(values));
    setIsModalOpen(false);
    setTodo("");
    setTime(null);
    setIsPublic(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChangeText = (e) => {
    setTodo(e.target.value);
  };

  const onSwitchChange = (checked) => {
    setIsPublic(checked);
    console.log(`switch to ${checked}`);
  };
  // jsx
  return (
    <Modal
      title="Create Your ToDo"
      open={isModalOpen}
      onOk={handleOk}
      okText="Create"
      onCancel={handleCancel}
    >
      <div className="mt3">
        <div className="flexBetween">
          <div style={{ width: "48%" }}>
            <InputLabel>Todo</InputLabel>
            <Input
              value={todo}
              placeholder="My Todo"
              onChange={handleChangeText}
              className="mb1"
              maxLength={60}
              showCount
            />
          </div>
          <div
            style={{
              width: "48%",
              paddingBottom: 14,
            }}
          >
            <InputLabel>Private / Public</InputLabel>
            <Switch
              checked={isPublic}
              checkedChildren="Public"
              unCheckedChildren="Private"
              onChange={onSwitchChange}
            />
          </div>
        </div>
        <ClockPicker time={time} setTime={setTime} />
      </div>
    </Modal>
  );
};

export default AddTodoModal;
