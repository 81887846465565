import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Checkbox, Col, DatePicker, Input, Row, Select } from "antd";
import { StepOneSchema } from "../../../../config/Schemas";
import { StepOneWrapper } from "./Style";
import { ImageList } from "../../../../config/ImageList";
import moment from "moment";
import { Button, IconButton } from "@mui/material";
import { DontsList, DosList } from "../../../../config/DummyData";
import { Icons } from "../../../../config/IconsList";
import { Colors } from "../../../../config/Colors";
import { useDispatch, useSelector } from "react-redux";
import { changeStepCount } from "../../../../redux/stepSlice";
import { LocalStorageIdentifier } from "../../../../config/LocalStorageInfo";
import {
  setCampaignName,
  setStartDate,
  setEndDate,
  setCampaignPlatform,
  setDeliverableType,
  setDos,
  setDonts,
  setIsDosChecked,
  setIsDontsChecked,
} from "../../../../redux/createCampaignSlice";
const { Option } = Select;

const StepOneForm = () => {
  const dispatch = useDispatch();
  const stepper = useSelector((state) => state.stepper);
  const createCampaign = useSelector((state) => state.createCampaign);
  // ===========================************==================================
  // ===========================initial values=====================================
  // ===========================*************=================================
  let initialValues = {
    campaignName: "",
    campaignPeriod: {
      startDate: "",
      endDate: "",
    },
    campaignPlatform: {
      platformName: "",
      platformLogo: "",
    },
    deliverableType: [],
    dos: [],
    donts: [],
    isDosChecked: true,
    isDontsChecked: true,
  };
  // ===========================************==================================
  // ===========================useState=====================================
  // ===========================*************=================================
  const [myOptions, setMyOptions] = useState([]);
  const [instructionText, setInstructionText] = useState("");
  const [preFilledData, setPreFilledData] = useState(initialValues);
  const [instructionTextDont, setInstructionTextDont] = useState("");
  let myValue =
    localStorage.getItem(LocalStorageIdentifier.createCampaignData) !== "";
  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: preFilledData,
    validationSchema: StepOneSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      console.log("submit");
    },
  });
  // ===========================************==================================
  // ===========================useEffect=====================================
  // ===========================*************=================================
  useEffect(() => {
    let savedDataOfStepOne = localStorage.getItem(
      LocalStorageIdentifier.stepOneData
    );
    if (savedDataOfStepOne !== "") {
      setPreFilledData(JSON.parse(savedDataOfStepOne));
    } else {
      setPreFilledData(initialValues);
      console.log("LOCAL STORAGE NOT FOUND");
    }
  }, [stepper.currentStep]);
  // vary deliverables options based on selected platform
  useEffect(() => {
    let MyOptions =
      values?.campaignPlatform.platformName === "instagram"
        ? [
            { label: "Reels", value: "instaReel" },
            { label: "Post", value: "instagramPost" },
            { label: "Story", value: "instagramStory" },
          ]
        : values?.campaignPlatform.platformName === "facebook"
        ? [{ label: "Post", value: "facebookPost" }]
        : [
            { label: "Shorts", value: "youtubeShorts" },
            { label: "Video", value: "youTubeVideo" },
          ];
    setMyOptions(MyOptions);
    // every time the socials value change, set the deliverables back to initial value
    handleChange({
      target: { name: "deliverableType", value: [] },
    });
  }, [values?.campaignPlatform.platformName]);
  // every time start date change, set the end date back to initial value
  useEffect(() => {
    handleChange({
      target: { name: "campaignPeriod.endDate", value: "" },
    });
  }, [values?.campaignPeriod?.startDate]);
  // setvalues?.dos to dosList when page loads
  useEffect(() => {
    if (createCampaign?.isDontsChecked) {
      handleChange({
        target: { name: "donts", value: DontsList },
      });
      dispatch(
        setDonts({
          donts: DontsList,
        })
      );
    }
    if (createCampaign?.isDosChecked) {
      handleChange({
        target: { name: "dos", value: DosList },
      });
      dispatch(
        setDos({
          dos: DosList,
        })
      );
    }
  }, [createCampaign?.isDontsChecked, createCampaign?.isDosChecked]);

  // ===========================************==================================
  // ===========================functions=====================================
  // ===========================*************=================================

  // handle change of inputs
  // since the data need to be updated for various form such as object and array
  // therefore we need different handles
  // normal input boxes are handled by useFormic itself
  const handleDosChange = () => {
    handleChange({
      target: { name: "dos", value: [instructionText, ...values?.dos] },
    });
    dispatch(
      setDos({
        dos: [instructionText, ...createCampaign?.dos],
      })
    );
    setInstructionText("");
  };

  const handleDontsChange = () => {
    handleChange({
      target: { name: "donts", value: [instructionTextDont, ...values?.donts] },
    });
    dispatch(
      setDonts({
        donts: [instructionTextDont, ...createCampaign?.donts],
      })
    );
    setInstructionTextDont("");
  };
  const onStartDateChange = (date, dateString) => {
    console.log("MY DATE", date, "DATE STRING==>>", dateString);
    handleChange({ target: { name: "campaignPeriod.startDate", value: date } });
    dispatch(setStartDate({ startDate: date }));
  };
  const onEndDateChange = (date, dateString) => {
    console.log("MY DATE", date, "DATE STRING==>>", dateString);
    handleChange({
      target: { name: "campaignPeriod.endDate", value: date },
    });
    dispatch(setEndDate({ endDate: date }));
  };
  // when any social platform is selected, based on its name,
  // its logo is selected and pushed in an object and then stored in platform schema
  const handleSocialChange = (e) => {
    let imageUrl =
      e === "instagram"
        ? ImageList.InstagramImageUrl
        : e === "facebook"
        ? ImageList.FacebookImageUrl
        : ImageList.YouTubeImageUrl;
    let myValue = {
      platformName: e,
      platformLogo: imageUrl,
    };
    handleChange({ target: { name: "campaignPlatform", value: myValue } });
    dispatch(
      setCampaignPlatform({
        ...myValue,
      })
    );
  };
  const handleDeliverableChange = (e) => {
    handleChange({ target: { name: "deliverableType", value: e } });
    dispatch(setDeliverableType({ deliverableType: e }));
  };
  // disable dates which are before the startDate
  const disableMydDate = (current) => {
    const specificDate = moment(
      createCampaign?.campaignPeriod?.startDate?.toString()
    );
    return current && current < specificDate;
  };
  // make the default checkbox toggle
  const onChangeDosDefault = (e) => {
    console.log(`checked = ${e.target.checked}`);
    handleChange({
      target: {
        name: "isDosChecked",
        value: e.target.checked,
      },
    });
    dispatch(
      setIsDosChecked({
        isDosChecked: e.target.checked,
      })
    );
  };
  const onChangeDontsDefault = (e) => {
    console.log(`checked = ${e.target.checked}`);
    handleChange({
      target: {
        name: "isDontsChecked",
        value: e.target.checked,
      },
    });
    dispatch(
      setIsDontsChecked({
        isDontsChecked: e.target.checked,
      })
    );
  };
  // do's and don'ts text change
  const handleInstructionTextChange = (e) => {
    if (e.target.name === "dos") {
      setInstructionText(e.target.value);
    } else {
      setInstructionTextDont(e.target.value);
    }
  };
  // handle delete of a do's
  const handleDeleteDos = (stringToDelete) => {
    const updatedStrings = values?.dos.filter(
      (string) => string !== stringToDelete
    );
    handleChange({
      target: {
        name: "dos",
        value: updatedStrings,
      },
    });
    dispatch(
      setDos({
        dos: updatedStrings,
      })
    );
  };
  // handle delete of a don'ts
  const handleDeleteDonts = (stringToDelete) => {
    const updatedStrings = values?.donts.filter(
      (string) => string !== stringToDelete
    );
    handleChange({
      target: {
        name: "donts",
        value: updatedStrings,
      },
    });
    dispatch(
      setDonts({
        donts: updatedStrings,
      })
    );
  };
  // function runs when Next button is clicked
  const handleNextButton = (e) => {
    e.preventDefault();
    handleSubmit();
  };
  // function runs when Next button is clicked
  const handlePrevButton = (e) => {
    e.preventDefault();
    // store all the data of this particular step under a different name
    localStorage.setItem(
      LocalStorageIdentifier.stepOneData,
      JSON.stringify(values)
    );
    // save in redux store
    dispatch(
      changeStepCount({
        data: "abc",
        currentStep: stepper?.currentStep - 1,
      })
    );
  };

  // ===========================************==================================
  // ================================jsx=====================================
  // ===========================*************=================================
  return (
    <StepOneWrapper className="mtp5">
      {/* {JSON.stringify(values.deliverableType, null, 4)} */}
      <form>
        {/* campaign name input box */}
        <div className="inputContainer">
          <label htmlFor="campaignName">Campaign Name</label>
          <Input
            status={
              touched.campaignName && errors.campaignName ? "error" : null
            }
            allowClear
            showCount
            maxLength={65}
            name="campaignName"
            id="campaignName"
            placeholder="Campaign Name"
            value={createCampaign?.campaignName}
            onChange={(e) =>
              dispatch(setCampaignName({ campaignName: e.target.value }))
            }
          />
        </div>

        {/* starting date and end date for campaign && social platform and deliverables */}
        <Row gutter={[8, 8]}>
          {/* start date and end date */}
          <Col xs={24} md={12} lg={12} style={{ display: "flex" }}>
            <div className="flexBetween inputContainer">
              {/* start date */}
              <div className="dateContainer">
                <label htmlFor="startDate">Start Date</label>
                <DatePicker
                  status={
                    touched.campaignPeriod?.startDate &&
                    errors.campaignPeriod?.startDate
                      ? "error"
                      : null
                  }
                  disabledDate={(current) =>
                    current && current < moment().startOf("day")
                  }
                  style={{ width: "100%" }}
                  id="startDate"
                  onChange={onStartDateChange}
                  size="large"
                  name="campaignPeriod.startDate"
                  suffixIcon={
                    <img
                      src={ImageList.CalenderImageIcon}
                      className="calenderIcon"
                      alt="calender"
                    />
                  }
                />
              </div>
              {/* separator dash */}
              <div className="dateSeparator">-</div>
              {/* end date */}
              <div className="dateContainer">
                <label htmlFor="endDate">End Date</label>
                <DatePicker
                  status={
                    touched.campaignPeriod?.endDate &&
                    errors.campaignPeriod?.endDate
                      ? "error"
                      : null
                  }
                  disabled={
                    createCampaign?.campaignPeriod?.startDate === "" ||
                    createCampaign?.campaignPeriod?.startDate === null
                  }
                  disabledDate={disableMydDate}
                  style={{ width: "100%" }}
                  id="endDate"
                  name="campaignPeriod.endDate"
                  onChange={onEndDateChange}
                  size="large"
                  suffixIcon={
                    <img
                      src={ImageList.CalenderImageIcon}
                      className="calenderIcon"
                      alt="calender"
                    />
                  }
                />
              </div>
            </div>
          </Col>
          {/* socials and deliverables */}
          <Col xs={24} md={12} lg={12} style={{ display: "flex" }}>
            <div className="flexBetween inputContainer">
              {/*select single social platform */}
              <div className="dateContainer">
                <label htmlFor="socials">Socials</label>
                <Select
                  value={
                    createCampaign?.campaignPlatform?.platformName !== "" &&
                    createCampaign?.campaignPlatform?.platformName
                  }
                  status={
                    touched.campaignPlatform?.platformName &&
                    errors.campaignPlatform?.platformName
                      ? "error"
                      : null
                  }
                  removeIcon
                  id="socials"
                  placeholder="Select"
                  size="middle"
                  name="campaignPlatform"
                  onChange={handleSocialChange}
                  style={{ width: "100%" }}
                >
                  <Option value="instagram">Instagram</Option>
                  <Option value="youtube">YouTube</Option>
                  <Option value="facebook">Facebook</Option>
                </Select>
              </div>
              {/*select multiple deliverables */}
              <div className="dateContainer">
                <label htmlFor="deliverables">Deliverable</label>
                <Select
                  value={createCampaign?.deliverableType}
                  status={
                    touched.deliverableType && errors.deliverableType
                      ? "error"
                      : null
                  }
                  disabled={
                    createCampaign?.campaignPlatform?.platformName === ""
                  }
                  id="deliverables"
                  mode="multiple"
                  maxTagCount="responsive"
                  name="deliverableType"
                  placeholder="Deliverable Type"
                  onChange={handleDeliverableChange}
                  className="inputBox "
                  style={{ width: "100%" }}
                >
                  {myOptions?.map((item, i) => {
                    return (
                      <Option key={i} value={item.value}>
                        {item.label}{" "}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
        {/* do's and don'ts row and column */}
        <Row gutter={[8, 24]}>
          {/* DO'S */}
          <Col
            xs={24}
            md={12}
            lg={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="flexBetween">
              <label htmlFor="dos">Do's</label>
              <div className="flexCenter">
                <Checkbox
                  checked={createCampaign?.isDosChecked}
                  id="defaultInstruction"
                  onChange={onChangeDosDefault}
                />
                <label htmlFor="defaultInstruction">Default</label>
              </div>
            </div>
            <div className="instructionContainer">
              {createCampaign?.isDosChecked ? (
                <ul>
                  {createCampaign?.dos?.map((item, i) => {
                    return <li key={i}>{item} </li>;
                  })}
                </ul>
              ) : (
                <div>
                  <Input
                    name="dos"
                    autoFocus
                    bordered={false}
                    placeholder="Write a few points (press enter to add)"
                    onChange={handleInstructionTextChange}
                    onPressEnter={handleDosChange}
                    value={instructionText}
                  />
                  <ul>
                    {createCampaign?.dos?.map((item, i) => {
                      return (
                        <div className="flexBetween" key={i}>
                          <li>{item}</li>
                          <IconButton onClick={() => handleDeleteDos(item)}>
                            <Icons.DeleteIcon
                              style={{
                                fontSize: 15,
                                color: Colors.lightPurple,
                              }}
                            />
                          </IconButton>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </Col>
          {/* DON'TS */}
          <Col
            xs={24}
            md={12}
            lg={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="flexBetween">
              <label htmlFor="donts">Don'ts</label>
              <div className="flexCenter">
                <Checkbox
                  checked={createCampaign?.isDontsChecked}
                  id="defaultInstructionDont"
                  onChange={onChangeDontsDefault}
                />
                <label htmlFor="defaultInstructionDont">Default</label>
              </div>
            </div>
            <div className="instructionContainer">
              {createCampaign?.isDontsChecked ? (
                <ul>
                  {createCampaign?.donts?.map((item, i) => {
                    return <li key={i}>{item}</li>;
                  })}
                </ul>
              ) : (
                <div>
                  <Input
                    name="donts"
                    autoFocus
                    bordered={false}
                    placeholder="Write a few points (press enter to add)"
                    onChange={handleInstructionTextChange}
                    onPressEnter={handleDontsChange}
                    value={instructionTextDont}
                  />
                  <ul>
                    {createCampaign?.donts?.map((item, i) => {
                      return (
                        <div className="flexBetween" key={i}>
                          <li>{item}</li>
                          <IconButton onClick={() => handleDeleteDonts(item)}>
                            <Icons.DeleteIcon
                              style={{
                                fontSize: 15,
                                color: Colors.lightPurple,
                              }}
                            />
                          </IconButton>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </form>
    </StepOneWrapper>
  );
};

export default StepOneForm;
