import { Icons } from "./IconsList";

export const NavOptions = [
  {
    title: "Dashboard",
    icon: Icons.StorefrontOutlinedIcon,
    linkTo: "/",
    folderName: "Home",
  },

  // {
  //   title: "User Profile",
  //   icon: Icons.ProfileIcon,
  //   linkTo: "/user/:userId",
  //   folderName: "Profile",
  // },
  {
    title: "Settings",
    icon: Icons.SettingsOutlinedIcon,
    linkTo: "/settings",
    folderName: "Pages",
  },

  {
    title: "Create Campaign",
    icon: Icons.AddIcon,
    linkTo: "/create/campaign",
    folderName: "Campaign",
  },
  {
    title: "All Campaigns",
    icon: Icons.CampaignIcon,
    linkTo: "/all/campaigns",
    folderName: "Campaign",
  },
  {
    title: "Unverified Campaigns",
    icon: Icons.UnderVerificationIcon,
    linkTo: "/all/unverified/campaigns",
    folderName: "Campaign",
  },
  {
    title: "Chats",
    icon: Icons.ChatIcon,
    linkTo: "/chats",
    folderName: "Chats",
  },
];
