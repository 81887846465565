import React from "react";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { Button } from "antd";
import { ImageList } from "../../../config/ImageList";

const MessageText = ({ currentUserId, message }) => {
  let isMe = message.senderId === currentUserId;
  return (
    <TextWrapper
      isMe={isMe}
      className="flexBetween"
      isUploader={message?.uploader?.isRequired}
      isDemo={
        message?.uploader?.isRequired &&
        message.uploader?.status &&
        !message.uploader?.isApproved
      }
      isFinal={
        message?.uploader?.isRequired &&
        message.uploader?.status &&
        message.uploader?.isApproved
      }
    >
      {message?.uploader?.isRequired && message.uploader?.status ? (
        <div
          className="flexCenter"
          style={{
            color: Colors.alertRed,
            backgroundColor: Colors.black30,
          }}
        >
          Check campaign page for links
        </div>
      ) : message?.uploader?.isRequired && !message.uploader?.status ? (
        <img src={ImageList.Uploader_Image_Option} alt="upload" />
      ) : (
        <> {message?.text}</>
      )}
    </TextWrapper>
  );
};

export default MessageText;

// styling
const TextWrapper = styled.div`
  width: fit-content;
  max-width: 80%;
  min-height: 2rem;
  padding: 0.7rem;
  max-height: fit-content;
  position: relative;
  border-radius: ${(prop) =>
    prop.isMe ? "10px 0px 10px 10px" : "0px 10px 10px 10px"};
  margin-top: 1rem;
  color: ${(prop) => prop.isMe && Colors.white};
  background: ${(prop) =>
    prop.isDemo
      ? "transparent"
      : prop.isFinal
      ? "transparent"
      : prop.isUploader
      ? "transparent"
      : prop.isMe
      ? Colors.lightPurple
      : Colors.messageBg};
  align-self: ${(prop) => prop.isMe && "flex-end"};
  line-height: 24px;
  .uploader {
    background: ${Colors.greyC9} !important;
    width: 8rem;
    height: 10rem;
    border: 1px solid grey;
  }
`;
