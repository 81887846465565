import { createSlice } from "@reduxjs/toolkit";

export const searchTypeSlice = createSlice({
  name: "search-type",
  initialState: {
    searchType: "",
    searchValue: "",
  },
  reducers: {
    changeSearchParameters: (state, action) => {
      state.searchType = action.payload.searchType;
      state.searchValue = action.payload.searchValue;
    },
  },
});

export const { changeSearchParameters } = searchTypeSlice.actions;
export default searchTypeSlice.reducer;
