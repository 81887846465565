import { Button, Typography } from "@mui/material";
import { Col, message, Row, Segmented } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImageAndRoleCard from "../../../components/cards/CreateUser/ImageAndRole";
import PreviewProfile from "../../../components/cards/CreateUser/Preview";
import RoleBasedInfo from "../../../components/cards/CreateUser/RoleBasedInfo";
import CreateUserInfoCard from "../../../components/cards/CreateUser/UserInfo";
import CreateUserByExcel from "../../../components/upload/excelUpload/CreateUserByExcel";
import { AboutText } from "../../../config/DummyData";
import { capitalizeFirstLetter } from "../../../config/Function";
import { CreateUserSchema } from "../../../config/Schemas";
import { createUserAPI } from "../../../functions/user";
import DesktopLayout from "../../../theme/DesktopLayout";
import useViewport from "../../../viewport/useViewport";
import { CreateUserWrapper } from "./CreateUserStyle";
const initialValues = {
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  role: "influencer",
  DOB: "",
  categories: [],
  profilePicture: {
    reference: "default_profile_picture",
    url: "https://res.cloudinary.com/axbordev/image/upload/v1678789382/default_profile_image_bo61ub.webp",
    isDefault: true,
  },
  about: "",
  tagLine: "",
  headQuarters: "",
  linkedIn: "",
  gender: "",
  age: 14,
  state: "",
  city: "",
  availability: "",
  barterAvailability: true,
  budget: {
    min: 0,
    max: 10000,
  },
  websiteUrl: "",
  instagramProfile: "",
  instagramFollowing: 0,
  instagramFollowers: 0,
  instagramDP: "",
  isCelebrity: false,
};
const CreateUser = () => {
  const { isMobile, isTablet } = useViewport();
  const user = useSelector((state) => state.user);

  // state
  const [segmentValue, setSegmentValue] = useState("singleUpload");
  const [segmentRoleValue, setSegmentRoleValue] = useState("influencer");

  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: CreateUserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("CREATE NEW USER VALUES==>", values);
      createUserAPI(values, user?.token)
        .then((res) => {
          message.success("User Created Successfully!");
          console.log("USER CREATED", res.data);
          window.location.reload();
          // reset the form
          resetForm();
        })
        .catch((err) => {
          console.log(err);
          message.error("Some error occured while creating new user.");
        });
    },
  });
  useEffect(() => {
    const about =
      values?.role === "brand" ? AboutText?.brand : AboutText?.influencer;

    handleChange({ target: { name: "lastName", value: "" } });
    handleChange({ target: { name: "about", value: about } });
    handleChange({ target: { name: "DOB", value: "" } });
    handleChange({ target: { name: "categories", value: [] } });
    handleChange({ target: { name: "tagLine", value: "" } });
    handleChange({ target: { name: "headQuarters", value: "" } });
    handleChange({ target: { name: "linkedIn", value: "" } });
    handleChange({ target: { name: "gender", value: "" } });
    handleChange({ target: { name: "age", value: initialValues?.age } });
    handleChange({ target: { name: "state", value: "" } });
    handleChange({ target: { name: "city", value: "" } });
    handleChange({ target: { name: "availability", value: "" } });
    handleChange({ target: { name: "barterAvailability", value: true } });
    handleChange({ target: { name: "budget", value: initialValues?.budget } });
    handleChange({
      target: {
        name: "instagramProfile",
        value: initialValues?.instagramProfile,
      },
    });
  }, [values?.role]);

  return (
    <DesktopLayout>
      <CreateUserWrapper
        className="mt1"
        isMobile={isMobile}
        isTablet={isTablet}
      >
        {/* heading and save button */}
        <div className="flexBetween">
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex" }}>
              <Typography className="font create_user_heading">
                {segmentValue === "singleUpload"
                  ? "Create new user below"
                  : `Upload ${capitalizeFirstLetter(
                      segmentRoleValue
                    )} Excel Sheet`}
              </Typography>
              <Segmented
                onChange={setSegmentValue}
                options={[
                  {
                    label: "Single Upload",
                    value: "singleUpload",
                  },
                  {
                    label: "Upload Excel",
                    value: "uploadExcel",
                  },
                ]}
                style={{
                  marginLeft: 10,
                  borderRadius: 10,
                  background: "transparent",
                  minWidth: "4rem",
                  width: "25rem",
                  maxWidth: "28rem",
                  alignSelf: "center",
                }}
                block={true}
              />
            </div>
            <Typography className="font create_user_subheading mtp4 mb2">
              Welcome to the user registration form! Please select whether you
              are creating an influencer or a brand user account.
            </Typography>
          </div>
          {segmentValue === "singleUpload" && (
            <Button
              variant="outlined"
              className="save_button"
              onClick={handleSubmit}
            >
              Save
            </Button>
          )}
        </div>
        {/* {JSON.stringify(values, null, 4)} */}
        {segmentValue === "singleUpload" ? (
          <>
            {/* 1st row */}
            <Row gutter={[24, 24]}>
              <Col xs={24} md={8} lg={8} style={{ display: "flex" }}>
                <ImageAndRoleCard
                  values={values}
                  handleChange={handleChange}
                  initialValues={initialValues}
                />
              </Col>
              <Col xs={24} md={16} lg={16} style={{ display: "flex" }}>
                <CreateUserInfoCard
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>
            {/* 2nd row */}
            <Row gutter={[24, 24]} style={{ marginTop: "1.5rem" }}>
              <Col xs={24} md={16} lg={16} style={{ display: "flex" }}>
                <RoleBasedInfo
                  values={values}
                  handleChange={handleChange}
                  initialValues={initialValues}
                  errors={errors}
                  touched={touched}
                />
              </Col>
              <Col xs={24} md={8} lg={8} style={{ display: "flex" }}>
                <PreviewProfile
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <CreateUserByExcel
              setSegmentRoleValue={setSegmentRoleValue}
              segmentRoleValue={segmentRoleValue}
            />
          </>
        )}
      </CreateUserWrapper>
    </DesktopLayout>
  );
};

export default CreateUser;
