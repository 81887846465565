import { Typography, Button, Divider } from "@mui/material";
import { Avatar, Empty, message } from "antd";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../config/Colors";
import { formatRelativeDate } from "../../../config/Function";
import { Icons } from "../../../config/IconsList";
import { FontType } from "../../../config/Values";
import {
  getCampaignAPI,
  getLatestCampaignAPI,
} from "../../../functions/campaign";
import useViewport from "../../../viewport/useViewport";
import ViewAllButton from "../../Button/ViewAllButton";
import CampaignDescription from "../../description/CampaignDescription";
import FadingDivider from "../../dividers/FadingDivider";
import CardHeading from "../../headings/CardHeading";
import NormalText from "../../headings/NormalText";
import { RecentCampaignWrapper } from "./RecentCampaignStyle";

const RecentCampaignCard = ({ user }) => {
  const { isTablet } = useViewport();
  const [campaignData, setCampaignData] = useState({});

  useEffect(() => {
    if (user?.token !== "") {
      getCampaign();
    }
  }, [user]);
  // TODO: get these campaign in most recent order
  const getCampaign = () => {
    const myValues = {
      page: 1,
      limit: 1,
      brandId: user?._id,
    };
    getCampaignAPI(myValues, user?.token)
      .then((res) => {
        console.log("LATEST CAMPAIGN=>", res.data);
        setCampaignData(res.data.campaigns[0]);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };

  const IconValueInfo = (IconValue, amount) => {
    return (
      <div className="flexBetween" style={{ marginRight: isTablet ? 6 : 21 }}>
        <IconValue style={{ color: Colors.lightGrey }} />

        <Typography
          variant="caption"
          style={{ marginLeft: isTablet ? 2 : 8, color: Colors.lightGrey }}
        >
          {" "}
          {amount}{" "}
        </Typography>
      </div>
    );
  };

  return (
    <RecentCampaignWrapper>
      {campaignData === null || !campaignData ? (
        <div
          className="flexCenter"
          style={{ height: "100%", flexDirection: "column" }}
        >
          <CardHeading title="No campaign found!" />
          <Empty />
        </div>
      ) : (
        <>
          {" "}
          {/* header with logo brand name and timing and edit button */}
          <div className="flexBetween headerBox">
            <Avatar
              shape="rounded"
              src={campaignData?.brand?.profilePicture?.url}
              alt="logo"
              className="brandLogo"
            />
            <div className="flexBetween basicInfo">
              <div className="nameAndTime">
                <Typography variant="body1" className="brandName">
                  {campaignData?.brand?.firstName}{" "}
                  {campaignData?.brand?.lastName}
                </Typography>
                <Typography variant="caption" className="time">
                  {formatRelativeDate(campaignData?.createdAt)}
                </Typography>
              </div>
              <Button
                href={`/edit/${campaignData?._id}/campaign`}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                className="editButton"
                startIcon={<Icons.EditIcon />}
              >
                Edit
              </Button>
            </div>
          </div>
          {/* divider */}
          <Divider />
          {/* content */}
          <div
            className="contentContainer"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography variant="caption" className="description">
              <CampaignDescription
                campaignDetail={campaignData}
                apiCall={true}
              />
            </Typography>
            <img
              src={campaignData?.campaignBanner}
              alt="campaign banner"
              className="campaignBanner mt2"
            />
            <div className="flexBetween mt2 mb1" style={{ flexWrap: "wrap" }}>
              <div className="iconContainer">
                {IconValueInfo(
                  Icons.TotalCountIcon,
                  campaignData?.influencerRequired
                )}
                {IconValueInfo(
                  Icons.ViewIcon,
                  campaignData?.appliedInfluencer?.length
                )}
                {IconValueInfo(
                  Icons.GroupIcon,
                  campaignData?.shortlistedInfluencer?.length
                )}
              </div>
              <div>
                <Avatar.Group
                  maxCount={3}
                  maxPopoverTrigger="none"
                  maxStyle={{ overflow: "hidden" }}
                >
                  {campaignData?.appliedInfluencer?.map((user, i) => {
                    return (
                      <Avatar
                        size={isTablet && 24}
                        key={i}
                        alt={user?.firstName}
                        src={user?.profilePicture?.url}
                      />
                    );
                  })}
                </Avatar.Group>
              </div>
            </div>
            {/* fading Divider */}
            <FadingDivider />
            {/* influencer list who has applied for the campaign */}

            {campaignData?.appliedInfluencer?.slice(0, 7).map((user, i) => {
              return (
                <div key={i} className="influencerInfo mt2">
                  <Avatar src={user.imageUrl} alt={user.name} size={55} />
                  <div style={{ marginLeft: 12 }}>
                    <NormalText title={user.name} />
                    <Typography
                      variant="caption"
                      fontFamily={FontType.openSans}
                    >
                      {user.totalConnects} connects
                    </Typography>
                  </div>
                </div>
              );
            })}
            {campaignData?.appliedInfluencer?.length < 1 && (
              <div
                className="flexCenter mt2"
                style={{
                  width: "100%",
                  alignSelf: "center",
                }}
              >
                <Empty />
              </div>
            )}
            <div
              className="mt3"
              style={{ width: "100%", position: "absolute", bottom: 10 }}
            >
              <ViewAllButton url={`/campaign/${campaignData?._id}`} />
            </div>
          </div>
        </>
      )}
    </RecentCampaignWrapper>
  );
};

export default RecentCampaignCard;
