import { Typography } from "@mui/material";
import { Button, Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LocalStorageIdentifier } from "../../../../config/LocalStorageInfo";
import { getAllBrandsAPI } from "../../../../functions/brand";
import { changeStepCount } from "../../../../redux/stepSlice";
import NormalLabel from "../../../inputLabel/NormalLabel";
import { StepZeroWrapper } from "./Style";

const StepZero = () => {
  const { currentStep } = useSelector((state) => state.stepper);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const editData = localStorage.getItem(
    LocalStorageIdentifier.editStepZeroData
  );
  const isValid =
    editData === undefined ||
    !editData ||
    editData === null ||
    editData === "" ||
    editData.length === 0;
  const brand = JSON.parse(!isValid && editData);
  // state
  const [data, setData] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);
  const [searchText, setSearchText] = useState("");

  // ==============================================
  // =================useEffect====================
  // ==============================================
  useEffect(() => {
    listOfBrands();
  }, []);

  // ==============================================
  // =================functions====================
  // ==============================================

  // this stands a condition that if localstorage value is not empty string then only parse it
  const myValue =
    localStorage.getItem(LocalStorageIdentifier.editCampaignData) !== "";

  // call all the brands
  const listOfBrands = () => {
    getAllBrandsAPI(["brand"], user?.token)
      .then((res) => {
        console.log(res.data);
        setData(res.data.users);
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (newValue) => {
    setSearchText(newValue);
    const filteredUsers = data.filter((user) =>
      user.name.toLowerCase().includes(newValue.toLowerCase())
    );
    setDataToRender(filteredUsers);
  };
  // every time select is changed value of the brand Id is stored
  const handleChange = (newValue) => {
    console.log("STEP ZERO VALUE", newValue);
    const label = newValue.label;
    const value = newValue.value;
    const brandUserId = { brandUserId: newValue.value };

    const brandFound = data.filter((item) => item._id === value)[0];
    // set step zero data to localstorage
    localStorage.setItem(
      LocalStorageIdentifier.editStepZeroData,
      JSON.stringify({ label, value, url: brandFound.profilePicture.url })
    );

    // save the step zero data to localstorage editCampaignData
    const savedData =
      myValue &&
      JSON.parse(localStorage.getItem(LocalStorageIdentifier.editCampaignData));
    localStorage.setItem(
      LocalStorageIdentifier.editCampaignData,
      JSON.stringify({ ...savedData, ...brandUserId })
    );
  };
  // function runs when Next button is clicked
  const handleNextButton = () => {
    dispatch(
      changeStepCount({
        data: "abc",
        currentStep: currentStep < 4 ? currentStep + 1 : 4,
      })
    );

    console.log("NEXT");
  };

  return (
    <StepZeroWrapper className="flexCenter" style={{ width: "100%" }}>
      <Typography className="font caption mb2">
        Our platform makes it easy for your brand to find and connect with
        influencers who align with your brand values and goals.
      </Typography>
      <NormalLabel title="Brand Identifier" identifier="brandIdentifier" />
      <Select
        showSearch
        value={brand}
        placeholder="Select Brand"
        style={{
          width: "80%",
        }}
        labelInValue
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={<Empty />}
        options={(searchText === "" ? data.slice(0, 8) : dataToRender)?.map(
          (d) => ({
            value: d._id,
            label: d.name,
          })
        )}
      />

      <Button
        style={{
          width: "80%",
        }}
        fullWidth
        variant="contained"
        className="nextButton mt2"
        onClick={handleNextButton}
        disabled={brand?.value === ""}
      >
        Next
      </Button>
    </StepZeroWrapper>
  );
};

export default StepZero;
