import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../config/Values";

const TABLE_PADDING_VALUE = ".7rem";

export const AllCampaignTableWrapper = styled.div`
  height: fit-content;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius} ${BorderRadius.mediumRadius} 0 0;
  display: flex;
  overflow-x: ${(prop) => prop.isTablet && "scroll"};
  margin-top: 2rem;
  ::-webkit-scrollbar {
    display: none;
  }
  .tableParent {
    flex: 1;
    border-radius: ${BorderRadius.mediumRadius};
    border-collapse: collapse;
    margin: 1rem 0rem;
  }
  .tableHeader {
    padding: ${TABLE_PADDING_VALUE} !important;
    font-size: 0.7rem;
    color: ${Colors.tableText};
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    max-width: 22rem;
  }
  .sortIcon {
    cursor: pointer;
    font-size: 18px;
  }
  .headerContainer {
    border-bottom: 1px solid ${Colors.lightGrey};
  }
  tr {
    border-bottom: 1px solid ${Colors.lightGrey};
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  tr:first-child {
    border-top: none;
  }
  tr:last-child {
    border-bottom: none;
  }
  tr:nth-last-child(1) {
    border-bottom: none;
  }
  td {
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  .centerAlignText {
    text-align: center;
    font-size: 0.7rem;
    color: ${Colors.tableText};
    min-width: 6rem;
  }
  .tableData {
    display: flex;
    align-items: flex-start;
    color: ${Colors.textGrey};
    font-family: ${FontType.openSans}, sans-serif !important;
    text-decoration: none;
  }
  .influencerText {
    margin-left: ${(prop) => (prop.isMobile ? 0 : "0.7rem")};
  }
  .name {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${Colors.textBlack};
  }
  .brandName {
    font-size: 0.6rem;
    color: ${Colors.cardText};
    text-transform: capitalize;
    font-family: ${FontType.openSans}, sans-serif !important;
  }
  .searchContainer {
    position: relative;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    height: fit-content;
    width: 60%;
    padding-left: 1rem;
  }
  .searchIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    width: 20px;
    height: 20px;
  }

  .searchInputBox {
    width: ${(prop) => (prop.expanded ? "100%" : "40px")};
    transition: width 0.4s linear;
  }
  .iconButton {
    margin: 0 1px;
  }
  .actionIcon {
    width: 15px;
    height: 15px;
  }
`;

export const PaginationWrapper = styled.div`
  height: fit-content;
  background-color: ${Colors.white};
  border-radius: 0 0 ${BorderRadius.mediumRadius} ${BorderRadius.mediumRadius};
  padding: 2rem;
`;
