import React from "react";
import DesktopLayout from "../../theme/DesktopLayout";

const SettingScreen = () => {
  return (
    <DesktopLayout>
      <div style={{ minHeight: "100vh" }}>SettingScreen</div>
    </DesktopLayout>
  );
};

export default SettingScreen;
