import styled from "styled-components";
import { Colors } from "../../../../../config/Colors";
import { BorderRadius, FontType } from "../../../../../config/Values";
const TABLE_PADDING_VALUE = ".7rem";

export const LiveWrapper = styled.div`
  height: fit-content;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius} ${BorderRadius.mediumRadius} 0 0;
  overflow-x: ${(prop) => prop.isTablet && "scroll"};
  margin-top: 2rem;
  padding: 1.5rem;
  ::-webkit-scrollbar {
    display: none;
  }
  .tableParent {
    width: 100%;
    border-radius: ${BorderRadius.mediumRadius};
    border-collapse: collapse;
    margin: 1rem 0rem;
  }
  .tableHeader {
    font-size: 0.7rem;
    color: ${Colors.tableText};
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 1rem;
  }

  /* === */
  .sortIcon {
    cursor: pointer;
    font-size: 18px;
  }
  .headerContainer {
    border-bottom: 1px solid ${Colors.lightGrey};
  }
  tr {
    border-bottom: 1px solid ${Colors.lightGrey};
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  tr:first-child {
    border-top: none;
  }
  tr:last-child {
    border-bottom: none;
  }
  tr:nth-last-child(0) {
    border-bottom: none;
  }
  td {
    padding: ${TABLE_PADDING_VALUE} !important;
  }
  .centerAlignText {
    text-align: center;
    font-size: 0.7rem;
    color: ${Colors.tableText};
  }
  .tableData {
    display: flex;
    align-items: center;
    color: ${Colors.textGrey};
    font-family: ${FontType.openSans}, sans-serif !important;
    text-decoration: none;
  }
  .influencerText {
    margin-left: 0.7rem;
  }
  .name {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${Colors.textBlack};
  }
  .email {
    font-size: 0.6rem;
    color: ${Colors.cardText};
    text-transform: lowercase;
    font-family: ${FontType.openSans}, sans-serif !important;
  }
  .uploadedLinkLogo {
    height: 22px;
    width: 22px;
    object-fit: contain;
    object-position: center;
  }
`;
