import React from "react";
import { Col, Input, Row } from "antd";
import CardHeading from "../../../headings/CardHeading";
import NormalLabel from "../../../inputLabel/NormalLabel";
import { CreateUserInfoWrapper } from "./Style";
const { TextArea } = Input;
const CreateUserInfoCard = ({ values, handleChange, errors, touched }) => {
  return (
    <CreateUserInfoWrapper>
      {/* heading */}
      <div className="flexBetween mb2">
        <CardHeading title="Basic Information" weight="700" />
      </div>
      {/* registration form */}
      {/* first name and last name */}
      <Row gutter={[24, 24]}>
        <Col
          xs={24}
          md={values?.role === "brand" ? 24 : 12}
          lg={values?.role === "brand" ? 24 : 12}
        >
          <NormalLabel
            title={values?.role === "brand" ? "Brand Name" : "First Name"}
            identifier="firstName"
          />
          <Input
            size="large"
            status={touched.firstName && errors.firstName ? "error" : null}
            type="text"
            id="firstName"
            name="firstName"
            value={values?.firstName}
            onChange={handleChange}
          />
        </Col>
        {values?.role === "influencer" && (
          <Col xs={24} md={12} lg={12}>
            <NormalLabel title="Last Name" identifier="lastName" />
            <Input
              size="large"
              status={touched?.lastName && errors?.lastName ? "error" : null}
              type="text"
              id="lastName"
              name="lastName"
              value={values?.lastName}
              onChange={handleChange}
            />
          </Col>
        )}
      </Row>
      {/* email and phone number */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="Email" identifier="email" />
          <Input
            size="large"
            status={touched.email && errors.email ? "error" : null}
            type="email"
            id="email"
            name="email"
            value={values?.email}
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="Phone Number" identifier="phone" />
          <Input
            size="large"
            status={touched.phone && errors.phone ? "error" : null}
            type="tel"
            id="phone"
            name="phone"
            value={values?.phone}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]} className="mt1">
        <Col span={24}>
          <NormalLabel title="About" identifier="about" />
          <TextArea
            status={touched.about && errors.about ? "error" : null}
            id="about"
            name="about"
            value={values?.about}
            onChange={handleChange}
            placeholder="About"
            maxLength={120}
            showCount
            autoSize={{
              minRows: 5,
              maxRows: 5,
            }}
          />
        </Col>
      </Row>
    </CreateUserInfoWrapper>
  );
};

export default CreateUserInfoCard;
