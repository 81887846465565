import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    message: "",
    open: false,
    messageType: "",
  },
  reducers: {
    snackbarAlert: (state, action) => {
      state.message = action.payload.message;
      state.open = action.payload.open;
      state.messageType = action.payload.messageType;
    },
  },
});

export const { snackbarAlert } = snackbarSlice.actions;
export default snackbarSlice.reducer;
