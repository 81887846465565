import { Button, Chip, Typography } from "@mui/material";
import { Avatar, Empty, message, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Colors } from "../../../../../config/Colors";
import { formatDate } from "../../../../../config/Function";
import { Icons } from "../../../../../config/IconsList";
import { ImageList } from "../../../../../config/ImageList";
import { SnackbarSeverity } from "../../../../../config/Values";
import {
  getInfluencerDetailCampaignWiseAPI,
  shortlistInfluencerForCampaignAPI,
} from "../../../../../functions/campaign";
import { snackbarAlert } from "../../../../../redux/snackbarSlice";
import useViewport from "../../../../../viewport/useViewport";
import AppliedInfluencerDrawer from "../../../../drawer/campaign/AppliedInfluencer/AppliedInfluencerDrawer";
import CardHeading from "../../../../headings/CardHeading";
import { AppliedWrapper } from "./Style";

const AppliedInfluencers = ({ campaignId, all, brandId }) => {
  const { isTablet, isMidrange } = useViewport();
  const user = useSelector((state) => state.user);
  // state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [appliedInfluencers, setAppliedInfluencers] = useState([]);
  const [toatlInfluencerCount, setToatlInfluencerCount] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [centralValueToCallFunction, setCentralValueToCallFunction] =
    useState(0);
  const [refreshing, setRefreshing] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (user !== "") {
      getInfluencerList();
    }
  }, [user, page, limit, centralValueToCallFunction]);

  const getInfluencerList = () => {
    setRefreshing(true);
    const values = {
      page,
      limit,
      campaignId,
      influencerType: "applied",
    };
    getInfluencerDetailCampaignWiseAPI(values, user?.token)
      .then((res) => {
        console.log("APPLIED INFLUENCERS", res.data);
        setAppliedInfluencers(res.data.influencers);
        setToatlInfluencerCount(res.data.totalCount);
        setRefreshing(true);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
        setRefreshing(true);
      });
  };

  const dispatch = useDispatch();

  const actionForTheInfluencer = (isApproved, influencerId) => {
    const values = {
      isApproved,
      influencerId,
      campaignId,
      brandId,
    };
    shortlistInfluencerForCampaignAPI(values, user?.token)
      .then((res) => {
        console.log("success");
        getInfluencerList();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };

  // reject influencer who has applied for this campaign
  const rejectInfluencer = (isApproved, influencerId) => {
    if (window.confirm("Are you sure you want to reject this influencer?")) {
      actionForTheInfluencer(isApproved, influencerId);
      let randomNumber = Math.floor(Math.random() * Number.MAX_VALUE) + 1;
      setCentralValueToCallFunction(randomNumber);
    }
  };
  // shortlist the influencer for the campaign
  const shortListTheInfluencer = (isApproved, influencerId) => {
    actionForTheInfluencer(isApproved, influencerId);
    let randomNumber = Math.floor(Math.random() * Number.MAX_VALUE) + 1;
    setCentralValueToCallFunction(randomNumber);
  };

  return (
    <>
      <AppliedInfluencerDrawer
        all={true}
        appliedInfluencers={appliedInfluencers}
        shortListTheInfluencer={shortListTheInfluencer}
        rejectInfluencer={rejectInfluencer}
        page={page}
        setPage={setPage}
        onClose={onClose}
        openDrawer={openDrawer}
        setLimit={setLimit}
        toatlInfluencerCount={toatlInfluencerCount}
      />
      <AppliedWrapper isTablet={isTablet}>
        <div className="flexBetween">
          <CardHeading title="Applied Influencers" />

          <div
            onClick={getInfluencerList}
            style={{
              cursor: "pointer",
              transform: refreshing && "rotate(90deg)",
              transition: "all 3s ease-in",
            }}
          >
            {" "}
            <Icons.RefreshIcon />
          </div>
        </div>
        <>
          <table className="tableParent mt1">
            {/* header */}
            <tr>
              <th className="tableHeader" style={{ textAlign: "left" }}>
                Name
              </th>
              <th className="tableHeader">Applied</th>
              <th className="tableHeader" style={{ paddingLeft: 60 }}>
                Action
              </th>
            </tr>
            {/* table body */}
            <tbody>
              {appliedInfluencers?.slice(0, 5).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <a
                        href={`/user/${item?._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tableData"
                      >
                        <Avatar
                          variant="rounded"
                          sx={{ width: 37, height: 37 }}
                          style={{ borderRadius: 10 }}
                          src={item?.profilePicture?.url}
                        />
                        <div className="influencerText">
                          <Typography className="name">
                            {" "}
                            {item?.firstName + " " + item?.lastName}{" "}
                          </Typography>
                          <Typography className="email">
                            {item?.email}
                          </Typography>
                        </div>
                      </a>
                    </td>
                    <td className="appliedCol">
                      {" "}
                      {formatDate(item?.appliedOn)}{" "}
                    </td>
                    <td className="centerAlignText">
                      <>
                        <Chip
                          clickable
                          onClick={() =>
                            shortListTheInfluencer(true, item?._id)
                          }
                          label="Accept"
                          size="small"
                          style={{
                            color: Colors.darkGreen,
                            cursor: "pointer",
                            textAlign: "center",
                            backgroundColor: Colors.tagGreenBg,
                            borderRadius: "8px",
                            marginRight: !isMidrange && 8,
                            marginTop: isMidrange && 8,
                          }}
                          icon={
                            <img
                              src={ImageList.AcceptImageIcon}
                              alt="accept influencer"
                              className="tagIcon"
                            />
                          }
                        />

                        <Chip
                          clickable
                          onClick={() => rejectInfluencer(false, item?._id)}
                          label="Reject"
                          size="small"
                          style={{
                            color: Colors.alertRed,
                            cursor: "pointer",
                            borderRadius: "8px",
                            background: Colors.faintedPink,
                            marginTop: isMidrange && 8,
                          }}
                          icon={
                            <img
                              src={ImageList.RejectImageIcon}
                              alt="accept influencer"
                              className="tagIcon"
                            />
                          }
                        />
                      </>
                    </td>
                  </tr>
                );
              })}

              {/* view all button */}
              {!all && (
                <tr>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Button
                      onClick={() => showDrawer()}
                      sx={{
                        "&:hover": { backgroundColor: "transparent" },
                        color: Colors.textGrey,
                        textTransform: "capitalize",
                      }}
                      endIcon={<Icons.ViewAllIcon style={{ fontSize: 14 }} />}
                    >
                      View All
                    </Button>
                  </td>
                </tr>
              )}

              {/* table ends here */}
            </tbody>
          </table>
          {appliedInfluencers?.length < 1 && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Empty />
            </div>
          )}
        </>
      </AppliedWrapper>
    </>
  );
};

export default AppliedInfluencers;
