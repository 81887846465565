import React, { useEffect, useState } from "react";
import { message, Steps, theme } from "antd";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
import { BorderRadius } from "../../config/Values";
import useViewport from "../../viewport/useViewport";
import { useSelector } from "react-redux";
import { changeStepCount } from "../../redux/stepSlice";
import { useDispatch } from "react-redux";
import { LocalStorageIdentifier } from "../../config/LocalStorageInfo";

const steps = [
  {
    title: "Basic info",
    content: "Basic info",
  },
  {
    title: "Campaign info",
    content: "Campaign info",
  },
  {
    title: "Influencer Info",
    content: "Influencer Info",
  },
  {
    title: "Media",
    content: "Media",
  },
  {
    title: "Publish",
    content: "Publish",
  },
];
const AntSteps = () => {
  const stepper = useSelector((state) => state.stepper);
  const dispatch = useDispatch();
  const { isMidrange, isTablet } = useViewport();

  let completedTill = JSON.parse(
    localStorage.getItem(LocalStorageIdentifier.stepCompleted)
  );

  const items = steps.map((item, i) => ({
    key: item.title,
    title: item.title,
  }));
  const onStepClick = (value) => {
    if (value < completedTill) {
      // save in redux store
      dispatch(
        changeStepCount({
          data: "abc",
          currentStep: value,
        })
      );
    } else {
      message.warning("Complete the prior steps first.");
    }
  };

  return (
    <>
      <StepWrapper
        className="flexCenter"
        isMidrange={isMidrange}
        isTablet={isTablet}
      >
        <Steps
          current={stepper?.currentStep}
          items={items}
          labelPlacement="vertical"
          onChange={onStepClick}
        />
      </StepWrapper>

      {/* <div
        style={{
          marginTop: 24,
        }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div> */}
    </>
  );
};
export default AntSteps;

const StepWrapper = styled.div`
  min-height: 5rem;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 2rem 1rem 1rem 1rem;
  width: ${(prop) => (prop.isTablet ? "100%" : "70%")};
`;
