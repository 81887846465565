import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Colors } from "../../config/Colors";
import { GraphData } from "../../config/DummyData";
import useViewport from "../../viewport/useViewport";
const SalesLineChart = () => {
  const { isMobile } = useViewport();

  return (
    <ResponsiveContainer width="100%" aspect={isMobile ? 1 : 2 / 1}>
      <AreaChart width={730} height={250} data={GraphData}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.3} />
            <stop offset="50%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="month"
          scale="point"
          axisLine={false}
          tickLine={false}
          style={{
            fill: Colors.textGrey,
            fontWeight: 300,
            fontSize: 10,
            fontFamily: "Open sans",
          }}
        />
        <CartesianGrid
          horizontal={true}
          vertical={false}
          strokeDasharray="4 4"
        />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="uv"
          stroke="#8884d8"
          fillOpacity={1}
          fill="url(#colorUv)"
          strokeWidth={3}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default SalesLineChart;
