import { IconButton, Typography } from "@mui/material";
import { Checkbox, Spin, Tag } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "../../../config/IconsList";
import { deleteTodoAsync, toggleCompleteAsync } from "../../../redux/todoSlice";
import NormalText from "../../headings/NormalText";
import { SingleTodoWrapper } from "./TodoStyle";

const SingleTodo = ({ todo }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  // functions
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    const values = {
      isCompleted: e.target.checked,
      todoId: todo._id,
      token: user?.token,
    };
    dispatch(toggleCompleteAsync(values));
  };

  const onDelete = (e) => {
    e.preventDefault();
    const values = {
      token: user?.token,
      _id: todo?._id,
    };
    dispatch(deleteTodoAsync(values));
  };

  return (
    <SingleTodoWrapper className="flexBetween">
      {/* text */}
      <div className="contentContainer">
        <NormalText title={todo.description} format={true} />
        <div className="controlAction mtp4">
          <Typography
            variant="caption"
            className="todoTime"
            style={{ marginRight: 10 }}
          >
            {moment(todo.time).format("h:mmA")}
          </Typography>
          {!todo.isPrivate && (
            <Tag
              color="magenta"
              style={{ fontSize: 8, paddingTop: "0px", borderRadius: ".3rem" }}
            >
              Public
            </Tag>
          )}
        </div>
      </div>
      {/* completed toggle tick */}

      {todo?.isLoading ? (
        <Spin />
      ) : (
        <div className="actionContainer">
          <Checkbox onChange={onChange} checked={todo.isCompleted} />
          <IconButton onClick={onDelete} style={{ padding: 0, marginLeft: 5 }}>
            <Icons.DeleteIcon className="deleteIcon" />
          </IconButton>
        </div>
      )}
    </SingleTodoWrapper>
  );
};

export default SingleTodo;
