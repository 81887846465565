import { IconButton, Typography } from "@mui/material";
import { Skeleton } from "antd";
import React from "react";
import { Colors } from "../../../../config/Colors";
import {
  formatDate,
  formatDateWithTime,
  formatRelativeDate,
} from "../../../../config/Function";
import { Icons } from "../../../../config/IconsList";
import SmallHeading from "../../../headings/SmallHeading";
import { ProfileInfoWrapper } from "./Style";

const ProfileInfo = ({ user, loading }) => {
  // item

  const SingleInfo = (title, value) => {
    return (
      <div className="singleInfo mt1">
        <Typography className="title">{title} </Typography>
        <Typography className="infoValue"> {value}</Typography>
      </div>
    );
  };

  return (
    <ProfileInfoWrapper>
      <Skeleton active loading={loading}>
        <SmallHeading
          title="Profile Information"
          weight="500"
          color={Colors.textBlack}
        />
        <Typography className="description">{user?.description} </Typography>
        {SingleInfo("Full Name:", user?.name)}
        {SingleInfo("Mobile:", user?.phone)}
        {SingleInfo("Email:", user?.email)}
        {SingleInfo("Location:", `${user?.city}, ${user?.state}`)}
        {SingleInfo(
          "Joined:",
          `${formatDate(user?.createdAt)} - (${formatRelativeDate(
            user?.createdAt
          )})`
        )}
        {SingleInfo(
          "Last Seen:",
          `${formatDate(user?.lastSeen)}, ${formatDateWithTime(
            user?.lastSeen
          )} - (${formatRelativeDate(user?.lastSeen)})`
        )}

        <div className="singleInfo mt1" style={{ alignItems: "flex-start" }}>
          <Typography className="title">About:</Typography>
          <Typography className="infoValue"> {user?.about}</Typography>
        </div>
        {/* <div className="singleInfo mt1">
          <Typography className="title">Social: </Typography>
          <div className="flexBetween">
            <IconButton aria-label="social" component="label" className="icon">
              <Icons.InstagramIcon />
            </IconButton>

            <IconButton aria-label="social" component="label" className="icon">
              <Icons.YouTubeIcon />
            </IconButton>
          </div>
        </div> */}
      </Skeleton>
    </ProfileInfoWrapper>
  );
};

export default ProfileInfo;
