import styled from "styled-components";
import { Colors } from "../../../config/Colors";

export const TeamMemberWrapper = styled.div`
  height: 25em;
  background-color: ${Colors.white};
  border-radius: 1rem;
  padding: 1.5rem;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  .memberImage {
    width: 4em;
    aspect-ratio: 1;
    border-radius: 0.75rem;
    object-fit: cover;
    object-position: center;
  }
  .contentContainer {
    flex: 1;
    padding-left: 1rem;
  }

  .roleText {
    width: 4rem;
    padding: 0.55rem 0.97rem;
    border: 1.3px solid ${Colors.lightPurple};
    border-radius: 0.6rem;
    text-transform: capitalize;
    color: ${Colors.lightPurple};
    font-weight: 600;
    font-size: 0.9em;
  }
  .tag {
    border-radius: 0.3rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.6rem;
  }
`;
