export const Instructions = {
  Barter:
    "Barter refers to collaboration in which a brand reaches out to Influencer to help promote its product in exchange for free product sample.",
  InfluencerBudget:
    "Influencer budget refers to budget allotted per influencer.",
  FollowersRange: "Followers range of Influencers your brand wants to target.",
  InfluencerRequired: "Total Number of Influencer required",
  ReferenceImage:
    "Give the reference of relevant content that will help the Influencers to deliver a better campaign for your brand.",
  ReferenceProduct:
    "Give the picture of products/services that the  Influencers will recieve after connecting with you.",
  ReferenceVideo:
    "Share a reference video that will help the Influencer to understand what your brand wants from the campaign.",
};
