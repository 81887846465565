import styled from "styled-components";
import { Colors } from "../../../config/Colors";

export const TodoWrapper = styled.div`
  height: 25em;
  background-color: ${Colors.white};
  border-radius: 1rem;
  padding: 1.5rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SingleTodoWrapper = styled.div`
  padding-right: 0.24em;
  align-items: flex-start !important;

  .contentContainer {
    flex: 1;
  }
  .todoTime {
    color: ${Colors.textGrey};
    font-weight: 300;
    font-size: 0.9em;
  }
  .controlAction {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .actionContainer {
    margin-right: -1rem;
  }
  .deleteIcon {
    font-size: 18px;
    margin-bottom: 0.2rem;
  }
`;
