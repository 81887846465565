import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../config/Colors";

const SmallHeading = ({ title, color, weight, mTop, mBottom, size }) => {
  return (
    <Heading
      marginTop={mTop}
      marginBottom={mBottom}
      variant="body1"
      className="cardTitle"
      style={{
        color: color ? color : Colors.textGrey,
        fontWeight: weight ? weight : "500",
        fontSize: size ? size : "1.1rem",
      }}
    >
      {title}
    </Heading>
  );
};

export default SmallHeading;

const Heading = styled(Typography)`
  font-family: "Open Sans", sans-serif !important;
  margin-bottom: ${(prop) =>
    prop.marginBottom ? prop.marginBottom : "1em"} !important;
  margin-top: ${(prop) => prop.marginTop && prop.marginTop};
`;
