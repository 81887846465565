import { createSlice } from "@reduxjs/toolkit";

export const imageUploadSlice = createSlice({
  name: "imageUploadedUrl",
  initialState: {
    imageUrl: "",
    type: "",
  },
  reducers: {
    uploadedImageUrl: (state, action) => {
      state.imageUrl = action.payload.imageUrl;
      state.type = action.payload.type;
    },
  },
});

export const { uploadedImageUrl } = imageUploadSlice.actions;
export default imageUploadSlice.reducer;
