import { IconButton } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { UserChatList, UserList } from "../../../config/DummyData";
import { Icons } from "../../../config/IconsList";
import { BorderRadius } from "../../../config/Values";

const SearchUser = ({ searchQuery, handleSearch }) => {
  let items = UserList;

  let userToPreview = 1;

  const ChatList = UserChatList.filter((item) => item.id === userToPreview)[0]
    .connection;

  return (
    <SearchWrapper>
      <div className="flexBetween containerBox">
        <IconButton className="searchIcon">
          <Icons.SearchIcon />
        </IconButton>
        <input
          className="searchInput"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search"
        />
      </div>
    </SearchWrapper>
  );
};

export default SearchUser;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  .containerBox {
    position: relative;
    flex: 1;
    border-radius: ${BorderRadius.smallRadius};
    background-color: ${Colors.searchBg} !important;
  }
  .searchInput {
    flex: 1;
    height: 1.7rem;
    padding: 0.3rem;
    background: transparent;
    border: none;
  }
  .searchInput:focus {
    outline: none;
  }
  .searchIcon {
    margin-left: 0.7rem;
    margin-right: 1rem;
  }
`;
