import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius } from "../../../../config/Values";

export const CreateUserInfoWrapper = styled.div`
  width: 100%;
  min-height: 27rem;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1.5rem;
`;
