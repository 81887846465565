import React, { useEffect } from "react";
import { Row } from "antd";
import { useSelector } from "react-redux";
import StepFourForm from "../../../components/forms/CreateCampaign/StepFour/StepFourForm";
import StepOneForm from "../../../components/forms/CreateCampaign/StepOne";
import StepThreeForm from "../../../components/forms/CreateCampaign/StepThree";
import StepTwoForm from "../../../components/forms/CreateCampaign/StepTwo";
import StepZero from "../../../components/forms/CreateCampaign/StepZero";
import CardHeading from "../../../components/headings/CardHeading";
import AntSteps from "../../../components/stepper/AntSteps";
import { ImageList } from "../../../config/ImageList";
import DesktopLayout from "../../../theme/DesktopLayout";
import useViewport from "../../../viewport/useViewport";
import { CreateCampaignWrapper } from "./CreateCampaignStyle";
import { LocalStorageIdentifier } from "../../../config/LocalStorageInfo";
import { useFormik } from "formik";
import { CreateCampaignSchema } from "../../../config/Schemas";
import { useDispatch } from "react-redux";
import { setBrandUserId } from "../../../redux/createCampaignSlice";

const CreateCampaign = () => {
  const { currentStep } = useSelector((state) => state.stepper);
  const user = useSelector((state) => state.user);
  const { isMidrange, isTablet } = useViewport();
  const createCampaign = useSelector((state) => state.createCampaign);
  const dispatch = useDispatch();
  // ===========================************==================================
  // ===========================initial values=====================================
  // ===========================*************=================================
  let initialValues = {
    campaignName: "",
    campaignPeriod: {
      startDate: "",
      endDate: "",
    },
    campaignPlatform: {
      platformName: "",
      platformLogo: "",
    },
    deliverableType: [],
    dos: [],
    donts: [],
    isDosChecked: true,
    isDontsChecked: true,
  };
  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: CreateCampaignSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      console.log("submit");
    },
  });

  useEffect(() => {
    if (user) {
      dispatch(setBrandUserId({ brandUserId: user?._id }));
    }
  }, [user]);

  return (
    <DesktopLayout>
      <CreateCampaignWrapper isMidrange={isMidrange} isTablet={isTablet}>
        <div className="mt2 formContainer">
          <>
            {" "}
            <div className="headingContainer">
              <img
                className="loudspeakerIcon"
                src={ImageList.LoudspeakerImageIcon}
                alt="speaker"
              />{" "}
              <CardHeading title="Campaign Info" weight="700" />
            </div>
            <StepOneForm />{" "}
          </>

          <>
            {" "}
            <div className="headingContainer">
              <CardHeading title="Influencer Info" weight="700" />
            </div>
            <StepTwoForm />{" "}
          </>

          <>
            {" "}
            <div className="headingContainer">
              <CardHeading title="Campaign Media" weight="700" />
            </div>
            <StepThreeForm />{" "}
          </>

          <>
            {" "}
            <div className="headingContainer">
              <CardHeading title="Publish Your Campaign" weight="700" />
            </div>
            <StepFourForm edit={false} />{" "}
          </>
        </div>
      </CreateCampaignWrapper>
    </DesktopLayout>
  );
};

export default CreateCampaign;
