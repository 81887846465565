import { Button, Typography } from "@mui/material";
import {
  Col,
  Image,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Upload,
} from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SmallHeading from "../../../components/headings/SmallHeading";
import NormalLabel from "../../../components/inputLabel/NormalLabel";
import UploadWidget from "../../../components/upload/cloudinaryWidget/UploadWidget";
import { Colors } from "../../../config/Colors";
import { CreateStorySchema } from "../../../config/Schemas";
import { StoryType } from "../../../config/Values";
import DesktopLayout from "../../../theme/DesktopLayout";
import { CreateStoryWrapper } from "./CreateStoryStyle";
import { MuiColorInput } from "mui-color-input";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebaseConfig";
import { InboxOutlined } from "@ant-design/icons";
import { createStoryAPI } from "../../../functions/story";
const { Option } = Select;
const { Dragger } = Upload;
//  initial values
const initialValues = {
  storyType: "text",
  userId: "",
  backgroundColor: "#fff",
  textColor: "#000",
  textMessage: "",
  storyMediaUrl: "",
  storyMediaThumbnail: "",
};
// before uploading any image this function runs to check the format and size of image
const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/WEBP file!");
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt10M;
};
const CreateStory = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);
  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: CreateStorySchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("CREATE NEW REELS VALUES==>", values);
      createStoryAPI(values, user?.token)
        .then((res) => {
          console.log(res.data);
          message.success("New story created.");
          resetForm();
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
        });
    },
  });

  // useEffect
  useEffect(() => {
    if (user?.token !== "") {
      handleChange({
        target: { name: "userId", value: user?._id },
      });
    }
  }, [user]);
  useEffect(() => {
    if (user?.token !== "") {
      clearAllData();
    }
  }, [values?.storyType]);
  // functions
  // open the cloudinary widget
  function handleOnUpload(error, result, widget) {
    if (error) {
      setError(error);
      widget.close({
        quiet: true,
      });
      return;
    }
    console.log("AFTER VIDEO UPLOAD", result);
    console.log("AFTER VIDEO UPLOAD ERROR", error);

    let myThumbnail = result?.info?.secure_url.replace(".mp4", ".jpg");
    handleChange({
      target: { name: "storyMediaUrl", value: result?.info?.secure_url },
    });
    handleChange({
      target: { name: "storyMediaThumbnail", value: myThumbnail },
    });
  }

  // clear the form
  const clearAllData = () => {
    handleChange({
      target: {
        name: "backgroundColor",
        value: initialValues?.backgroundColor,
      },
    });
    handleChange({
      target: { name: "textColor", value: initialValues?.textColor },
    });
    handleChange({
      target: { name: "textMessage", value: initialValues?.textMessage },
    });
    handleChange({
      target: {
        name: "storyMediaThumbnail",
        value: initialValues?.storyMediaThumbnail,
      },
    });
    handleChange({
      target: { name: "storyMediaUrl", value: initialValues?.storyMediaUrl },
    });
  };
  // handle color from color picker
  const handleColorChange = (fieldName, color) => {
    handleChange({
      target: { name: fieldName, value: color },
    });
  };
  // Handle file upload event and upload image to firebase storage and retrieve the link of uploaded image
  function handleImageUploadToFirebase(value) {
    const { file } = value;
    console.log("FILE SELECTED==>", value);
    const storageRef = ref(storage, `/story/${file.name}-${Date.now()}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          handleChange({
            target: {
              name: "storyMediaUrl",
              value: url,
            },
          });
          setLoading(false);
        });
      }
    );
  }
  return (
    <DesktopLayout>
      <div className="flexCenter">
        <CreateStoryWrapper>
          {/* {JSON.stringify(values, null, 4)} */}
          <SmallHeading title="Story" color={Colors.textBlack} weight={"400"} />
          <SmallHeading
            title="Create new story"
            weight={"300"}
            size="0.8rem"
            mTop="-1rem"
          />
          {/* form starts here */}
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <NormalLabel identifier="storyType" title="Select Story Type" />
              <Select
                id="storyType"
                size="large"
                maxTagCount="responsive"
                style={{
                  width: "100%",
                }}
                placeholder="select storyType"
                value={values?.storyType}
                onChange={(e) =>
                  handleChange({ target: { name: "storyType", value: e } })
                }
                optionLabelProp="label"
              >
                {StoryType?.map((item, i) => {
                  return (
                    <Option key={i} value={item?.value} label={item?.label}>
                      <Space> {item?.label} </Space>
                    </Option>
                  );
                })}
              </Select>
            </Col>

            {values?.storyType === "text" || values?.storyType === "url" ? (
              <>
                <Col span={24}>
                  <NormalLabel identifier="textMessage" title="Your Message" />
                  <Input.TextArea
                    status={errors.textMessage && "error"}
                    showCount
                    size="large"
                    onChange={handleChange}
                    name="textMessage"
                    value={values?.textMessage}
                    placeholder="Your message for the story goes here!!"
                    id="textMessage"
                    autoSize={{
                      minRows: 8,
                      maxRows: 12,
                    }}
                  />
                  {touched.textMessage && errors.textMessage && (
                    <Typography fontSize={"0.7rem"} color={Colors.alertRed}>
                      {" "}
                      {errors.textMessage}{" "}
                    </Typography>
                  )}
                </Col>

                <Col xs={24} lg={12}>
                  <NormalLabel
                    identifier="backgroundColor"
                    title="Choose Background Color"
                  />
                  <MuiColorInput
                    value={values?.backgroundColor}
                    onChange={(color) =>
                      handleColorChange("backgroundColor", color)
                    }
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <NormalLabel
                    identifier="textColor"
                    title="Choose Text Color"
                  />
                  <MuiColorInput
                    value={values?.textColor}
                    onChange={(color) => handleColorChange("textColor", color)}
                  />
                </Col>

                <Col span={24}>
                  <NormalLabel
                    identifier="preview"
                    title="Preview Your Story"
                  />
                  <div
                    className="previewContainer"
                    style={{ background: values?.backgroundColor }}
                  >
                    <Typography
                      className="font"
                      style={{ color: values?.textColor }}
                    >
                      {values?.textMessage}
                    </Typography>
                  </div>
                </Col>
              </>
            ) : null}

            {values?.storyType === "image" && (
              <Col span={24}>
                <Dragger
                  customRequest={handleImageUploadToFirebase}
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  className="draggerWithImage"
                  style={{
                    background: "transparent",
                  }}
                >
                  {loading ? (
                    <Spin />
                  ) : values?.storyMediaUrl === "" ? (
                    <>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag image to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single media upload. Strictly prohibited
                        from uploading company data or other banned files.
                      </p>
                    </>
                  ) : (
                    <Image
                      style={{ width: "10rem", height: "10rem" }}
                      src={values?.storyMediaUrl}
                      alt="uploadedImage"
                    />
                  )}
                </Dragger>
              </Col>
            )}

            {values?.storyType === "video" ? (
              <Col span={24}>
                <NormalLabel identifier="videoUpload" title="Upload video" />
                {/* cloudinary upload widget */}
                <UploadWidget onUpload={handleOnUpload}>
                  {({ open }) => {
                    function handleOnClick(e) {
                      e.preventDefault();
                      open();
                    }
                    return (
                      <button
                        className="videoUploadButton flexCenter"
                        onClick={handleOnClick}
                      >
                        {values?.storyMediaUrl === "" ? (
                          "Upload a video"
                        ) : (
                          <>
                            <Typography variant="body1">
                              Video has been successfully uploaded to
                            </Typography>
                            <Button
                              style={{ textTransform: "lowercase" }}
                              href={values?.storyMediaUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {values?.storyMediaUrl.substring(0, 28) + "..."}{" "}
                            </Button>
                          </>
                        )}
                      </button>
                    );
                  }}
                </UploadWidget>
              </Col>
            ) : null}
            {/* upload button */}
            <Col span={24}>
              <Button className="uploadReelButton" onClick={handleSubmit}>
                Upload Story
              </Button>
              <Button
                className="clearAllButton uploadReelButton"
                onClick={clearAllData}
              >
                Clear All
              </Button>
            </Col>
          </Row>
        </CreateStoryWrapper>
      </div>
    </DesktopLayout>
  );
};

export default CreateStory;
