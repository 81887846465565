import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius, Gradient, Shadow } from "../../../config/Values";

export const CreateReelsWrapper = styled.div`
  width: 65%;
  min-height: 80vh;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius} !important;
  padding: 1rem;
  .videoUploadButton {
    width: 100%;
    height: 10rem;
    border-radius: ${BorderRadius.smallRadius} !important;
    border: 1px solid ${Colors.greyDashedBorder};
    background-color: transparent;
    cursor: pointer;
  }
  .uploadReelButton {
    background-image: ${Gradient.primary};
    color: ${Colors.white};
    font-size: 0.9rem;
    text-transform: capitalize;
    padding: 0.48rem 1.8rem;
    border-radius: ${BorderRadius.smallRadius};
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    box-shadow: ${Shadow.light};
    float: right;
    margin-top: ${(prop) => prop.isMobile && "1rem"};
  }
  .uploadReelButton:hover {
    transform: translateY(-0.14rem);
    transition: all 0.3s ease-in-out;
  }

  .uploadReelButton:focus {
    outline: none;
  }
  .clearAllButton {
    background: transparent !important;
    color: ${Colors.textBlack};
    margin-right: 0.5rem;
    border: 1px solid ${Colors.greyDashedBorder};
  }
`;
