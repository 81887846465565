import { Button, Typography } from "@mui/material";
import { Input, message, Spin } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../config/Colors";
import { LoginSchema } from "../../../config/Schemas";
import { LoginWrapper } from "./LoginStyled";
import { signInWithCustomToken, signOut } from "firebase/auth";
import {
  checkPhoneNumber,
  phoneVerification,
  verifyNumberOtp,
} from "../../../functions/auth";
import { v4 as uuidv4 } from "uuid";
import { auth } from "../../../firebaseConfig";
import { logoutUser } from "../../../redux/currentUserSlice";
import { useDispatch, useSelector } from "react-redux";
const Login = () => {
  const initialValues = {
    code: "+91",
    phoneNumber: "",
    otpInput: "",
    uuid: "",
  };
  const [loading, setLoading] = useState(false);
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [redirection, setRedirection] = useState(false);
  // hooks
  const dispatch = useDispatch();
  // redux store
  const user = useSelector((state) => state.user);
  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: LoginSchema,
      validateOnBlur: true,
      validateOnChange: true,
      onSubmit: (values, { setSubmitting }) => {
        console.log("SUBMITTED");
        // mobileLoginWithFirebase();
        login();
      },
    });

  useEffect(() => {
    const myId = uuidv4();
    handleChange({ target: { name: "uuid", value: myId } });
  }, []);

  const login = async () => {
    setLoading(true);
    if (await checkPhoneNumber(values?.phoneNumber)) {
      phoneVerification(values.uuid, values?.phoneNumber)
        .then((res) => {
          setIsOtpSend(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log("SENDING OTP ERROR_LOGIN PAGE===>", err);
          setLoading(false);
        });
    } else {
      message.warning("User is not registered with us");
    }
  };

  const verifyOtpNew = () => {
    setLoading(true);

    verifyNumberOtp(values?.uuid, values?.otpInput, values?.phoneNumber)
      .then(async (res) => {
        if (res.data) {
          await signInWithCustomToken(auth, res?.data?.token).then(
            (response) => {
              setLoading(false);
              setRedirection(true);
            }
          );
        }
      })
      .catch((err) => {
        console.log(err?.response?.data);
        console.log("FIREBASE ERROR====>", err);
        message.error(err);
        setLoading(false);
      });
    // console.log(res.data);
  };
  const logout = async () => {
    await signOut(auth)
      .then(() => {
        console.log("User signed out.");
        dispatch(logoutUser());
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <LoginWrapper className="flexCenter">
      {redirection ? (
        <div className="flexCenter">
          {" "}
          <Typography variant="h3">Redirecting...</Typography> <Spin />
        </div>
      ) : (
        <div style={{ maxWidth: "20rem" }}>
          {/* {JSON.stringify(loading, null, 4)} */}
          <Typography variant="h3" className="font mb1" textAlign="center">
            Login
          </Typography>
          <Input
            prefix="+91"
            placeholder="Enter your mobile number"
            size="large"
            name="phoneNumber"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.phoneNumber && touched.phoneNumber ? (
            <span className="font" style={{ color: Colors.alertRed }}>
              {errors.phoneNumber}
            </span>
          ) : null}

          {isOtpSend && (
            <Input
              placeholder="Enter the otp"
              size="large"
              name="otpInput"
              onChange={handleChange}
              onBlur={handleBlur}
              className="mt1"
            />
          )}
          <div id="sign-in-button"></div>
          {isOtpSend ? (
            <Button
              fullWidth
              variant="contained"
              onClick={verifyOtpNew}
              className="nextButton mt1"
              disabled={loading || values.otpInput?.length < 6}
            >
              {loading ? "Signing in...." : "Login"}
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              className="nextButton mt1"
              sx={{
                "&:disabled": {
                  backgroundColor: Colors.greyC9,
                  color: Colors.white,
                  opacity: 0.5,
                },
              }}
              disabled={errors.phoneNumber || values?.phoneNumber === ""}
            >
              {loading ? (
                <span>
                  Sending OTP... <Spin />
                </span>
              ) : (
                "Send OTP"
              )}
            </Button>
          )}
        </div>
      )}
      {user?.email !== "" && <Button onClick={logout}>Logout</Button>}
    </LoginWrapper>
  );
};

export default Login;
