import { createSlice } from "@reduxjs/toolkit";

export const navigationSlice = createSlice({
  name: "currentLocation",
  initialState: {
    title: "Dashboard",
    folderName: "Home",
  },
  reducers: {
    changeCurrentLocation: (state, action) => {
      state.title = action.payload.title;
      state.folderName = action.payload.folderName;
    },
  },
});

export const { changeCurrentLocation } = navigationSlice.actions;
export default navigationSlice.reducer;
