import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius, Gradient } from "../../../config/Values";

export const CountInfoCardWrapper = styled.div`
  border-radius: ${BorderRadius.mediumRadius};
  background-color: ${Colors.white};
  min-height: fit-content;
  max-height: 8em;
  min-width: 21%;
  max-width: 90%;
  padding: 1em;
  margin-top: ${(prop) => prop.isTablet && "1em"};
  width: ${(prop) => prop.isMobile && "100%"};
  .contentContainer {
    margin-bottom: 1em;
  }
  .cardInfoContainer {
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .cardTitle {
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    color: ${Colors.textGrey};
  }
  .cardAmount {
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: ${Colors.black70};
  }
  .cardIconContainer {
    width: 3em;
    height: 3em;
    background-image: ${Gradient.primary};

    border-radius: 50%;
  }
  .cardIcon {
    color: ${Colors.white};
  }

  .gainLossText {
    font-size: 15px;
    font-weight: 400;
    color: ${Colors.textGrey};
  }
  .lossText {
    color: ${Colors.alertRed};
  }
  .gainText {
    color: ${Colors.lightGreen};
  }
`;
