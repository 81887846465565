import { Chip, Typography } from "@mui/material";
import { Dropdown } from "antd";
import React from "react";
import styled from "styled-components";
import { FontType } from "../../config/Values";
const TagIconOutlined = ({ title, iconUrl, onClick }) => {
  return (
    <TagWrapper
      clickable
      onClick={onClick}
      variant="outlined"
      size="small"
      label={<Typography className="tagTitle"> {title} </Typography>}
      icon={<img src={iconUrl} alt="icon" className="iconImage" />}
    />
  );
};

export default TagIconOutlined;

const TagWrapper = styled(Chip)`
  text-transform: capitalize;
  cursor: pointer;
  .iconImage {
    width: ${(prop) => (prop.iconDimension ? prop.iconDimension : "1.06rem")};
    height: ${(prop) => (prop.iconDimension ? prop.iconDimension : "1rem")};
    margin-left: ${(prop) => prop.iconDimension && "0.5rem"} !important;
  }

  .tagTitle {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: capitalize !important;
  }
`;
