import { Avatar, Empty, message, Pagination, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { numberWithCommas } from "../../../../config/Function";
import { Icons } from "../../../../config/IconsList";
import { getRevenueByBrandAPI } from "../../../../functions/brand";
import useViewport from "../../../../viewport/useViewport";
import ViewAllButton from "../../../Button/ViewAllButton";
import CardHeading from "../../../headings/CardHeading";
import { PaginationWrapper } from "../../campaign/AllCampaignScreen/Style";
import { BrandWiseInfoWrapper } from "./Style";

const BrandWiseInfo = ({ all }) => {
  const { isTablet } = useViewport();
  const user = useSelector((state) => state.user);
  const [campaigns, setCampaigns] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user?.token !== "") {
      getRevenueByBrand();
    }
  }, [user, page, limit]);

  const getRevenueByBrand = () => {
    setLoading(true);
    getRevenueByBrandAPI(page, limit, user?.token)
      .then((res) => {
        console.log("REVENUE BY BRAND==>", res.data.data);
        setLoading(false);
        setCampaigns(res.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error(err.response.data.message);
      });
  };

  // page  size change: number of item to be displayed on a single page
  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setLimit(pageSize);
  };
  // change page number
  const handleChangePageNumber = (page, pageSize) => {
    setPage(page);
  };
  return (
    <>
      <BrandWiseInfoWrapper isTablet={isTablet} className="mt2" all={all}>
        <CardHeading title="Revenue by Brand" />

        <div
          className="flexCenter mb1"
          style={{ flexDirection: "column", width: "100%" }}
        >
          <table className="tableParent mt1" style={{ width: "100%" }}>
            {/* table header row */}
            <tr>
              <th> </th>
              <th className="tableHeader" style={{ textAlign: "left" }}>
                Brand
              </th>
              <th className="tableHeader">Campaigns</th>
              <th className="tableHeader">Revenue</th>
              <th className="tableHeader">Success</th>
            </tr>
            {/* table body row */}
            {campaigns.slice(0, 5).map((item, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Avatar src={item?.logo} alt="logo" />
                  </td>
                  <td style={{ textAlign: "left" }}> {item.brandName} </td>
                  <td className="tableData">{item?.campaigns?.length}</td>
                  <td className="tableData">
                    <Icons.RupeeIcon style={{ fontSize: 12 }} />
                    {numberWithCommas(item?.revenueGenerated)}
                  </td>
                  <td className="tableData">
                    {item?.success === null ? 0 : item?.success}{" "}
                  </td>
                </tr>
              );
            })}
            {/* view all button */}
            {!all && (
              <tr>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  <ViewAllButton url="/brand/all/info" />
                </td>
              </tr>
            )}

            {/* table ends here */}
          </table>
          {loading && <Spin />}
          {campaigns?.length < 1 && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Empty />
            </div>
          )}
        </div>
      </BrandWiseInfoWrapper>
      {/* pagination */}
      {all && (
        <PaginationWrapper>
          <Pagination
            pageSizeOptions={[1, 4, 10, 50, 100]}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={1}
            total={500}
            onChange={handleChangePageNumber}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default BrandWiseInfo;
