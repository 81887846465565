import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius, FontType } from "../../../config/Values";

export const SingleCampaignWrapper = styled.div`
  min-height: 20rem;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  padding: 1rem;
  .campaignBanner {
    width: 100% !important;
    height: 18rem;
    object-fit: cover;
    object-position: center;
    border-radius: ${BorderRadius.smallRadius};
  }
  .otherImagesContainer {
    display: flex;
    align-items: center;
    overflow-x: scroll;
  }
  .otherImagesContainer::-webkit-scrollbar {
    display: none;
  }
  .brandContainer {
    flex-wrap: wrap;
  }
  .smallImage {
    width: 5.93rem;
    aspect-ratio: 4/2.62;
    margin-right: 0.7rem;
    object-fit: cover;
    object-position: center;
    border-radius: ${BorderRadius.smallRadius};
    cursor: pointer;
  }
  .videoDiv {
    background-color: ${Colors.lightPurple};
    color: ${Colors.white};
    font-weight: 500;
    font-family: ${FontType.openSans}, sans-serif;
  }
  .tagWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .deliverableTagsContainer {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
  }
  .deliverableHeading {
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 600;
    font-size: 9px;
    margin-top: 1rem;
    margin-left: 0.4rem;
  }
  .contentContainer {
    padding: ${(prop) => (prop.isMobile ? "0" : "0 2rem")};
  }
  .verificationTick {
    width: 1rem;
    height: 1rem;
    border-radius: 4rem;
    background-color: ${Colors.white};
    border: 0.5px solid ${Colors.white};
  }
  .brandInfo {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .brandName {
    font-size: 0.95rem;
    color: ${Colors.textBlack};
    font-weight: 400;
    margin-left: 0.7rem;
  }
`;
