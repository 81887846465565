import axios from "axios";

export const createReelsAPI = async (values, authtoken) => {
  return await axios.post(`${process.env.REACT_APP_API_ADMIN}/reel`, values, {
    headers: {
      authorization: authtoken,
    },
  });
};

export const getReelsAPI = async (page, limit, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/reels?page=${page}&limit=${limit}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const deleteReelsAPI = async (reelId, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_ADMIN}/reel?reelId=${reelId}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};
