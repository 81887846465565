import { Avatar, Button, Chip, Typography } from "@mui/material";
import { Drawer, Empty, message } from "antd";
import React, { useEffect, useRef } from "react";
import { Colors } from "../../../../config/Colors";
import { formatDate } from "../../../../config/Function";
import { Icons } from "../../../../config/IconsList";
import { ImageList } from "../../../../config/ImageList";
import useViewport from "../../../../viewport/useViewport";
import CardHeading from "../../../headings/CardHeading";
import { AppliedWrapper } from "./Style";

const AppliedInfluencerDrawer = ({
  appliedInfluencers,
  rejectInfluencer,
  all,
  openDrawer,
  onClose,
  setPage,
  shortListTheInfluencer,
  setLimit,
  page,
  toatlInfluencerCount,
}) => {
  const { isTablet, isMidrange } = useViewport();

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={onClose}
      open={openDrawer}
    >
      <AppliedWrapper isTablet={isTablet}>
        <CardHeading title="Applied Influencers" />
        <>
          <table className="tableParent mt1">
            {/* header */}
            <tr>
              <th className="tableHeader" style={{ textAlign: "left" }}>
                Name
              </th>
              <th className="tableHeader">Applied</th>
              <th className="tableHeader" style={{ paddingLeft: 60 }}>
                Action
              </th>
            </tr>
            {/* table body */}
            <tbody>
              {appliedInfluencers?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <a
                        href={`/user/${item?._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tableData"
                      >
                        <Avatar
                          variant="rounded"
                          sx={{ width: 37, height: 37 }}
                          style={{ borderRadius: 10 }}
                          src={item?.profilePicture?.url}
                        />
                        <div className="influencerText">
                          <Typography className="name">
                            {" "}
                            {item?.firstName + " " + item?.lastName}{" "}
                          </Typography>
                          <Typography className="email">
                            {item?.email}
                          </Typography>
                        </div>
                      </a>
                    </td>
                    <td className="appliedCol">
                      {" "}
                      {formatDate(item?.appliedOn)}{" "}
                    </td>
                    <td className="centerAlignText">
                      <>
                        <Chip
                          clickable
                          onClick={() =>
                            shortListTheInfluencer(true, item?._id)
                          }
                          label="Accept"
                          size="small"
                          style={{
                            color: Colors.darkGreen,
                            cursor: "pointer",
                            textAlign: "center",
                            backgroundColor: Colors.tagGreenBg,
                            borderRadius: "8px",
                            marginRight: !isMidrange && 8,
                            marginTop: isMidrange && 8,
                          }}
                          icon={
                            <img
                              src={ImageList.AcceptImageIcon}
                              alt="accept influencer"
                              className="tagIcon"
                            />
                          }
                        />

                        <Chip
                          clickable
                          onClick={() => rejectInfluencer(false, item?._id)}
                          label="Reject"
                          size="small"
                          style={{
                            color: Colors.alertRed,
                            cursor: "pointer",
                            borderRadius: "8px",
                            background: Colors.faintedPink,
                            marginTop: isMidrange && 8,
                          }}
                          icon={
                            <img
                              src={ImageList.RejectImageIcon}
                              alt="accept influencer"
                              className="tagIcon"
                            />
                          }
                        />
                      </>
                    </td>
                  </tr>
                );
              })}
              {/* && appliedInfluencers?.totalCount !== page * limit */}
              {all && toatlInfluencerCount > appliedInfluencers?.length && (
                <tr>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Button
                      onClick={() => setLimit((prev) => prev + 20)}
                      sx={{
                        "&:hover": { backgroundColor: "transparent" },
                        color: Colors.textGrey,
                        textTransform: "capitalize",
                      }}
                      endIcon={<Icons.AddIcon style={{ fontSize: 14 }} />}
                    >
                      Load More
                    </Button>
                  </td>
                </tr>
              )}

              {/* table ends here */}
            </tbody>
          </table>
          {appliedInfluencers?.length < 1 && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Empty />
            </div>
          )}
        </>
      </AppliedWrapper>
    </Drawer>
  );
};

export default AppliedInfluencerDrawer;
