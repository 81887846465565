import { AppBar, Avatar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { FontType } from "../../../config/Values";
import { Empty, Select } from "antd";
import { getCampaignAPI } from "../../../functions/campaign";
import { useSelector } from "react-redux";
import { CampaignsList } from "../../../config/DummyData";
import { useDispatch } from "react-redux";
import { setCampaignChatInfo } from "../../../redux/campaignChatSlice";
const MessageAreaNav = ({ user, isRoom }) => {
  const loggedUser = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // state
  const [loadingCampaign, setLoadingCampaign] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  useEffect(() => {
    if (loggedUser) {
      getAllCampaigns();
    }
  }, [loggedUser]);

  const getAllCampaigns = () => {
    setLoadingCampaign(true);
    const values = {
      page: 1,
      limit: 1000,
      brandId: loggedUser?._id,
    };
    getCampaignAPI(values, user?.token)
      .then((res) => {
        console.log("CAMPAIGNS=>", res.data);
        setCampaignsList(res.data.campaigns);
        setLoadingCampaign(false);
        // dispatch(
        //   setCampaignChatInfo({
        //     campaignName: res.data.campaigns[0]?.campaignName,
        //     id: res.data.campaigns[0]?._id,
        //   })
        // );
      })
      .catch((err) => console.log(err));
  };
  // any time campaign is changed it's name and id is stored
  const handleCampaignChange = (newValue) => {
    // setValue(newValue);
    console.log("CAMPAIGN REDUX VALUE==>", newValue);
    const myValues = {
      campaignName: newValue?.label,
      id: newValue?.value,
    };
    dispatch(
      setCampaignChatInfo({
        ...myValues,
      })
    );
  };

  return (
    <NavWrapper position="sticky" elevation={0}>
      <div className="flexBetween">
        <Avatar
          src={isRoom ? user?.imageUrl : user?.profilePicture?.url}
          alt={user?.name}
        />
        <div className="name">{user?.name}</div>
      </div>
      {!isRoom && (
        <>
          <Select
            showSearch
            placeholder="Select campaign to view chat"
            id="campaign"
            style={{ width: "60%" }}
            defaultActiveFirstOption
            showArrow={true}
            filterOption={true}
            onChange={handleCampaignChange}
            notFoundContent={<Empty />}
            labelInValue
            options={(campaignsList || []).map((d) => ({
              value: d._id,
              label: d.campaignName.substring(0, 30) + "...",
            }))}
            optionFilterProp="label"
            size="large"
          />
        </>
      )}
    </NavWrapper>
  );
};

export default MessageAreaNav;

const NavWrapper = styled(AppBar)`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between !important;
  padding: 0.5rem;
  background-color: ${Colors.faintedWhiteBg} !important;
  color: ${Colors.textBlack} !important;
  z-index: 2;
  position: absolute;
  top: 0;
  .name {
    margin-left: 1rem;
  }
  .nameText {
    color: ${Colors.textBlack} !important;
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 500 !important;
  }
  .roleText {
    color: ${Colors.cardText} !important;
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 500 !important;
    text-transform: capitalize;
  }
`;
